import config from "../../config";
import * as ActionTypes from './ActionTypes'
import { Item } from "../state";
const pos_url = process.env.POS_URL || config().config.POS.url;
export const GetAllProduct = (merchantId: string) => (dispatch: any) => {
    let _url = `${pos_url}/getAllItem/${merchantId}`
    fetch(_url, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
    })
        .then(response => response.json())
        .then(data => {
            let products: Item[] = []
           // console.log('response product', data)
            if (data.status !== 'Error' || data.data.length < 0) {
                products = data.data as Item[] || []
            }
            dispatch({
                type: ActionTypes.ADD_PRODUCT,
                payload: { products }
            })
        });
}