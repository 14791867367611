import React, { Component } from 'react'
import moment from 'moment'
import 'antd/dist/antd.css';
import { Button, Card, Table, } from 'antd'
import '../css.less'
import { connect } from 'react-redux';
import { DateRange } from "../Member/component";
import * as Actions from '../redux/actions'
import { ExportCSV } from '../excel/exportCSV'
import { StampModel } from './model'
//import { EmptyBox } from "../../components";
const { createRows, crateColummns, createCsvData } = StampModel
const dateFormat = 'YYYY/MM/DD';
class StampHistory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sdate: moment(),
            tdate: moment(),
        }
        this.onhandleChange = this.onhandleChange.bind(this)
        this.onFilterChange = this.onFilterChange.bind(this)
        this.onOk = this.onOk.bind(this)
        this.show = this.show.bind(this)
        this.onpresetController = this.onpresetController.bind(this)
    }
    onhandleChange(m, st) {
        this.setState({
            sdate: st[0],
            tdate: st[1]
        })
    }
    onOk() {
        this.props.stampFilterRange(moment(this.state.sdate).unix() * 1000, moment(this.state.tdate).unix() * 1000)
    }
    onpresetController(m, st) {
        const { stampFilterRange } = this.props
        this.setState({
            sdate: st[0],
            tdate: st[1],
        }, () => {
            stampFilterRange(moment(this.state.sdate).unix() * 1000, moment(this.state.tdate).unix() * 1000)
        })
    }
    onFilterChange(filters) {
        if (filters.length > 0) {
            this.props.stampFilterType(filters)
        }
    }
    componentDidMount() {
        if (!this.props.fetchedStamp) {
            this.props.fetchStamp(this.props.merchantId)
        }
    }
    show() {
        return (<div>
            <div className='history__bar'>
                <h2 className="history__title"> Stamp </h2>
                <DateRange
                    className='history__date'
                    format={dateFormat}
                    defaultValue={[this.state.sdate, this.state.tdate]}
                    onChange={(s, t) => this.onhandleChange(s, t)}
                    onOk={() => this.onOk()}
                    footerController={(m, st) => this.onpresetController(m, st)}
                    showTime
                />
                {/* <Popover content={<FilterBox handleSumbit={this.onFilterChange} />}>
                        <Button icon='filter' className='history__filter'>
                            Filter
                    </Button>
                    </Popover> */}
                <Button icon='delete' className="history__filter__clear" onClick={() => { this.props.stampFilterClear() }}> Clear Filter </Button>
                <ExportCSV csvData={createCsvData(createRows(this.props.data))} fileName='stamp' />
            </div>
            <Table
                size='middle'
                columns={crateColummns()}
                dataSource={createRows(this.props.data)}
                scroll={{ x: 1500 }} sticky
            />
        </div>
        )
    }
    render() {
        return (
            <Card
                loading={!this.props.fetchedStamp}
            >
                {this.show()}
            </Card>
        )
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        fetchStamp: (merchantId) => dispatch(Actions.fetchStampHistory(merchantId)),
        stampFilterRange: (from, to) => dispatch(Actions.addStampFilterRange(from, to)),
        stampFilterClear: () => dispatch(Actions.clearStampFilter()),
        stampFilterType: (filterType) => dispatch(Actions.addStampFilterType(filterType ? filterType : []))
    }
}
const mapStateToProps = state => {
    const { reportState, authState } = state
    const { stamp } = reportState.history
    const { temp } = stamp
    return {
        data: temp,
        fetchedStamp: reportState.fetchedStamp | false,
        merchantId: authState.status === 'loggedIn' && state.authState.userInfo ? authState.userInfo.merchantId : ''
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(StampHistory)