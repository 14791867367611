import * as _ from 'lodash'
import * as Colors from '../../color'
import moment from 'moment'
import Highcharts from 'highcharts'
var ugly = ['', '2014', '', '2015', ''];
var cats = ugly.concat(ugly).concat(ugly).concat(ugly).concat(ugly);
export const pointAndMemberFormatter = (arr = []) => {
    const distinctYear = [...new Set(arr.map(pm => pm.year))]
    // let state = distinctYear.reduce((a, b) => (a[b] = {}, a), {})
    let state = distinctYear.reduce((obj, v) => { obj[v] = {}; return obj; }, {})
    distinctYear.forEach(y => {
        let val = arr.filter(pm => pm.year === y).map(v => {
            return {
                year: v.year,
                totalMember: v.totalMember,
                point: v.point,
                month: v.month
            }
        })
        var pointMonth = Array.apply(null, Array(12)).map(function () { return 0 });
        var memberMonth = Array.apply(null, Array(12)).map(function () { return 0 });
        val.forEach(pm => {
            memberMonth[pm.month - 1] = pm.totalMember * 100
            pointMonth[pm.month - 1] = pm.point
        })
        let obj = {
            'point': pointMonth,
            'member': memberMonth
        }
        state = {
            ...state,
            [y]: obj
        }
    })
    let result = []
    let legend = 0
    distinctYear.map(y => {
        legend++
        return ([{
            name: 'Point',
            color: Colors.BannaColor,
            data: state[y].point,
            showInLegend: legend <= 1 ? true : false,
            stack: y,
            xAxis: 1
        },
        {
            name: 'Member',
            color: Colors.SkyBlueColor,
            data: state[y].member,
            stack: y,
            showInLegend: legend <= 1 ? true : false,
            xAxis: 1
        }
        ])
    }).map(arr => result.push(arr[0], arr[1]))
    return result
}
export const pointAndMemberMonthlyConfig = (arr = []) => {
    return {
        chart: {
            type: 'column'
        },

        title: {
            text: 'Points And Members'
        },

        xAxis: [
            {
                categories: cats,
                labels: { autoRotation: false, style: { textOverflow: 'none' } },
                tickWidth: 0,
            },
            {
                categories: ['Jan', 'Feb', 'Mar', 'April', 'May', 'June', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                lineColor: '#ffffff',
                tickWidth: 0,

                //     plotBands : [ {  from:-0.5, to:0.5, color:"#eeeeee"  }, 
                //                   {  from: 1.5, to:2.5, color:"#eeeeee"  },
                //                    {  from: 3.5, to:4.5, color:"#eeeeee"  }]       
            },
        ],

        yAxis: {
            allowDecimals: false,
            min: 0,
            title: {
                text: 'points and members monthly'
            }
        },
        credits: {
            enabled: false
        },
        tooltip: {
            formatter: function () {
                let total = this.point.stackTotal - this.y
                if (this.series.name === 'Member') {
                    total += this.y / 100
                } else {
                    total = total / 100
                    total = total + this.y
                }
                const y = this.series.name === 'Member' ? this.y / 100 : this.y
                return '<b>' + this.x + ' ' + this.series.options.stack + '</b><br/>' +
                    this.series.name + ': ' + y + '<br/>' +
                    'Total: ' + total;
            }
        },
        plotOptions: {
            column: {
                stacking: 'normal',
                groupPadding: 0.10,
                size: '100%'
            },

        },
        series: pointAndMemberFormatter(arr)
    }
}
export const trendConfig = (trends = []) => {
    var data = Array(trends.length).fill().map(p => Array(2).fill().map(p => p))
    _.orderBy(trends, 'createdAt', 'asc').map((p, index) => {
        data[index][0] = p.createdAt * 1000
        data[index][1] = p.point
        return null
    })
    return {
        rangeSelector: {
            selected: 1,
        },
        title: {
            text: 'Point Trends',
        },
        credits: {
            enabled: false
        },
        series: [
            {
                name: 'Point',
                data,
                tooltip: {
                    valueDecimals: 2,
                },
            },
        ],
    }
}
const initPieConfig = { totalMember: 0, spendPoint: 0, earnPoint: 0, earnEngage: 0, spendEngage: 0 }

export const pieConfig = (obj = initPieConfig) => {
    const pieSerie = [{
        name: 'Total',
        colorByPoint: true,
        data: [{
            name: 'Reward Points',
            y: obj.earnPoint,
            color: Colors.BannaColor,
            sliced: true,
            selected: true
        }, {
            name: 'Redeem Points',
            color: Colors.WatermelonColor,
            y: - obj.spendPoint
        }, {
            name: 'Earn Engagement',
            color: Colors.ColorTwo,
            y: obj.earnEngage
        }, {
            name: 'Spend Engagement',
            color: Colors.CantelColor,
            y: obj.spendEngage
        }, {
            name: 'Members',
            y: obj.totalMember,
            color: Colors.SkyBlueColor
        }]
    }]
    const config = {
        chart: {
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            type: 'pie',
            defaultSeriesType: 'areaspline'
        },
        title: {
            text: 'Point Summary'
        },
        tooltip: {
            pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
        },
        accessibility: {
            point: {
                valueSuffix: '%'
            }
        },
        credits: {
            enabled: false
        },
        plotOptions: {
            pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                size: '100%',
                dataLabels: {
                    enabled: false,
                    format: '<b>{point.name}</b>: {point.percentage:.1f} %'
                },
                showInLegend: true,
            }
        },
        series: pieSerie
    }
    return config
}
var days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
var times = [
    '1 AM', '2 AM', '3 AM', '4 AM', '5 AM', '6 AM', '7 AM', '8 AM', '9 AM', '10 AM', '11 AM', '12 AM',
    '1 PM', '2 PM', '3 PM', '4 PM', '5 PM', '6 PM', '7 PM', '8 PM', '9 PM', '10 PM', '11 PM', '12 PM',
]
export const pointLastMonthFormatter = (arr = []) => {
    // weekdays state obj keys
    let state = days.reduce((obj, v) => { obj[v] = {}; return obj; }, {})
    // day's hrs initate
    days.forEach(day => {
        //make 24 hrs obj
        let obj24 = times.reduce((obj, v) => { obj[v] = 0; return obj; }, {})
        state = { ...state, [day]: obj24 }
    })
    arr.forEach(lst => {
        // const format = moment(lst.createdAt).format(DATE_FORMAT_DATE_STRING)
        // console.log('lastMonth', format)
        const date = new Date(lst.createdAt)
        let day = days[date.getDay()], hr = times[date.getHours() - 1]
        let dayState = state[day]
        let count = dayState[hr] || 0
        count += 1
        dayState = {
            ...dayState,
            [hr]: count
        }
        state = {
            ...state,
            [day]: dayState
        }
    })
    //check state counter
    let rootCount = 0
    Object.values(state).forEach(dstate => {
        Object.values(dstate).forEach(dayCount => rootCount += dayCount)
    })
    rootCount !== arr.length ? console.log('Hourly counting Failed') : console.log('Hourly counting Success')
   // console.log('state', state)
    return convertHeatMapSeries(state)
}
export const convertHeatMapSeries = (state = {}) => {
    let hrState = times.reduce((obj, v) => { obj[v] = {}; return obj; }, {})
    times.forEach(hr => {
        let dayObj = days.reduce((obj, v) => { obj[v] = []; return obj; }, {})
        hrState = { ...hrState, [hr]: dayObj }
    })
    //{ '2 AM':{ 'sun':[],  'sat':[] } }
    // get hourly keys

    Object.keys(hrState).forEach(hrKey => {
        //get hourly key's weekday 
        let weekdayHour = []
        Object.keys(hrState[hrKey]).forEach(dayKey => {
            // create Heat Obj
            let heatObj = { x: 0, y: 0, value: 0 }
            //state[dayKey][hrKey] > 0 && console.log('X-Y Value', state[dayKey][hrKey])
            heatObj = {
                ...heatObj,
                y: days.indexOf(dayKey),
                x: times.indexOf(hrKey),
                value: state[dayKey][hrKey]
            }
            weekdayHour.push(heatObj)
        })
        hrState = { ...hrState, [hrKey]: weekdayHour }
    })
    //   console.log('hourly state', hrState)
    let result = []
    Object.values(hrState).forEach(objArr => {
        result.push.apply(result, objArr)
    })
    // console.log('hourly state', result)
    return result
}
function getPointCategoryName(point, dimension) {
    var series = point.series,
        isY = dimension === 'y',
        axis = series[isY ? 'yAxis' : 'xAxis'];
    return axis.categories[point[isY ? 'y' : 'x']];
}

export const heatMapConfig = (data = []) => {
    return {
        chart: {
            type: 'heatmap',
            marginTop: 40,
            marginBottom: 80,
            plotBorderWidth: 1
        },
        title: {
            text: 'Last 90 days Sale'
        },
        credits: {
            enabled: false
        },
        xAxis: {
            categories: times,
            visible: true,
        },
        yAxis: {
            categories: days,
            visible: true,
            // title: null,
            title: {
                text: 'Last 90days per daily sales',
                color: 'blue'
            },
            reversed: true
        },

        accessibility: {
            point: {
                descriptionFormatter: function (point) {
                    var ix = point.index + 1,
                        xName = getPointCategoryName(point, 'x'),
                        yName = getPointCategoryName(point, 'y'),
                        val = point.value;
                    return ix + '. ' + xName + ' sales ' + yName + ', ' + val + '.';
                }
            }
        },
        colorAxis: {
            min: 0,
            minColor: '#FFFFFF',
            maxColor: '#2f486e'
        },

        legend: {
            align: 'right',
            layout: 'vertical',
            margin: 0,
            verticalAlign: 'top',
            y: 25,
            symbolHeight: 280
        },

        tooltip: {
            formatter: function () {
                return '<b>' + getPointCategoryName(this.point, 'x') + '</b> sold <br><b>' +
                    this.point.value + '</b> items on <br><b>' + getPointCategoryName(this.point, 'y') + '</b>';
            }
        },

        series: [{
            name: 'Sales per employee',
            borderWidth: 1,
            data: pointLastMonthFormatter(data),
            // dataLabels: {
            //     enabled: true,
            //     color: '#000000'
            // },
            visible: true
        }],
        responsive: {
            rules: [{
                condition: {
                    maxWidth: 600
                },
                chartOptions: {
                    yAxis: {
                        labels: {
                            formatter: function () {
                                return this.value.charAt(0);
                            }
                        },
                        showInLegend: true,
                    },

                },
            }]
        }

    }
}
const Months = ['Jan', 'Feb', 'Mar', 'April', 'May', 'June', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
export const monthlyRewardAndRedeemDataFomat = (arr = []) => {
    //console.log('reward and redeem', arr)
    let distinctYear = [...new Set(arr.map(pm => pm.year))]
    distinctYear = distinctYear.sort((a, b) => a - b)
    let cat = [], earn = [], spend = []
    distinctYear.forEach(y => {
        let val = arr.filter(pm => pm.year === y).map(v => {
            return {
                year: v.year,
                totalMember: v.totalMember,
                spendPoint: v.spendPoint,
                point: v.point,
                month: v.month,
                monthName: Months[v.month - 1],
                key: `${v.year} ${Months[v.month - 1]}`
            }
        })
        val = _.orderBy(val, 'month', 'asc')
        val.forEach(v => {
            cat.push(v.key)
            earn.push(v.point)
            spend.push(v.spendPoint)
        })
    })

    return { categories: cat, spend: spend, earn: earn }
}
export const MonthlyRedeemAndRewardConfig = (arr = []) => {
    const { categories, spend, earn } = monthlyRewardAndRedeemDataFomat(arr)

    return {
        chart: {
            type: 'column'
        },
        title: {
            text: 'Earn And Spend Graph'
        },
        accessibility: {
            point: {
                valueDescriptionFormat: '{index}. Age {xDescription}, {value}%.'
            }
        },
        xAxis: [{
            categories: categories,
            reversed: false,
            labels: {
                enabled: true
            },
            accessibility: {
                description: 'Point (earn)'
            }
        }, {
            opposite: true,
            reversed: false,
            categories: categories,
            labels: {
                enabled: false
            },
            accessibility: {
                description: 'Point (spend)'
            }
        }],
        yAxis: {
            title: {
                text: 'earn and spend'
            },
            labels: {
                formatter: function () {
                    if (this.value > 1000) return Highcharts.numberFormat(this.value / 1000, 1) + "K";  // maybe only switch if > 1000
                    return Highcharts.numberFormat(this.value, 0);
                }
            },
            accessibility: {
                description: 'Point usage',
                rangeDescription: 'Range: 0 to 5%'
            }
        },
        plotOptions: {
            series: {
                stacking: 'normal'
            }
        },
        credits: {
            enabled: false
        },
        tooltip: {
            formatter: function () {
                return '<b>' + this.series.name + ',' + this.point.category + '</b><br/>' +
                    'Usage: ' + this.point.y + ' points';
            }
        },
        series: [{
            name: 'Earn',
            data: earn,
            color: Colors.SkyBlueColor
        }, {
            name: 'Spend',
            data: spend,
            color: Colors.WatermelonColor
        }]
    }
}

export const pointFilter = ({ pointTrend = [], monthlyMemberAndPoint = [], from, to }) => {
    const fromDate = moment(from).toDate()
    const toDate = moment(to).toDate()
    const fromUnix = moment(from).unix()
    const toUnix = moment(to).unix()
    let sameYear = false
    const distinctYear = []
    if (fromDate.getFullYear() === toDate.getFullYear()) {
        sameYear = true
        distinctYear.push(fromDate.getFullYear())
    } else {
        distinctYear.push(fromDate.getFullYear(), toDate.getFullYear())
    }
    // make state
    let state = distinctYear.reduce((obj, v) => { obj[v] = []; return obj; }, {})
    const fromMonth = fromDate.getMonth() + 1
    const toMonth = toDate.getMonth() + 1
    const includeCurrentMonth = (sameYear && (toMonth !== fromMonth)) ? false : true
    let fMonths = []
    let max = sameYear ? toMonth : 12
    max = includeCurrentMonth ? max : max - 1
    for (var m = fromMonth; m <= max; m++) {
        fMonths.push(m)
    }
    state = {
        ...state,
        [fromDate.getFullYear()]: fMonths
    }
    let tMonths = []
    const min = sameYear ? fromMonth : 1
    let _max = includeCurrentMonth ? toMonth : toMonth - 1
    for (var t = min; t <= _max; t++) {
        tMonths.push(t)
    }
    tMonths = tMonths.concat(state[toDate.getFullYear()])
    state = {
        ...state,
        [toDate.getFullYear()]: [...new Set(tMonths)]
    }
    let monthlyData = []
    const getSummary = () => {
        let earnPoint = 0, earnEngage = 0, spendPoint = 0, spendEngage = 0, totalMember = 0
        Object.keys(state).forEach(y => {
            const months = state[y]
            const mData = monthlyMemberAndPoint.filter(mp => mp.year === parseInt(y, 10))
            for (const _m of months) {
                const filterValue = mData.filter(m => m.month === parseInt(_m, 10))
                if (filterValue.length > 0) {
                    monthlyData.push({ ...filterValue[0] })
                    earnPoint = earnPoint + filterValue[0].point
                    earnEngage = earnEngage + filterValue[0].earnCount
                    spendPoint = spendPoint + filterValue[0].spendPoint
                    spendEngage = spendEngage + filterValue[0].spendCount
                    totalMember = totalMember + filterValue[0].totalMember
                }
            }
        })
        return { earnPoint, earnEngage, spendPoint, spendEngage, totalMember }
    }
    const getPointTrend = pointTrend.filter(p => p.createdAt >= fromUnix && p.createdAt <= toUnix) || []
    return {
        summary: { ...getSummary() },
        pointTrend: getPointTrend,
        monthlyMemberAndPoint: monthlyData
    }
}