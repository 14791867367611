import React from 'react';
import './style.css';
import { Spin } from 'antd';


export default class Progress extends React.Component {
  render() {
    return (
      <div className='example'>
        <Spin />
      </div>
    );
  }
}
