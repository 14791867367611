import React from 'react';
import { RouteComponentProps } from 'react-router';
import { Field, BaseFieldProps, WrappedFieldProps } from 'redux-form';
import moment from 'moment';
import FormItem from 'antd/lib/form/FormItem';

interface Props extends RouteComponentProps<{}>, BaseFieldProps<{}> {
  loading: boolean;
}

const renderSpan: any = (props: WrappedFieldProps & Props) => {
  const {
    input: { value },
    ...rest
  } = props;
  return (
    <FormItem {...rest} className="summary-box">
      <span className="ant-form-text">
        {moment(value[0]).format('DD/MM/YYYY')}
      </span>
      <span className="ant-form-text">-</span>
      <span className="ant-form-text">
        {moment(value[1]).format('DD/MM/YYYY')}
      </span>
    </FormItem>
  );
};

class SpanDateRangeField extends React.Component<Props> {
  render() {
    const { component, ...rest } = this.props;
    return <Field {...rest} component={renderSpan} />;
  }
}

export default SpanDateRangeField as any;
