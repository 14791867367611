import * as React from 'react';
import { Card, Table } from "antd";
import { RouteComponentProps, withRouter } from 'react-router';
import { connect } from 'react-redux';
import { GetCustomerGroup, GetAllCustomer } from "./redux";
import { Customer } from "./state";
import { CustomerModel } from "./model";
import { McommerceState } from "./redux/state";
import { ExportCSV } from "../report/excel/exportCSV";

const { createColumns, createRows, createCsvData } = CustomerModel
interface Props extends RouteComponentProps {
    getAllCustomer: (id: string) => any;
    getCustomerGroup: (id: string) => any;
    onDetailView: (id: string) => any;
    fetchedCustommer: boolean;
    fetchedGroup: boolean;
    merchantId: string;
    customers: Customer[];
}
class CustomerList extends React.Component<Props> {
    constructor(props: Props) {
        super(props);
    }
    componentDidMount() {
        const { merchantId, getAllCustomer, getCustomerGroup, fetchedCustommer, fetchedGroup } = this.props
        if (!fetchedCustommer) getAllCustomer(merchantId)
        if (!fetchedGroup) getCustomerGroup(merchantId)
    }
    render() {
        return (
            <Card title="Customer List"
                extra={
                    <>
                        <ExportCSV csvData={createCsvData(createRows(this.props.customers))} fileName={'Customerlist'} />
                    </>
                }
                loading={!this.props.fetchedGroup && !this.props.fetchedCustommer} >
                <Table
                    columns={createColumns(id => this.props.onDetailView(id))}
                    dataSource={createRows(this.props.customers)}
                />
            </Card>
        );
    }

}

const mapDispatchToProps = (dispatch: any, { history }: Props) => {
    return {
        onDetailView: (id: string) => history.push(`/mcommerce/${id}/customer`),
        getAllCustomer: (merchantId: string) => dispatch(GetAllCustomer(merchantId)),
        getCustomerGroup: (merchantId: string) => dispatch(GetCustomerGroup(merchantId))
    }
}
const mapStateToProps = (state: any) => {
    const { authState, mcommerceState: s } = state as { mcommerceState: McommerceState, authState: any }
    return {
        customers: s.customer.origin,
        fetchedCustomer: s.fetchedCustomer,
        fetchedGroup: s.fetchedGroup,
        merchantId: authState.status === 'loggedIn' && state.authState.userInfo ? authState.userInfo.merchantId : ''
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CustomerList)) as any;
