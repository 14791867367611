import React from 'react';
import { RouteComponentProps } from 'react-router';
import { Field, BaseFieldProps, WrappedFieldProps } from 'redux-form';
import FormItem from 'antd/lib/form/FormItem';

interface Props extends RouteComponentProps<{}>, BaseFieldProps<{}> {
  loading: boolean;
}

const renderSpan: any = (props: WrappedFieldProps & Props) => {
  const {
    input: { value },
    ...rest
  } = props;
  return (
    <FormItem {...rest} className="summary-box">
      <span className="ant-form-text">{value}</span>
    </FormItem>
  );
};

class SpanField extends React.Component<Props> {
  render() {
    const { component, ...rest } = this.props;
    return <Field {...rest} component={renderSpan} />;
  }
}

export default SpanField as any;
