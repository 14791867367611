import * as React from "react";
import 'antd/dist/antd.css';
import { Row, Col, Card, Button, Avatar, Table } from "antd";
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from "react-router";
import moment from 'moment'
import PanelBox from "../Charts/PanelBox/PanelBox";
import { formatMoney } from "../util";
import BarChart from "./Charts/BarChart";
import { McommerceState, } from "./redux/state";
import { Item, Order, Variation } from "./state";
import { DateRange } from './components'
import { GetAllOrder, GetAllProduct, GetAllVariation } from "./redux";
import { BarChartConfig, productDashboardcreateColumns, productDashobardCreateRows, getBarConfigFormatData } from "./model";
import * as Colors from '../color'
interface Filter {
    from: number;
    to: number;
}
interface Props extends RouteComponentProps {
    getAllProduct: (id: string) => any;
    getAllVariation: (id: string) => any;
    getAllOrder: (id: string) => any;
    fetchedOrder: boolean;
    orders: Order[];
    fetchedProduct: boolean;
    fetchedVaritaion: boolean;
    merchantId: string;
    products: Item[];
    variations: Variation[]
}
const defaultState = {
    orders: [],
    products: [],
    items: [],
    totalSales: 0,
    totalOrders: 0,
    itemsAndVartions: [],
    filter: { from: moment().startOf('month').unix() * 1000, to: moment().endOf('month').unix() * 1000 },
}
type S = |
{ orders: Order[], products: Item[], filter: Filter, items: any[], itemsAndVartions: any[], totalSales: number, totalOrders: number }
class ProductDashboard extends React.Component<Props, S> {
    constructor(props: Props) {
        super(props)
        this.state = { ...defaultState }
        this.onOk = this.onOk.bind(this)
        this.onClear = this.onClear.bind(this)
        this.onhandleChange = this.onhandleChange.bind(this)
        this.init = this.init.bind(this)
        this.onpresetController = this.onpresetController.bind(this)
    }
    onhandleChange(m: any, st: any) {
        this.setState({
            ...this.state,
            filter: {
                from: moment(st[0]).unix() * 1000,
                to: moment(st[1]).unix() * 1000
            }
        })
    }
    onpresetController(m: any, st: any) {
        const from = moment(st[0]).unix() * 1000
        const to = moment(st[1]).unix() * 1000
        const { orders, products } = this.state
        //uconst { origin } = this.state
        const state = getBarConfigFormatData(orders, products, { from, to })
        this.setState({
            ...this.state,
            items: state.items,
            itemsAndVartions: state.itemsAndVartions,
            totalOrders: state.totalOrders,
            totalSales: state.totalSales,
            filter: {
                from,
                to
            }
        })
    }
    onOk() {
        const { orders, products, filter } = this.state
        const state = getBarConfigFormatData(orders, products, filter)
        this.setState({
            ...this.state,
            items: state.items,
            itemsAndVartions: state.itemsAndVartions,
            totalOrders: state.totalOrders,
            totalSales: state.totalSales
        })
    }
    onClear() {
        const { orders, products } = this.state
        const state = getBarConfigFormatData(orders, products, { from: 0, to: 0 })
        this.setState({
            ...defaultState,
            products,
            orders,
            items: state.items,
            itemsAndVartions: state.itemsAndVartions,
            totalOrders: state.totalOrders,
            totalSales: state.totalSales
        })
    }
    init(props: Props) {
        const { merchantId, getAllProduct, fetchedProduct, } = props
        // const { getAllVariation, fetchedVaritaion } = props
        const { getAllOrder, fetchedOrder } = this.props
        if (!fetchedOrder) getAllOrder(merchantId)
        if (!fetchedProduct) getAllProduct(merchantId)
        if (fetchedOrder && fetchedProduct) {
            const { orders, products } = props
            const state = getBarConfigFormatData(orders, products, this.state.filter)
            this.setState({
                ...this.state,
                products,
                orders,
                items: state.items,
                totalSales: state.totalSales,
                totalOrders: state.totalOrders,
                itemsAndVartions: state.itemsAndVartions
            })
        }
        //if (!fetchedVaritaion) getAllVariation(merchantId)
    }
    componentDidMount() {
        this.init(this.props)
    }
    componentWillReceiveProps(nextProps: any) {
        this.init(nextProps)
    }
    render() {
        return (
            <div className='reward'>
                <div >
                    <Row gutter={24}>
                        <Col span={24}>
                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <div >
                                    <DateRange
                                        className='sale_date_range'
                                        defaultValue={[moment(this.state.filter.from), moment(this.state.filter.to)]}
                                        onChange={(s, t) => this.onhandleChange(s, t)}
                                        onOk={() => this.onOk()}
                                        footerController={(m, st) => this.onpresetController(m, st)}
                                    />
                                </div>
                                <div style={{ marginLeft: '10px', width: 'auto' }}>
                                    <Button icon='delete' onClick={() => this.onClear()} style={{ width: '100%' }} block>Clear Filter</Button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div style={{ marginTop: '12px' }}>
                    <Row gutter={24} >
                        <Col span={24}>
                            <Card
                                loading={!this.props.fetchedOrder && !this.props.fetchedProduct}
                                style={{ border: 'none' }}
                            >
                                <Row gutter={24}>
                                    <Col span={8}>
                                        {/* <Card
                                            loading={!this.props.fetchedOrder && !this.props.fetchedProduct}
                                        > */}
                                        <Row>
                                            <Col span={12}>
                                                <PanelBox className="card-item" loading={!this.props.fetchedOrder}>
                                                    <div style={{ display: "block", height: 50 }}>
                                                        <div style={{ color: 'blue', textAlign: 'center' }}>
                                                            <Avatar icon="calendar"
                                                                size={60}
                                                                style={{
                                                                    color: 'black',
                                                                    backgroundColor: 'white'
                                                                }} />
                                                        </div>
                                                        <div style={{ textAlign: 'center' }}>
                                                            <b style={{
                                                                color: Colors.LabelColor,
                                                                marginLeft: 5
                                                            }}>{moment(this.state.filter.from).format('DD/MM/YYYY')}</b>
                                                        </div>
                                                        <div style={{ textAlign: 'center' }}>
                                                            <span>
                                                                <b style={{ color: 'black', fontWeight: 'bold' }}>
                                                                    From
                                                        </b>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </PanelBox>
                                            </Col>
                                            <Col span={12}>
                                                <PanelBox className="card-item" loading={!this.props.fetchedOrder}>
                                                    <div style={{ display: "block", height: 50 }}>
                                                        <div style={{ color: 'blue', textAlign: 'center' }}>
                                                            <Avatar icon="calendar"
                                                                size={60}
                                                                style={{
                                                                    color: 'black',
                                                                    backgroundColor: 'white'
                                                                }} />
                                                        </div>
                                                        <div style={{ textAlign: 'center' }}>
                                                            <b style={{
                                                                color: Colors.LabelColor,
                                                                marginLeft: 5
                                                            }}> {moment(this.state.filter.to).format('DD/MM/YYYY')} </b>
                                                        </div>
                                                        <div style={{ textAlign: 'center' }}>
                                                            <span>
                                                                <b style={{ color: 'black', fontWeight: 'bold' }}>
                                                                    To
                                                        </b>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </PanelBox>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col span={12}>
                                                <PanelBox className="card-item" loading={!this.props.fetchedOrder}>
                                                    <div style={{ display: "block", height: 80 }}>
                                                        <div style={{ color: 'blue', textAlign: 'center' }}>
                                                            <Avatar icon="shopping-cart"
                                                                size={60}
                                                                style={{
                                                                    color: 'black',
                                                                    backgroundColor: 'white'
                                                                }} />
                                                        </div>
                                                        <div style={{ textAlign: 'center' }}>
                                                            <b style={{
                                                                color: Colors.LabelColor,
                                                                marginLeft: 5
                                                            }}>{formatMoney(this.state.totalSales)} MMK</b>
                                                        </div>
                                                        <div style={{ textAlign: 'center' }}>
                                                            <span>
                                                                <b style={{ color: 'black', fontWeight: 'bold' }}>
                                                                    Sales
                                                        </b>

                                                            </span>
                                                        </div>
                                                    </div>
                                                </PanelBox>
                                            </Col>
                                            <Col span={12}>
                                                <PanelBox className="card-item" loading={!this.props.fetchedOrder}>
                                                    <div style={{ display: "block", height: 50 }}>
                                                        <div style={{ color: 'blue', textAlign: 'center' }}>
                                                            <Avatar icon="book"
                                                                size={60}
                                                                style={{
                                                                    color: 'black',
                                                                    backgroundColor: 'white'
                                                                }} />
                                                        </div>
                                                        <div style={{ textAlign: 'center' }}>
                                                            <b style={{
                                                                color: Colors.LabelColor,
                                                                marginLeft: 5
                                                            }}>{this.state.items.length} </b>
                                                        </div>
                                                        <div style={{ textAlign: 'center' }}>
                                                            <span>
                                                                <b style={{ color: 'black', fontWeight: 'bold' }}>
                                                                    Products
                                                        </b>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </PanelBox>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col span={12}>
                                                <PanelBox className="card-item" loading={!this.props.fetchedOrder}>
                                                    <div style={{ display: "block", height: 50 }}>
                                                        <div style={{ color: 'blue', textAlign: 'center' }}>
                                                            <Avatar icon="file-text"
                                                                size={60}
                                                                style={{
                                                                    color: 'black',
                                                                    backgroundColor: 'white'
                                                                }} />
                                                        </div>
                                                        <div style={{ textAlign: 'center' }}>
                                                            <b style={{
                                                                color: Colors.LabelColor,
                                                                marginLeft: 5
                                                            }}> {this.state.totalOrders} </b>
                                                        </div>
                                                        <div style={{ textAlign: 'center' }}>
                                                            <span>
                                                                <b style={{ color: 'black', fontWeight: 'bold' }}>
                                                                    Orders
                                                        </b>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </PanelBox>
                                            </Col>
                                            {/* <Col span={12}>
                                        <PanelBox className="card-item" loading={!this.props.fetchedOrder}>
                                            <div style={{ display: "block", height: 50 }}>
                                                <div style={{ color: 'blue', justifyContent: 'center' }}>
                                                    <Avatar icon="file"
                                                        size={60}
                                                        style={{
                                                            color: 'black',
                                                            backgroundColor: 'white'
                                                        }} />
                                                </div>
                                                <div>
                                                    <span>
                                                        <b style={{ color: 'black', fontWeight: 'bold' }}>
                                                            Orders
                                                        </b>
                                                        <b style={{
                                                            color: Colors.LabelColor,
                                                            marginLeft: 5
                                                        }}> {23} </b>
                                                    </span>
                                                </div>
                                            </div>
                                        </PanelBox>
                                    </Col> */}
                                        </Row>
                                        {/* </Card> */}
                                    </Col>
                                    <Col span={16}>
                                        {/* <Card
                                            loading={!this.props.fetchedOrder && !this.props.fetchedProduct}
                                        > */}
                                        <BarChart config={BarChartConfig(this.state.items)} />
                                        {/* </Card> */}
                                    </Col>
                                </Row>

                            </Card>
                        </Col>

                    </Row>
                </div>
                <div className='customer___body' style={{ marginTop: 12 }}>
                    <Row gutter={24} >
                        <Col span={24}>
                            <Card
                                loading={!this.props.fetchedOrder && !this.props.fetchedProduct}
                            >
                                <Table
                                    dataSource={productDashobardCreateRows(this.state.itemsAndVartions)}
                                    columns={productDashboardcreateColumns()}
                                    pagination={{ pageSize: 3 }}
                                />
                            </Card>
                        </Col>
                    </Row>
                    {/* <Row gutter={24} style={{ marginTop: '12px' }}>
                        <Col span={24}>
                            <Card
                                loading={false}
                            >
                                Conext Lastest
                            </Card>
                        </Col>
                    </Row> */}
                </div>
            </div >

        )
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        getAllProduct: (merchantId: string) => dispatch(GetAllProduct(merchantId)),
        getAllOrder: (merchantId: string) => dispatch(GetAllOrder(merchantId)),
        getAllVariation: (merchantId: string) => dispatch(GetAllVariation(merchantId))
    }
}
const mapStateToProps = (state: any) => {

    const { authState, mcommerceState: s } = state as { mcommerceState: McommerceState, authState: any }
    const { fetchedOrder, order } = s
    const { origin } = order
    return {
        products: s.product.origin,
        fetchedProduct: s.fetchedProduct,
        fetchedVaritaion: s.fetchedVariation,
        variations: s.variation.origin,
        fetchedOrder,
        orders: origin,
        merchantId: authState.status === 'loggedIn' && state.authState.userInfo ? authState.userInfo.merchantId : ''
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ProductDashboard));