import * as React from 'react';
import { Card } from "antd";
import { RouteComponentProps, withRouter } from "react-router";
import { connect } from 'react-redux';
import { GetAllOrder } from "./redux";
import { Order } from "./state";
import { Table } from 'antd';
import { OrderModel } from "./model";
import * as _ from 'lodash'
import { Button } from 'antd';
const { createColumns, createRows } = OrderModel
interface Props extends RouteComponentProps {
    getAllOrder: (id: string) => any;
    fetchedOrder: boolean;
    merchantId: string;
    orders: Order[];
    goHistory: () => any;
}
interface Filter {
    from: number;
    to: number;
    orderType: string[];
    orderStatus: string[];
}
const defaultState = {
    data: [],
    origin: [],
    filter: {
        from: 0,
        to: 0,
        orderStatus: [],
        orderType: []
    },
}
type S =
    | { data: Order[], origin: Order[], filter: Filter }
class ViewMore extends React.Component<Props, S> {
    constructor(props: Props) {
        super(props);
        this.state = { ...defaultState }
        // this.clearHandler = this.clearHandler.bind(this)
    }
    componentDidMount() {
        const { merchantId, getAllOrder, fetchedOrder } = this.props
        if (!fetchedOrder) getAllOrder(merchantId)

    }
    clearHandler = () => {
        const { origin } = this.state
        this.setState({
            ...defaultState,
            origin,
            data: origin
        })
    }

    render() {
        let { orders } = this.props
        orders = _.orderBy(orders, 'createdOn', 'desc')
        const data = orders.length > 5 ? orders.slice(0, 4) : orders
        return (
            <Card
                loading={!this.props.fetchedOrder}
                style={{ border: 'none' }}
            >
                <Table
                    bordered={false}
                    size='middle'
                    dataSource={createRows(data)}
                    columns={createColumns()}
                    pagination={false}
                >
                </Table>
                <div style={{ textAlign: 'right', marginTop: 10 }}>
                    <Button
                        onClick={() => this.props.goHistory()}
                        style={{ alignItem: 'flex-end', textColor: 'blue' }}
                    >
                        View all more
                </Button>
                </div>
            </Card>
        );
    }

}

const mapDispatchToProps = (dispatch: any, { history }: Props) => {
    return {
        goHistory: () => history.push('/mcommerce/order'),
        getAllOrder: (merchantId: string) => dispatch(GetAllOrder(merchantId)),
    }
}
const mapStateToProps = (state: any) => {
    const { authState, mcommerceState } = state
    const { fetchedOrder, order } = mcommerceState
    const { origin } = order
    return {
        orders: origin,
        fetchedOrder,
        merchantId: authState.status === 'loggedIn' && state.authState.userInfo ? authState.userInfo.merchantId : ''
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ViewMore)) as any