import { createComponent, customMap } from 'redux-form-antd';
import { Input } from 'antd';

const TextArea = Input.TextArea;
// tslint:disable-next-line:no-any
function mapFunction(mapProps: any, { input: { onChange } }: any) {
  return {
    ...mapProps,
    // tslint:disable-next-line:no-any
    onChange: (v: any) => onChange(v.nativeEvent.target.value),
  };
}
const textAreaFieldMap = customMap(mapFunction);

export default createComponent(TextArea, textAreaFieldMap);
