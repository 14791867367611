import React, { Component } from 'react'
import 'antd/dist/antd.css';
import { Card, Table } from 'antd'
import moment from 'moment'
import './css.less'
import { connect } from 'react-redux';
import * as Actions from '../redux/actions'
import { ExportCSV } from '../excel/exportCSV'
import { Alert, DateRange } from './component'
//import { EmptyBox } from '../../components'
import { ReturnModel } from './model'
const dateFormat = 'YYYY/MM/DD';
const { createColumns, createCsvData, createRows } = ReturnModel
class ReturnMemberList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            sdate: moment().subtract(1, 'month'),
            tdate: moment(),
            hasInit: false,
            loading: false,
            pending: false,
            fileName: "ReturnMembers_last_one_month"
        }
        this.onOk = this.onOk.bind(this)
        this.onhandleChange = this.onhandleChange.bind(this)
        this.init = this.init.bind(this)
        this.getFileName = this.getFileName.bind(this)
        this.onViewHandler = this.onViewHandler.bind(this)
        this.show = this.show.bind(this)
        this.onpresetController = this.onpresetController.bind(this)
    }
    init(props) {
        const { fetchedMember, getAllMember, merchantId, memberData, getReturnMember } = props
        const { fetchedRootPoint, fetchedReturnMember, getAllPoint, pointData, returnMemberData } = props
        const { fetchedReward, rewardData, getAllReward } = props
        const { fetchedRootPointCount, totalCount, getAllPointCount } = props
        if (!fetchedMember) getAllMember(merchantId)
        if (!fetchedRootPointCount) getAllPointCount(merchantId)
        if (!fetchedReward) getAllReward(merchantId)
        if (fetchedRootPointCount && !fetchedRootPoint && !this.state.pending) {
            this.setState({
                pending: true
            }, () => {
                getAllPoint(merchantId, totalCount)
            })
        }
        if (fetchedReward && fetchedMember && fetchedRootPoint) {
            const { sdate, tdate, hasInit } = this.state
            if (!hasInit && returnMemberData.length <= 0 && !fetchedReturnMember) {
                let newObj = { members: memberData, points: pointData, rewards: rewardData, from: moment(sdate).unix() * 1000, to: moment(tdate).unix() * 1000 }
                this.setState({
                    hasInit: true,
                })
                getReturnMember({ ...newObj })
            }
            if (fetchedReturnMember) {
                this.setState({
                    loading: true
                })
            }
        }
    }
    componentDidMount() {
        this.init(this.props)
    }
    componentWillReceiveProps(nextProps) {
        this.init(nextProps)
    }
    onhandleChange(m, st) {
        this.setState({
            sdate: moment(st[0]),
            tdate: moment(st[1])
        })
    }
    onpresetController(m, st) {
        const { memberData, pointData, getReturnMember, rewardData } = this.props
        this.setState({
            sdate: st[0],
            tdate: st[1],
        }, () => {
            let newObj = { members: memberData, points: pointData, rewards: rewardData, from: moment(this.state.sdate).unix() * 1000, to: moment(this.state.tdate).unix() * 1000 }
            getReturnMember({ ...newObj })
        })
    }
    onOk() {
        const { memberData, pointData, getReturnMember, rewardData } = this.props
        let { sdate, tdate } = this.state
        let newObj = { members: memberData, points: pointData, rewards: rewardData, from: moment(sdate).unix() * 1000, to: moment(tdate).unix() * 1000 }
        this.setState({
            loading: false,
            fileName: this.getFileName()
        }, () => {
            getReturnMember({ ...newObj })
        })
    }
    onViewHandler(memberId) {
        if (memberId !== undefined) {
            const { onView } = this.props
            onView(memberId)
        }
    }
    getFileName() {
        let from = moment(this.state.sdate).format(dateFormat).toString()
        let to = moment(this.state.tdate).format(dateFormat).toString()
        return "ReturnMembers_" + from + "_" + to
    }
    show() {
        const { returnMemberData } = this.props
        return (<div>
            <div className='active__member__bar'>
                <h2 className="active__member__title"> Return Members </h2>
                <DateRange
                    className='active_member__date'
                    format={dateFormat}
                    defaultValue={[this.state.sdate, this.state.tdate]}
                    onChange={(s, t) => this.onhandleChange(s, t)}
                    onOk={() => this.onOk()}
                    footerController={(m, st) => this.onpresetController(m, st)}
                    showTime
                />
                <ExportCSV csvData={createCsvData(createRows(returnMemberData))} fileName={this.state.fileName} />
            </div>
            <Table
                size='middle'
                columns={createColumns({ onView: (memberId) => this.onViewHandler(memberId) })}
                dataSource={createRows(returnMemberData)}
                scroll={{ x: 1500 }} sticky
            />
        </div >
        )
    }
    render() {
        return (
            <div className='active__member'>
                <Alert message="Members who earn point at least one time in the given period and have more than one lifetime points " />
                <Card
                    loading={!this.state.loading}
                >
                    {this.show()}
                </Card>
            </div>
        )
    }
}
const mapDispatchToProps = (dispatch, { history }) => {
    return {
        getAllMember: (merchantId) => dispatch(Actions.GetAllMember(merchantId)),
        onView: (memberId) => history.push(`/member/detail/${memberId}`),
        getAllPoint: (merchantId, totalCount) => dispatch(Actions.GetAllPoint(merchantId, totalCount)),
        getAllReward: (merchantId) => dispatch(Actions.fetchRewardHistory(merchantId)),
        getAllPointCount: (merchantId) => dispatch(Actions.GetAllTotalPointCount(merchantId)),
        getReturnMember: ({ members, points, rewards, from, to }) => dispatch(Actions.GetReturnMembers({ members, points, rewards, from, to }))
    }
}
const mapStateToProps = state => {
    const { authState, reportState } = state
    const { member, fetchedMember, fetchedRootPoint, root, fetchedReturnMember } = reportState
    const { fetchedReward, history } = reportState
    const { totalCount, fetchedRootPointCount, point } = root
    const { reward } = history
    const [rewardData] = [reward.origin]
    const { origin, returning } = member
    return {
        pointData: point,
        memberData: origin,
        rewardData: rewardData,
        totalCount: totalCount,
        fetchedRootPointCount: fetchedRootPointCount,
        returnMemberData: returning,
        fetchedMember: fetchedMember,
        fetchedRootPoint: fetchedRootPoint,
        fetchedReward: fetchedReward,
        fetchedReturnMember: fetchedReturnMember,
        merchantId: authState.status === 'loggedIn' && state.authState.userInfo ? authState.userInfo.merchantId : ''
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ReturnMemberList)