import * as React from "react";
import { Customer } from "../state";
import { Avatar, Button } from "antd";
import * as  _ from 'lodash'
import nophoto from '../img/noprofile.png';
import ButtonGroup from 'antd/lib/button/button-group';
export const createRows = (rows: Customer[] = []) => {
    let i = 0
    return rows.length > 0 ?
        rows.map(row => {
            i++;
            return {
                key: i,
                id: row.id,
                name: row.name,
                email: row.email,
                phoneNo: row.phone,
                photo: row.photo,
                dateOfBirth: row.dateOfBirth,
                gender: row.gender,
                address: row.address,
                date: new Date(row.createdOn).toDateString(),
            }
        }) :
        []
}


export const createColumns = (view: (id: string) => any) => {
    const columns = [
        {
            title: 'Photo',
            width: 100,
            key: 'photo',
            fixed: 'left',
            render: (v: { photo: string }) => < Avatar src={v.photo || nophoto} size={70} />

        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            align: 'left',
            width: 120,
            render: (text: any) => <b style={{ color: 'blue' }}>{text}</b>
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            width: 150,
            render: (text: any) => text
        },
        {
            title: 'Phone Number',
            dataIndex: 'phoneNo',
            key: 'phoneNo',
            width: 150,
            render: (text: any) => text
        },
        {
            title: 'Gender',
            dataIndex: 'gender',
            key: 'gender',
            width: 120,
            render: (text: any) => text
        },
        {
            title: 'Address',
            dataIndex: 'address',
            key: 'address',
            width: 120,
            render: (text: any) => text
        },
        {
            title: 'DateOfBirth',
            dataIndex: 'dateOfBirth',
            key: 'dateOfBirth',
            width: 120,
            render: (text: any) => text
        },
        {
            title: 'Action',
            key: 'action',
            align: 'center',
            width: '12%',
            fixed: 'right',
            render: (v: { id: string }) => {
                return (
                    <ButtonGroup>
                        <Button
                            icon="eye"
                            // tslint:disable-next-line:jsx-no-lambda
                            onClick={() => view(v.id)}
                        > detail </Button>
                    </ButtonGroup>
                );

            }
        }

    ];
    return columns
}

export const createCsvData = (rows: any[] = []) => {
    return rows.length > 0 ?
        rows.map(row => {
            return {
                id: row.id,
                name: row.name,
                email: row.email,
                phoneNo: row.phoneNo,
                // photo: row.photo,
                dateOfBirth: row.dateOfBirth,
                gender: row.gender,
                address: row.address,
                date: row.date,
            }
        })
        : [];
}