import * as React from 'react'
import * as Colors from '../../../color'
import * as _ from 'lodash'
export const createRows = (rows = []) => {
    let i = 0
    let result = rows.length > 0 ?
        rows.map(row => {
            i++;
            return {
                key: i,
                event: 'award-point',
                from: row.uId ? row.uId.split('@')[0] : 'system',
                to: row.memberName,
                date: require('moment').unix(row.createdAt / 1000 | 0).format('LLL'),
                eod: require('moment').unix(row.expiredAt / 1000 | 0).format('ll'),
                ref: row.referenceNo,
                point: row.point,
                change: row.point,
            }
        }) :
        []
    return _.orderBy(result, 'date', 'desc')
}
export const crateColummns = () => {
    const columns = [
        // {
        //     title: 'Event', width: 100, dataIndex: 'event', key: 'event', fixed: 'left',
        //     // render: (text) => {
        //     //     return < Avatar size={64} icon='trophy' style={{ color: '#FFD700', backgroundColor: 'white', }}><b style={{ color: 'blue' }}>{text}</b></ Avatar>
        //     // },
        //     render: () => <Avatar src={rewadPhoto} size={70} />
        // },
        {
            title: 'From', dataIndex: 'from', key: 'from', fixed: 'left',
            render: text => <b style={{ color: Colors.BlackColor, fontSize: '16' }}> {text} </b>
        },
        {
            title: 'To', dataIndex: 'to', key: 'to',
            render: text => <b style={{ color: Colors.MemberColor, fontSize: '16' }}> {text}</b>
        },
        {
            title: 'Date', dataIndex: 'date', key: 'date',
        },

        {
            title: 'Expire Date', dataIndex: 'eod', key: 'eod',
        },
        {
            title: 'Branch', dataIndex: 'branch', key: 'branch',
        },
        {
            title: "Reference", dataIndex: 'ref', key: 'ref'
        },
        {
            title: "+100 Points", width: 120, dataIndex: 'change', key: 'change', fixed: 'right',
            render: text => <b style={{ color: Colors.BlackColor, fontSize: '14' }}>
                <div className='changes__points' style={{ display: 'flex' }}>
                    <div className='changes__plus' style={{ marginLeft: '15px' }} >
                    {parseInt(text, 10) > 0 && '+'}
                     </div>
                    <div className='changes__value'>{text}</div>
                    <div className='changes__unit' style={{ marginLeft: 'auto' }}>Points</div>
                </div>
            </b>
        }
    ]
    return columns
}

export const createCsvData = (rows = []) => {
    return rows.length > 0 ?
        rows.map(row => {
            return {
                event: row.event,
                point: row.point,
                from: row.from,
                to: row.to,
                date: row.date,
                expireDate: row.eod,
            }
        })
        : [];
}