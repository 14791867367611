import * as React from 'react'
import * as _ from 'lodash'
import * as Colors from '../../../color'
import moment from 'moment'
const dateFormat = 'YYYY/MM/DD';
export const initMemberDetail = { id: '', name: '', photo: '' }
export const GetMemberDetail = ({ members = [], points = [], rewards = [], memberId = null }) => {
    let _member = members.filter(m => m.id === memberId)[0]
    _member = {
        ..._member,
        joinedAt: moment(_member.joinedAt).format(dateFormat),
        lastActive: moment(_member.lastActive).format(dateFormat),
        firstActive: moment(_member.firstActive).format(dateFormat),
    }
    let _points = points.filter(p => p.memberId === memberId).map(p => {
        return { ...p, memberName: _member.displayName }
    })
    let _rewards = rewards.filter(r => r.memberId === memberId)
    return { member: _member, points: _points, rewards: _rewards }
}
export const lineChartConfig = ({ points = [] }) => {
    points = _.orderBy(points, 'createdAt', 'asc')
    const distinctPointDate = [...new Set(points.map(p => p.createdDate))]
    var data = Array(distinctPointDate.length).fill().map(p => Array(2).fill().map(p => p))

    distinctPointDate.forEach((pdate, index) => {
        var totalPoint = 0
        points.filter(p => p.createdDate === pdate).map(p => totalPoint += p.point)
        data[index][0] = (pdate && pdate.split("T")[0]) || pdate
        data[index][1] = totalPoint
    })
    // console.log('line chart data:', data)
    const config = {
        chart: {
            type: 'spline'
        },
        title: {
            text: 'Member Point Trend'
        },
        credits: {
            enabled: false
        },
        yAxis: {
            title: {
                text: 'member point trends',
                color: 'blue'
            },
        },
        xAxis: {
            visible: false,
        },
        series: [
            {
                name: 'Points',
                data: data,
                showInLegend: false,
            }
        ]
    };
    return config
}

export const createRows = (rows = []) => {
    rows = _.orderBy(rows, 'createdAt', 'desc')
    let i = 0
    let result = rows.length > 0 ?
        rows.map(row => {
            i++;
            return {
                key: i,
                event: 'award-point',
                from: row.uId ? row.uId.split('@')[0] : 'system',
                to: row.memberName,
                date: require('moment').unix(row.createdAt / 1000 | 0).format('LLL'),
                eod: require('moment').unix(row.expiredAt / 1000 | 0).format('ll'),
                ref: row.referenceNo,
                point: row.point,
                change: row.point,
            }
        }) :
        []
    return result
}
export const crateColumns = () => {
    const columns = [
        {
            title: 'From', dataIndex: 'from', key: 'from', fixed: 'left', width: 120,
            render: text => <b style={{ color: Colors.BlackColor, fontSize: '16' }}> {text} </b>
        },
        {
            title: 'To', dataIndex: 'to', key: 'to', width: 150, fixed: 'left',
            render: text => <b style={{ color: Colors.MemberColor, fontSize: '16' }}> {text}</b>
        },
        {
            title: 'Date', dataIndex: 'date', key: 'date',
        },
        {
            title: "+100 Points", width: 120, dataIndex: 'change', key: 'change', fixed: 'right',
            render: text => <b style={{ color: Colors.BlackColor, fontSize: '14' }}>
                <div className='changes__points' style={{ display: 'flex' }}>
                    <div className='changes__plus' style={{ marginLeft: '15px' }} >
                        {parseInt(text, 10) > 0 && '+'}
                    </div>
                    <div className='changes__value'>{text}</div>
                    <div className='changes__unit' style={{ marginLeft: 'auto' }}>Points</div>
                </div>
            </b>
        }
    ]
    return columns
}


