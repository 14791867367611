import * as React from "react";
import { Order } from "../state";
import * as  _ from 'lodash'

interface Filter {
    from: number;
    to: number;
    orderType: string[];
    orderStatus: string[];
}
export const createRows = (rows: Order[] = []) => {
    let i = 0
    rows = _.orderBy(rows, 'createdOn', 'desc')
    return rows.length > 0 ?
        rows.map(row => {
            //console.log('order value', typeof row.itemIds, '\n variation ids', row.itemVariationIds)
            i++;
            return {
                key: i,
                orderId: row.orderId,
                customer: row.customerName,
                phone: row.customerPhone,
                address: row.customerAddress,
                totalAmount: row.totalAmount,
                date: new Date(row.createdOn).toDateString(),
                point: row.earnPoints,
                status: row.orderStatus,
                taxAmount: row.totalAddedTaxAmount,
                type: row.orderType,
                payment: row.paymentType
            }
        }) :
        []
}

export const createColumns = () => {
    const columns = [
        {
            title: 'OrderId',
            dataIndex: 'orderId',
            key: 'orderId',
            align: 'left',
            render: (text: any) => <b style={{ color: 'blue' }}>{text}</b>
        },
        {
            title: 'Customer',
            dataIndex: 'customer',
            key: 'customer',
            render: (text: any) => text
        },
        {
            title: 'Phone',
            dataIndex: 'phone',
            key: 'phone',
            render: (text: any) => text
        },
        {
            title: 'Address',
            dataIndex: 'address',
            key: 'address',
            render: (text: any) => text
        },
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            render: (text: any) => text
        },
        {
            title: 'Total Amount',
            dataIndex: 'totalAmount',
            key: 'totalAmount',
            render: (text: any) => text
        },
        {
            title: 'Points',
            dataIndex: 'point',
            key: 'point',
            render: (text: any) => text
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (text: any) => <b style={{ color: text === 'Completed' ? 'green' : text === 'Pending' ? 'peru' : 'red', fontWeight: 'bold' }}>{text}</b>
        },
        {
            title: 'Order Type',
            dataIndex: 'type',
            key: 'type',
            render: (text: any) => text
        },
        {
            title: 'Payment',
            dataIndex: 'payment',
            key: 'payment',
            render: (text: any) => text
        },
    ];
    return columns
}

export const createCsvData = (rows: any[] = []) => {
    return rows.length > 0 ?
        rows.map(row => {
            return {
                orderId: row.orderId,
                customer: row.customer,
                totalAmount: row.totalAmount,
                date: row.date,
                point: row.point,
                status: row.status,
                taxAmount: row.taxAmount,
                type: row.type,
                payment: row.payment
            }
        })
        : [];
}
export const getOrder = (data: Order[], f: Filter) => {
    const conditions = (o: Order) => {
        if ((f.from > 0 && f.to > 0) && (f.orderStatus.length > 0) && (f.orderType.length > 0)) {
            if (o.createdOn > f.from && o.createdOn < f.to && f.orderType.includes(o.orderType) && f.orderStatus.includes(o.orderStatus)) return true
            return false
        }
        if ((f.from > 0 && f.to > 0) && (f.orderStatus.length > 0)) {
            if (o.createdOn > f.from && o.createdOn < f.to && f.orderStatus.includes(o.orderStatus)) return true
            return false
        }
        if ((f.orderStatus.length > 0) && (f.orderType.length > 0)) {
            if (f.orderStatus.includes(o.orderStatus) && f.orderType.includes(o.orderType)) return true
            return false
        }
        if ((f.from > 0 && f.to > 0) && (f.orderType.length > 0)) {
            if (o.createdOn > f.from && o.createdOn < f.to && f.orderType.includes(o.orderType)) return true
            return false
        }
        if (f.from > 0 && f.to > 0) {
            if (o.createdOn > f.from && o.createdOn < f.to) return true
            return false
        }
        if (f.orderStatus.length > 0) {
            if (f.orderStatus.includes(o.orderStatus)) return true
            return false
        }
        if (f.orderType.length > 0) {
            if (f.orderType.includes(o.orderType)) return true
            return false
        }
        return true
    }

    return data.filter(o => conditions(o))
}
