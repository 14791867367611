import * as React from 'react'
import moment from 'moment'
import { DatePicker, Button } from 'antd'
const { RangePicker } = DatePicker
const dateFormat = 'YYYY/MM/DD';

const DateRange = ({ defaultValue, onChange, onOk, className, footerController }) => {
    return (
        <RangePicker
            className={className}
            format={dateFormat}
            value={defaultValue}
            defaultValue={defaultValue}
            onChange={(s, t) => onChange(s, t)}
            onOk={() => onOk()}
            renderExtraFooter={() => {
                return (
                    <>
                        <Button style={{ marginLeft: 10 }} type='primary' size='small' onClick={() => footerController({}, [moment(), moment()])}>Today</Button>
                        <Button style={{ marginLeft: 10 }} type='primary' size='small' onClick={() => footerController({}, [moment().startOf('month'), moment().endOf('month')])}>This Month</Button>
                        <Button style={{ marginLeft: 10 }} type='primary' size='small' onClick={() => footerController({}, [moment(), moment().add(7, 'day')])}>Next 1Week</Button>
                        <Button style={{ marginLeft: 10 }} type='primary' size='small' onClick={() => footerController({}, [moment(), moment().add(14, 'day')])}>Next 2Week</Button>
                        <Button style={{ marginLeft: 10 }} type='primary' size='small' onClick={() => footerController({}, [moment(), moment().add(21, 'day')])}>Next 3Week</Button>
                        <Button style={{ marginLeft: 10 }} type='primary' size='small' onClick={() => footerController({}, [moment(), moment().add(1, 'month')])}>Next 1Month</Button>
                        <Button style={{ marginLeft: 10 }} type='primary' size='small' onClick={() => footerController({}, [moment(), moment().add(3, 'month')])}>Next 3Month</Button>
                        <Button style={{ marginLeft: 10 }} type='primary' size='small' onClick={() => footerController({}, [moment(), moment().add(6, 'month')])}>Next 6Month</Button>
                        <Button style={{ marginLeft: 10 }} type='primary' size='small' onClick={() => footerController({}, [moment(), moment().add(9, 'month')])}>Next 9Month</Button>
                        <Button style={{ marginLeft: 10 }} type='primary' size='small' onClick={() => footerController({}, [moment(), moment().add(12, 'month')])}>Next Year</Button>
                    </>
                )
            }}
            showTime
        />
    )

}
export default DateRange