import React, { Component } from 'react'
import { Card, Table, Button, Popover } from 'antd'
import { connect } from 'react-redux';
import * as Actions from '../redux/actions'
import { ExportCSV } from '../excel/exportCSV'
import { Alert } from './component'
import { TierModel } from './model'
import { EmptyBox } from '../../components'
import FilterBox from '../Filter/FilterBox'
import './css.less'
import 'antd/dist/antd.css';
const { createColumns, createCsvData, createRows } = TierModel
const { getDistinctTier, getTierByFilter } = TierModel
class TierMemberList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tier: [],
            hasInit: false,
            filters: [],
        }
        this.init = this.init.bind(this)
        this.onClear = this.onClear.bind(this)
        this.onFilterChange = this.onFilterChange.bind(this)
        this.onViewHandler = this.onViewHandler.bind(this)
        this.show = this.show.bind(this)
    }
    init(props) {
        const { fetchedTopMember, fetchTopMember, merchantId } = props
        const { hasInit } = this.props
        if (!fetchedTopMember) fetchTopMember(merchantId)
        if (fetchedTopMember && !hasInit) {
            const { topMemberData } = props
            this.setState({
                tier: topMemberData,
                filters: getDistinctTier({ tiers: topMemberData })
            })
        }
    }
    onViewHandler(memberId) {
        if (memberId !== undefined) {
            const { onView } = this.props
            onView(memberId)
        }
    }
    componentDidMount() {
        this.init(this.props)
    }
    componentWillReceiveProps(nextProps) {
        this.init(nextProps)
    }
    onClear() {
        const { topMemberData } = this.props
        this.setState({
            tier: topMemberData
        })
    }
    onFilterChange(filters = []) {
        const { topMemberData } = this.props
        if (filters.length > 0) {
            this.setState({
                tier: getTierByFilter({ tiers: topMemberData, filters: filters })
            })
        }
    }
    show() {
        const { tier, filters } = this.state
        if (tier.length > 0)
            return (
                <div>
                    <div className='tier__member__bar'>
                        <h2 className="tier__member__title"> Tier Members </h2>
                        <Popover content={<FilterBox handleSumbit={this.onFilterChange} options={filters} />}>
                            <Button icon='filter' className='tier__filter'>
                                Filter
                    </Button>
                        </Popover>
                        <Button icon='delete' className="tier__filter__clear" onClick={() => this.onClear()}> Clear Filter </Button>
                        <ExportCSV csvData={createCsvData(createRows(tier))} fileName='TierMembers' />
                    </div>
                    <Table
                        size='middle'
                        columns={createColumns({ onView: (memberId) => this.onViewHandler(memberId) })}
                        dataSource={createRows(tier)}
                        scroll={{ x: 1500 }} sticky
                    />
                </div>
            )
        else return <EmptyBox description='Tier members not found.' />
    }
    render() {
        const { fetchedTopMember } = this.props

        return (
            <div className='tier__member'>
                <Alert message="Tier members in your membership program" />
                <Card
                    loading={!fetchedTopMember}
                >
                    {this.show()}
                </Card>
            </div>
        )
    }
}
const mapDispatchToProps = (dispatch, { history }) => {
    return {
        onView: (memberId) => history.push(`/member/detail/${memberId}`),
        fetchTopMember: (merchantId) => dispatch(Actions.GetTopMemberSummary(merchantId)),
    }
}
const mapStateToProps = state => {
    const { authState, chartState } = state
    const { fetchedTopMember, chart } = chartState
    const { member } = chart
    const { topMember } = member
    return {
        topMemberData: topMember,
        fetchedTopMember: fetchedTopMember,
        merchantId: authState.status === 'loggedIn' && state.authState.userInfo ? authState.userInfo.merchantId : ''
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(TierMemberList)