import React, { Component } from 'react'
import 'antd/dist/antd.css';
import { Card, Table } from 'antd'
import moment from 'moment'
import './css.less'
import { connect } from 'react-redux';
import * as Actions from '../redux/actions'
import { ExportCSV } from '../excel/exportCSV'
import { Alert, DateRange } from './component'
//import { EmptyBox } from '../../components'
import { NewModel } from './model'
const dateFormat = 'YYYY/MM/DD';
const { createColumns, createCsvData, createRows } = NewModel
class NewMemberList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            sdate: moment().subtract(1, 'month'),
            tdate: moment(),
            hasInit: false,
            pending: false,
            loading: false,
        }

        this.onOk = this.onOk.bind(this)
        this.onhandleChange = this.onhandleChange.bind(this)
        this.init = this.init.bind(this)
        this.onViewHandler = this.onViewHandler.bind(this)
        this.show = this.show.bind(this)
        this.onpresetController = this.onpresetController.bind(this)
    }
    init(props) {
        const { fetchedMember, getAllMember, merchantId, memberData } = props
        const { fetchedNewMember, getNewMembers, newMemberData } = props
        const { fetchedReward, rewardData, getAllReward } = props
        const { fetchedRootPoint, getAllPoint, pointData } = props
        const { fetchedRootPointCount, totalCount, getAllPointCount } = props
        if (!fetchedMember) getAllMember(merchantId)
        if (!fetchedReward) getAllReward(merchantId)
        if (!fetchedRootPointCount) getAllPointCount(merchantId)
        if (fetchedRootPointCount && !fetchedRootPoint && !this.state.pending) {
            this.setState({
                pending: true
            }, () => {
                getAllPoint(merchantId, totalCount)
            })
        }
        if (fetchedMember && fetchedReward && fetchedRootPoint) {
            const { sdate, tdate, hasInit } = this.state
            if (!fetchedNewMember && newMemberData.length <= 0 && !hasInit) {
                let newObj = { members: memberData, points: pointData, rewards: rewardData, from: moment(sdate).unix() * 1000, to: moment(tdate).unix() * 1000 }
                this.setState({
                    loading: false,
                }, () => getNewMembers({ ...newObj }))
            }
            if (fetchedNewMember) {
                this.setState({
                    loading: true
                })
            }
        }
    }
    componentDidMount() {
        this.init(this.props)
    }
    onViewHandler(memberId) {
        if (memberId !== undefined) {
            const { onView } = this.props
            onView(memberId)
        }
    }
    componentWillReceiveProps(nextProps) {
        this.init(nextProps)
    }
    onpresetController(m, st) {
        const { memberData, getNewMembers } = this.props
        const { pointData, rewardData } = this.props
        this.setState({
            sdate: st[0],
            tdate: st[1],
        }, () => {
            const newObj = { members: memberData, points: pointData, rewards: rewardData, from: moment(this.state.sdate).unix() * 1000, to: moment(this.state.tdate).unix() * 1000 }
            getNewMembers({ ...newObj })
        })
    }
    onhandleChange(m, st) {
        this.setState({
            sdate: st[0],
            tdate: st[1]
        })
    }
    onOk() {
        const { memberData, getNewMembers } = this.props
        const { pointData, rewardData } = this.props
        let { sdate, tdate } = this.state
        sdate = moment(sdate).unix()
        tdate = moment(tdate).unix()
        let newObj = { members: memberData, points: pointData, rewards: rewardData, from: sdate * 1000, to: tdate * 1000 }
        getNewMembers({ ...newObj })
    }
    show() {
        const { newMemberData } = this.props
        return (
            <div>
                <div className='new__member__bar'>
                    <h2 className="new__member__title"> New Members </h2>
                    <DateRange
                        className='new_member__date'
                        format={dateFormat}
                        defaultValue={[this.state.sdate, this.state.tdate]}
                        onChange={(s, t) => this.onhandleChange(s, t)}
                        onOk={() => this.onOk()}
                        footerController={(m, st) => this.onpresetController(m, st)}
                        showTime
                    />
                    <ExportCSV csvData={createCsvData(createRows(newMemberData))} fileName='newMembers' />
                </div>
                <Table
                    size='middle'
                    columns={createColumns({ onView: (memberId) => this.onViewHandler(memberId) })}
                    dataSource={createRows(newMemberData)}
                    scroll={{ x: 1500 }} sticky
                />
            </div>
        )
    }
    render() {

        return (
            <div className='new__member'>
                <Alert message="Member who get the first point in given period" />
                <Card
                    loading={!this.state.loading}
                >
                    {this.show()}
                </Card>
            </div>
        )
    }
}
const mapDispatchToProps = (dispatch, { history }) => {
    return {
        getAllMember: (merchantId) => dispatch(Actions.GetAllMember(merchantId)),
        onView: (memberId) => history.push(`/member/detail/${memberId}`),
        getAllPoint: (merchantId, totalCount) => dispatch(Actions.GetAllPoint(merchantId, totalCount)),
        getAllReward: (merchantId) => dispatch(Actions.fetchRewardHistory(merchantId)),
        getAllPointCount: (merchantId) => dispatch(Actions.GetAllTotalPointCount(merchantId)),
        getNewMembers: ({ members, points, rewards, from, to }) => dispatch(Actions.GetNewMembers({ members, points, rewards, from, to }))
    }
}
const mapStateToProps = state => {
    const { authState, reportState } = state
    const { member, fetchedMember, fetchedNewMember, fetchedRootPoint, root } = reportState
    const { fetchedReward, history } = reportState
    const { totalCount, fetchedRootPointCount, point } = root
    const { reward } = history
    const [rewardData] = [reward.origin]
    const { origin, news } = member
    return {
        pointData: point,
        memberData: origin,
        rewardData: rewardData,
        newMemberData: news,
        totalCount: totalCount,
        fetchedRootPointCount: fetchedRootPointCount,
        fetchedMember: fetchedMember,
        fetchedNewMember: fetchedNewMember,
        fetchedReward: fetchedReward,
        fetchedRootPoint: fetchedRootPoint,
        merchantId: authState.status === 'loggedIn' && state.authState.userInfo ? authState.userInfo.merchantId : ''
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(NewMemberList)