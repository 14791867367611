import { createComponent, customMap } from 'redux-form-antd';
import { Cascader } from 'antd';

// tslint:disable-next-line:no-any
function mapFunction(mapProps: any, { input: { onChange } }: any) {
  return {
    ...mapProps,
    // tslint:disable-next-line:no-any
    onChange: (v: any) => onChange(v),
  };
}

const cascaderFieldMap = customMap(mapFunction);

export default createComponent(Cascader, cascaderFieldMap);
