import React from 'react';
import { createComponent, customMap } from 'redux-form-antd';
import { Upload, Button } from 'antd';
import { UploadChangeParam, UploadProps } from 'antd/lib/upload';
import { UploadFile } from 'antd/lib/upload/interface';
import { from, empty } from 'rxjs';
import { flatMap, map } from 'rxjs/operators';
import { readFileInfo$ } from '../readFileInfo';
import { fetchJSON$ } from '../api';

// const uploadURL = baseURL + '/upload';

const getBody = (xhr: XMLHttpRequest) => {
  const text = xhr.responseText || xhr.response;
  if (!text) {
    return text;
  }

  try {
    return JSON.parse(text);
  } catch (e) {
    return text;
  }
};

type FileEx = File & {
  uid: number;
  lastModified: number;
  size: number;
  type: string;
};

interface RequestOption {
  onProgress: (event: { percent: number }) => void;
  onError: (event: Error, body?: object) => void;
  onSuccess: (body: object) => void;
  data: object;
  filename: string;
  file: FileEx;
  withCredentials: boolean;
  action: string;
  headers: object;
}

const upload = (req: {
  url: string;
  file: FileEx;
  md5: string;
  onProgress: (event: { percent: number }) => void;
}) => {
  const { url, file, md5, onProgress } = req;

  return new Promise((res, rej) => {
    const xhr = new XMLHttpRequest();
    xhr.open('PUT', url);
    xhr.setRequestHeader('Content-Type', file.type);
    xhr.setRequestHeader('Content-MD5', md5);
    xhr.setRequestHeader(
      'Content-Disposition',
      `attachment; filename*= UTF-8''${encodeURIComponent(file.name)}`
    );
    xhr.onload = function onload() {
      if (xhr.status < 200 || xhr.status >= 300) {
        rej(new Error('Oops! Cannot upload!'));
      }

      res(getBody(xhr));
    };
    xhr.onerror = rej;
    if (xhr.upload && onProgress) {
      xhr.upload.onprogress = (e) => {
        let percent = 0;
        if (e.total > 0) {
          percent = (e.loaded / e.total) * 100;
        }
        onProgress({ percent });
      };
    }
    xhr.send(file);
  });
};

const uploadHandler = (opt: RequestOption) => {
  const { file, onProgress, onSuccess } = opt;
  readFileInfo$(file)
    .pipe(
      flatMap((fi) => {
        const key = `${new Date().getTime()}_${fi.fileName}`;
        return fetchJSON$(
          '/signedURL',
          { fileName: key, md5: fi.fileMD5, type: file.type },
          'POST'
        )
          .pipe(map((r) => r.location))
          .pipe(
            flatMap((loc: string) => {
              if (loc) {
                return from(
                  upload({
                    url: loc,
                    file,
                    md5: fi.fileMD5,
                    onProgress,
                  })
                ).pipe(
                  map((_) => {
                    onSuccess({
                      key,
                      name: file.name,
                      type: file.type,
                      size: fi.fileSize,
                    });
                    return _;
                  })
                );
              }

              return empty();
            })
          );
      })
    )
    .subscribe();
};

interface LimitedFileUploadProps extends UploadProps {
  maxFiles?: number;
  showUpload?: boolean;
}
interface S {
  fileList: UploadFile[];
}
class LimitedFileUpload extends React.Component<LimitedFileUploadProps, S> {
  constructor(props: LimitedFileUploadProps) {
    super(props);

    this.state = { fileList: this.props.fileList || [] };
  }

  componentWillReceiveProps(nextProps: LimitedFileUploadProps) {
    this.setState({
      fileList: nextProps.fileList || [],
    });
  }

  handleChange = (info: UploadChangeParam) => {
    const { fileList } = info;
    this.setState({ fileList }, () => {
      const { onChange } = this.props;
      if (onChange) {
        onChange(info);
      }
    });
  };

  render() {
    const { maxFiles, showUpload, fileList: _, ...props } = this.props;
    const { fileList } = this.state;

    return (
      <Upload
        {...props}
        // action={uploadURL}
        customRequest={uploadHandler}
        fileList={fileList}
        onChange={this.handleChange}
      >
        {showUpload && <Button>Upload</Button>}
      </Upload>
    );
  }
}

// tslint:disable-next-line:no-any
function mapFunction(mapProps: any, { input: { value, onChange } }: any) {
  return {
    ...mapProps,
    fileList: value || [],
    // tslint:disable-next-line:no-any
    onChange: ({ fileList }: UploadChangeParam) => {
      const files = fileList.filter((f) => f.status === 'done');
      if (files.length > 0) {
        onChange(files);
      } else {
        onChange(null);
      }
    },
  };
}
const uploadFieldMap = customMap(mapFunction);

export default createComponent(LimitedFileUpload, uploadFieldMap);
