import * as React from 'react'
import { Order, Item } from "../state";
import * as Colors from "../../color";
import { Avatar } from "antd";
import * as _ from 'lodash';
interface Filter {
    from: number;
    to: number;
}
interface ConfigVariation {
    name: string,
    count: number
}
interface ConfigItemVariation {
    name: string,
    photo: string,
    id: string,
    count: number,
    variations: ConfigVariation[]
}
export const getBarConfigFormatData = (orders: Order[], items: Item[], f: Filter) => {
    let iNames: Array<{ name: string, count: number, VarIds: Array<{ name: string, count: number }> }> = [], totalOrders = 0, totalSales = 0
    if (f.from > 0 && f.to > 0) {
        orders = orders.filter(o => o.createdOn > f.from && o.createdOn < f.to)
    }
    orders.forEach(o => {
        if (o.orderStatus === 'Completed') {
            totalOrders = totalOrders + 1
            totalSales = totalSales + o.totalNetAmount
            if (o.itemVariationIds) {
                o.itemVariationIds.split('^').forEach(vi => {
                    const iname = vi.split('->')
                    if (iname.length > 0) {
                        const index = iNames.findIndex(i => i.name === iname[0])
                        if (index > -1) {
                            const viIndex = iNames[index].VarIds.findIndex(i => i.name === vi)
                            let vIds = iNames[index].VarIds
                            if (viIndex > -1) {
                                vIds[viIndex] = { name: vIds[viIndex].name, count: vIds[viIndex].count + 1 }
                            } else {
                                vIds.push({ name: vi, count: 1 })
                            }
                            iNames[index] = {
                                name: iNames[index].name,
                                count: iNames[index].count + 1,
                                VarIds: vIds
                            }
                        } else {
                            iNames.push({ name: iname[0], count: 1, VarIds: [{ name: vi, count: 1 }] })
                        }
                    }
                })
            }
        }
    })
    const names = Array.from(new Set(iNames.map(i => i.name)))
    const itemsAndVartions: any[] = []
    //  console.log('before filter', iNames)
    const distinctItemNames = names.map(n => {
        const ns = items.filter(i => i.name === n)
        if (ns.length > 0) {
            itemsAndVartions.push(
                {
                    name: n,
                    photo: ns[0].photo,
                    id: ns[0].id,
                    count: iNames.filter(i => i.name === n)[0].count,
                    variations: iNames.filter(i => i.name === n)[0].VarIds
                }
            )
            return { name: n, id: ns[0].id, found: true, count: iNames.filter(i => i.name === n)[0].count }
        } else {
            return {
                found: false
            }
        }
    }).filter(v => v.found === true)
    return {
        items: distinctItemNames,
        itemsAndVartions,
        totalOrders,
        totalSales,
    }
}
export const BarChartConfig = (result: any[] = []) => {
    result = _.orderBy(result, 'count', 'desc')
    let max = 0
    if (result.length > 0) {
        max = max + (result[0].count || 0)
    }
    var data = Array(result.length).fill(0).map(p => Array(2).fill(0).map(p => p))
    result.forEach((p, index) => {
        data[index][0] = p.name || ''
        data[index][1] = p.count || 0
    })
    max += 50
    return {
        chart: {
            type: 'bar',
            marginLeft: 150
        },
        title: {
            text: 'Product Count'
        },
        xAxis: {
            type: 'category',
            title: {
                text: null
            },
            color: 'green',
            min: 0,
            max: 5,
            scrollbar: {
                enabled: true
            },
            tickLength: 0
        },
        yAxis: {
            min: 0,
            max: max,
            title: {
                text: 'count',
                align: 'high'
            },
        },
        plotOptions: {
            bar: {
                dataLabels: {
                    enabled: true
                }
            }
        },
        colors: [Colors.WatermelonColor, Colors.BannaColor],
        legend: {
            enabled: false
        },
        credits: {
            enabled: false
        },
        series: [{
            name: 'Products',
            data: data
        }]
    }
}
export const productDashobardCreateRows = (data: ConfigItemVariation[] = []) => {
    let i = 0
    return data.length > 0 ?
        _.orderBy(data.map(d => {
            i++
            return {
                key: i,
                name: d.name,
                photo: d.photo,
                count: d.count || 0,
                variation: d.variations || [],
            }
        }), 'count', 'desc')
        : []
    return data
}
export const productDashboardcreateColumns = () => {
    const columns = [
        {
            title: 'Photo',
            key: 'photo',
            fixed: 'left',
            render: ({ photo = {} }) => photo ? <Avatar src={photo} size={200} shape='square' /> : <Avatar icon='gift' size={70} style={{ color: 'black', backgroundColor: 'white' }} />,
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            fixed: 'left',
            render: (text: any) => <b style={{ color: 'black', fontSize: 20 }} >{text}</b>
        },
        {
            title: 'Count',
            dataIndex: 'count',
            key: 'count',
            render: (text: any) => <b style={{ color: 'black', fontSize: 20 }} >{text}</b>
        },
        {
            title: 'Variations',
            key: 'variation',
            dataIndex: 'variation',
            fixed: 'right',
            render: (v: ConfigVariation[]) => <table style={{
                display: 'table',
                borderCollapse: 'separate',
                borderSpacing: '2px',
                borderColor: 'gray',
                height: '20px',
                // overflow: 'scroll',
            }}>
                <thead style={{ display: 'block' }}>
                    <tr key='0'>
                        <th style={{ color: 'black', width: '250px' }}>Variation</th>
                        <th style={{ color: 'black', width: '150px' }}>Count</th>
                    </tr>
                </thead>
                <tbody style={{
                    height: '150px',
                    display: 'block',
                    width: '100%',
                    overflowY: 'scroll'
                }} >
                    {v.map((m, index) => (
                        <tr key={index}>
                            <td style={{ width: '250px', color: Colors.LabelColor, fontWeight: 'bold' }}>{m.name || ''}</td>
                            <td style={{ width: '150px', color: 'black', fontWeight: 'bold' }}>{m.count}</td>
                            {/* <td style={{ width: '150px', color: 'black', fontWeight: 'bold' }}></td> */}
                            {/* <td style={{ width: '150px', color: 'black', fontWeight: 'bold' }}>{m.redeem}</td> */}
                        </tr>
                    ))}
                </tbody>
            </table >
        },
    ]
    return columns

}
