import * as React from 'react'
import { CustomerGroup, Customer } from "../state";
import { Avatar } from "antd";
import * as _ from 'lodash'
import { Order, Item } from "../state";
export const GetCustomerDetail = (customers: Customer[] = [], id: string, groups: CustomerGroup[] = []) => {
    const c = customers.filter(c => c.id === id)[0];
    let gs: CustomerGroup[] = []
    const gpIds = c.groupIds.split(',') || []
    for (const gpId of gpIds) {
        if (gpId && groups.filter(g => g.id === gpId).length > 0) gs.push(groups.filter(g => g.id === gpId)[0])
    }
    return {
        customer: c,
        groups: gs
    }
}
export const GetCustomerProduct = (itemIds: string[] = [], items: Item[]) => {
    const _items: Item[] = []
    for (const i of itemIds) {
        const f = items.filter(i2 => i2.id === i)
        if (f.length > 0) {
            _items.push(f[0])
        }
    }
    return _items
}
export const GetCustomerOrder = (cId: string, o: Order[] = []) => {
    let orders: Order[] = [], itemIds: Array<{ id: string, count: number }> = []
    for (const order of o) {
        if (order.customerId && order.customerId === cId && order.orderStatus === 'Completed') {
            orders.push(order)
            if (order.itemIds) {
                order.itemIds.split('^').map(id => {
                    const index = itemIds.findIndex(item => item.id === id)
                    if (index !== -1) {
                        itemIds[index] = { id: id, count: itemIds[index].count + 1 }
                    } else {
                        itemIds.push({ id: id, count: 1 })
                    }

                })
            }
        }
    }

    return { orders, itemIds }
}
export const customerOrderRows = (rows: Order[] = []) => {
    let i = 0
    rows = _.orderBy(rows, 'createdOn', 'desc')
    return rows.length > 0 ?
        rows.map(row => {
            //console.log('order value', typeof row.itemIds, '\n variation ids', row.itemVariationIds)
            i++;
            return {
                key: i,
                orderId: row.orderId,
                customer: row.customerName,
                totalAmount: row.totalAmount,
                date: new Date(row.createdOn).toDateString(),
                point: row.earnPoints,
                status: row.orderStatus,
                taxAmount: row.totalAddedTaxAmount,
                type: row.orderType,
                payment: row.paymentType
            }
        }) :
        []
}

export const customerOrderColumns = () => {
    const columns = [
        {
            title: 'OrderId',
            dataIndex: 'orderId',
            key: 'orderId',
            align: 'left',
            render: (text: any) => <b style={{ color: 'blue' }}>{text}</b>
        },
        {
            title: 'Customer',
            dataIndex: 'customer',
            key: 'customer',
            render: (text: any) => text
        },
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            render: (text: any) => text
        },
        {
            title: 'Total Amount',
            dataIndex: 'totalAmount',
            key: 'totalAmount',
            render: (text: any) => text
        },
        {
            title: 'Points',
            dataIndex: 'point',
            key: 'point',
            render: (text: any) => text
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (text: any) => <b style={{ color: text === 'Completed' ? 'green' : text === 'Pending' ? 'peru' : 'red', fontWeight: 'bold' }}>{text}</b>
        },
        {
            title: 'Order Type',
            dataIndex: 'type',
            key: 'type',
            render: (text: any) => text
        },
        {
            title: 'Payment',
            dataIndex: 'payment',
            key: 'payment',
            render: (text: any) => text
        },
    ];
    return columns
}

export const customerProductRows = (rows: Item[] = []) => {
    let i = 0
    return rows.length > 0 ?
        rows.map(row => {
            i++;
            return {
                key: i,
                id: row.id,
                name: row.name,
                category: row.categoryName,
                photo: row.photo,
                date: new Date(row.createdOn).toDateString(),
                varitations: row.itemVariationIds,
            }
        }) :
        []
}


export const customerProductColumns = () => {
    const columns = [
        {
            title: 'Photo',
            width: 100,
            key: 'photo',
            fixed: 'left',
            render: (v: { photo: string }) => < Avatar src={v.photo} size={70} />

        },
        // {
        //     title: 'Id',
        //     dataIndex: 'id',
        //     key: 'id',
        //     align: 'left',
        //     render: (text: any) => <b style={{ color: 'blue' }}>{text}</b>
        // },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (text: any) => text
        },
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            render: (text: any) => text
        },
        {
            title: 'Category',
            dataIndex: 'category',
            key: 'category',
            render: (text: any) => text
        },
        {
            title: 'Variatations',
            dataIndex: 'varitations',
            key: 'varitations',
            width: 250,
            render: (text: any) => text
        },
       

    ];
    return columns
}
