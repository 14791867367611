import React, { Component } from 'react'
import 'antd/dist/antd.css';
import { Card, Table, } from 'antd'
import '../css.less'
import { connect } from 'react-redux';
import moment from 'moment';
import * as Actions from '../redux/actions'
import { ExportCSV } from '../excel/exportCSV'
import { Alert, DateRange } from './component'
//import { EmptyBox } from "../../components";
import { TopModel } from './model'
const dateFormat = 'YYYY/MM/DD';
const { createColumns, createCsvData, createRows, GetTopMembers } = TopModel
class TopMemberList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            hasInit: false,
            pending: false,
            loading: false,
            topMemberData: [],
            fileName: 'Topmeber_one_month',
            sdate: moment().startOf('month'),
            tdate: moment().endOf('month'),
        }
        this.init = this.init.bind(this)
        this.onOk = this.onOk.bind(this)
        this.onhandleChange = this.onhandleChange.bind(this)
        this.getFileName = this.getFileName.bind(this)
        this.onViewHandler = this.onViewHandler.bind(this)
        this.show = this.show.bind(this)
        this.onpresetController = this.onpresetController.bind(this)
    }
    init(props) {
        const { fetchedMember, getAllMember, merchantId, memberData } = props
        const { fetchedRootPoint, getAllPoint, pointData } = props
        const { fetchedRootPointCount, totalCount, getAllPointCount } = props
        if (!fetchedMember) getAllMember(merchantId)
        if (!fetchedRootPointCount) getAllPointCount(merchantId)
        if (fetchedRootPointCount && !fetchedRootPoint && !this.state.pending) {
            this.setState({
                pending: true
            }, () => {
                getAllPoint(merchantId, totalCount)
            })
        }
        if (fetchedMember && fetchedRootPoint) {
            const { sdate, tdate, hasInit } = this.state
            if (!hasInit) {
                let newObj = { members: memberData, points: pointData, from: moment(sdate).unix() * 1000, to: moment(tdate).unix() * 1000 }
                this.setState({
                    hasInit: true,
                    loading: true,
                    topMemberData: GetTopMembers({ ...newObj })
                })
            }
            if (hasInit) {
                this.setState({
                    loading: true,
                })
            }
        }
    }
    onpresetController(m, st) {
        const { memberData, pointData } = this.props
        let newObj = { members: memberData, points: pointData, from: moment(st[0]).unix() * 1000, to: moment(st[1]).unix() * 1000 }
        this.setState({
            sdate: st[0],
            tdate: st[1],
            fileName: this.getFileName(),
            topMemberData: GetTopMembers({ ...newObj }),
        })
    }
    onhandleChange(m, st) {
        this.setState({
            sdate: moment(st[0]),
            tdate: moment(st[1])
        })
    }
    onOk() {
        const { memberData, pointData } = this.props
        let { sdate, tdate } = this.state
        let newObj = { members: memberData, points: pointData, from: moment(sdate).unix() * 1000, to: moment(tdate).unix() * 1000 }
        this.setState({
            topMemberData: GetTopMembers({ ...newObj }),
            fileName: this.getFileName()
        })
    }
    getFileName() {
        let from = moment(this.state.sdate).format(dateFormat).toString()
        let to = moment(this.state.tdate).format(dateFormat).toString()
        return "TopMembers_" + from + "_" + to
    }
    onViewHandler(memberId) {
        if (memberId !== undefined) {
            const { onView } = this.props
            onView(memberId)
        }
    }
    componentWillReceiveProps(nextProps) {
        this.init(nextProps)
    }
    componentDidMount() {
        this.init(this.props)
    }
    show() {
        return (
            <div>
                <div className='top__member__bar'>
                    <h2 className="top__member__title"> Top Members </h2>
                    <DateRange
                        className='top_member__date'
                        format={dateFormat}
                        defaultValue={[this.state.sdate, this.state.tdate]}
                        onChange={(s, t) => this.onhandleChange(s, t)}
                        onOk={() => this.onOk()}
                        footerController={(m, st) => this.onpresetController(m, st)}
                        showTime
                    />
                    <ExportCSV csvData={createCsvData(createRows(this.state.topMemberData))} fileName={this.state.fileName} />
                </div>
                <Table
                    size='middle'
                    columns={createColumns({ onView: (memberId) => this.onViewHandler(memberId) })}
                    dataSource={createRows(this.state.topMemberData)}
                    scroll={{ x: 1500 }} sticky
                />
            </div>
        )
    }
    render() {
        return (
            <div className='top__member'>
                <Alert message="Top member in your membership program" />
                <Card
                    loading={!this.state.loading}
                >
                    {this.show()}
                </Card>
            </div>
        )
    }
}
const mapDispatchToProps = (dispatch, { history }) => {
    return {
        getAllMember: (merchantId) => dispatch(Actions.GetAllMember(merchantId)),
        onView: (memberId) => history.push(`/member/detail/${memberId}`),
        getAllPoint: (merchantId, totalCount) => dispatch(Actions.GetAllPoint(merchantId, totalCount)),
        getAllPointCount: (merchantId) => dispatch(Actions.GetAllTotalPointCount(merchantId)),
    }
}
const mapStateToProps = state => {
    const { authState, reportState } = state
    const { member, fetchedMember, fetchedRootPoint, root } = reportState
    const { totalCount, fetchedRootPointCount, point } = root
    const { origin } = member
    return {
        pointData: point,
        memberData: origin,
        totalCount: totalCount,
        fetchedMember: fetchedMember,
        fetchedRootPoint: fetchedRootPoint,
        fetchedRootPointCount: fetchedRootPointCount,
        merchantId: authState.status === 'loggedIn' && state.authState.userInfo ? authState.userInfo.merchantId : ''
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(TopMemberList)