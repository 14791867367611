import React, { Component } from 'react'
import moment from 'moment'
import 'antd/dist/antd.css';
import { Button, Card, Table, Popover } from 'antd'
import * as _ from 'lodash'
import '../css.less'
import { connect } from 'react-redux';
import * as Actions from '../redux/actions'
import FilterBox from '../Filter/FilterBox'
import { DateRange } from '../Member/component'
import { ExportCSV } from '../excel/exportCSV'
import { RewardModel } from './model'
//import { EmptyBox } from "../../components";
const { createRows, crateColummns, createCsvData } = RewardModel
const dateFormat = 'YYYY/MM/DD';
class RewardHistory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sdate: moment(),
            tdate: moment(),
        }
        this.onhandleChange = this.onhandleChange.bind(this)
        this.onFilterChange = this.onFilterChange.bind(this)
        this.onOk = this.onOk.bind(this)
        this.show = this.show.bind(this)
        this.onpresetController = this.onpresetController.bind(this)
    }
    onhandleChange(m, st) {
        this.setState({
            sdate: st[0],
            tdate: st[1]
        })
    }
    onOk() {
        this.props.rewardFilterRange(moment(this.state.sdate).unix() * 1000, moment(this.state.tdate).unix() * 1000)
    }
    onpresetController(m, st) {
        const { rewardFilterRange } = this.props
        this.setState({
            sdate: st[0],
            tdate: st[1],
        }, () => {
            rewardFilterRange(moment(this.state.sdate).unix() * 1000, moment(this.state.tdate).unix() * 1000)
        })
    }
    onFilterChange(filters) {
        if (filters.length > 0) {
            var index = filters.indexOf('redeem');
            if (index !== -1) {
                filters[index] = null;
            }
            this.props.rewardFilterType(filters)
        }
    }
    componentDidMount() {
        if (!this.props.fetchedReward) {
            this.props.fetchReward(this.props.merchantId)
        }
    }
    show() {
        return (
            <div>
                <div className='history__bar'>
                    <h2 className="history__title"> Reward </h2>
                    <DateRange
                        className='history__date'
                        format={dateFormat}
                        defaultValue={[this.state.sdate, this.state.tdate]}
                        onChange={(s, t) => this.onhandleChange(s, t)}
                        onOk={() => this.onOk()}
                        footerController={(m, st) => this.onpresetController(m, st)}
                        showTime
                    />
                    <Popover content={<FilterBox handleSumbit={this.onFilterChange} options={['claimed', 'redeem']} />}>
                        <Button icon='filter' className='history__filter'>
                            Filter
                    </Button>
                    </Popover>
                    <Button icon='delete' className="history__filter__clear" onClick={() => { this.props.rewardFilterClear() }}> Clear Filter </Button>
                    <ExportCSV csvData={createCsvData(createRows(this.props.data))} fileName='reward' />
                </div>
                <Table
                    size='middle'
                    columns={crateColummns()}
                    dataSource={createRows(this.props.data)}
                    scroll={{ x: 1500 }} sticky
                />
            </div>
        )
    }
    render() {
        return (
            <Card
                loading={!this.props.fetchedReward}
            >
                {this.show()}
            </Card>
        )
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        fetchReward: (merchantId) => dispatch(Actions.fetchRewardHistory(merchantId)),
        rewardFilterRange: (from, to) => dispatch(Actions.addRewardFilterRange(from, to)),
        rewardFilterClear: () => dispatch(Actions.clearRewardFilter()),
        rewardFilterType: (filterType) => dispatch(Actions.addRewardFilterType(filterType ? filterType : []))
    }
}
const mapStateToProps = state => {
    const { reportState, authState } = state
    const { reward } = reportState.history
    const { temp } = reward
    return {
        data: _.orderBy(temp, 'createdAt', 'desc'),
        fetchedReward: reportState.fetchedReward | false,
        merchantId: authState.status === 'loggedIn' && state.authState.userInfo ? authState.userInfo.merchantId : ''
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(RewardHistory)