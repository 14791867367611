import config from "../../config";
import * as ActionTypes from './ActionTypes'
import { Customer, CustomerGroup } from "../state";
const pos_url = process.env.POS_URL || config().config.POS.url;

export const GetAllCustomer = (merchantId: string) => (dispatch: any) => {
    let _url = `${pos_url}/getAllCustomer/${merchantId}`
    fetch(_url, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
    })
        .then(response => response.json())
        .then(data => {
            let customers: Customer[] = [];
            if (data.status !== 'Error' || data.data.length < 0) {
                customers = data.data as Customer[] || []
            }
            dispatch({
                type: ActionTypes.ADD_CUSTOMER,
                payload: { customers }
            });
        });
}


export const GetCustomerGroup = (merchantId: string) => (dispatch: any) => {
    let _url = `${pos_url}/getCustomerGroup/${merchantId}`
    fetch(_url, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
    })
        .then(response => response.json())
        .then(data => {
            let groups: CustomerGroup[] = [];
            if (data.status !== 'Error' || data.data.length < 0) {
                groups = data.data as CustomerGroup[] || []
            }
            dispatch({
                type: ActionTypes.ADD_CUSTOMER_GP,
                payload: { groups }
            });
        });
}