import * as React from 'react'
import { connect } from "react-redux";
import firebase from 'firebase/app';
import { RouteComponentProps } from "react-router";
import { Card, Table, Col, Row } from "antd";
import { GetAllVariation, McommerceState, GetAllProduct } from "./redux";
import { DetailModel } from "./model";
import { Variation, Item } from "./state";
import { from, Subscription } from 'rxjs';
import { Order, SubItemCustomer, ItemSet } from './state'
import LineChart from './Charts/LineChart';
const { Meta } = Card
const { GetProductSummary, lineChartConfig, GetProductVariants } = DetailModel
const { createBoughtTogetherColumn, createBoughtTogetherRows } = DetailModel
const { createBoughtCustomerColumns, createBoughtCustomerRows } = DetailModel
//GetProductDetail//
interface Props extends RouteComponentProps {
    productId: string;
    merchantId: string;
    getAllVariation: (id: string) => any;
    getAllProduct: (id: string) => any;
    variations: Variation[];
    products: Item[],
    fetchedVariation: boolean;
    fetchedProduct: boolean;
}

type S =
    | { status: 'loading' }
    | {
        status: 'loaded';
        data: { orders: Order[], customers: SubItemCustomer[], itemsets: ItemSet[] };
    };
class ProductDetail extends React.Component<Props, S> {
    sub: Subscription;

    constructor(props: Props) {
        super(props);
        this.sub = new Subscription();
        this.state = { status: 'loading' };
    }

    componentDidMount() {
        const { merchantId } = this.props
        const { productId: itemId } = this.props.match.params as { productId: string }
        const getItemDetail = firebase.functions().httpsCallable('getItemDetail');
        this.sub = from(getItemDetail({ merchantId, itemId })).subscribe(
            (result) => {
                const { data } = result.data;
                this.setState({
                    status: 'loaded',
                    data,
                });
            }
        );
    }

    componentWillUnmount() {
        this.sub.unsubscribe();
    }

    // componentDidMount() {
    //     const { fetchedVariation, getAllVariation, merchantId } = this.props
    //     const { getAllProduct, fetchedProduct } = this.props
    //     if (!fetchedVariation) getAllVariation(merchantId)
    //     if (!fetchedProduct) getAllProduct(merchantId)
    // }

    render() {
        const { productId: itemId } = this.props.match.params as { productId: string }
        const loading = this.state.status !== 'loaded'
        const data = this.state.status === 'loaded' ? this.state.data : { orders: [], customers: [], itemsets: [] }
        const product = GetProductSummary(data.orders, data.itemsets)
        const varitaions = GetProductVariants(data.itemsets)
        console.log('varitaion', varitaions)
        return (
            <Card title={`Product Detail`}
                loading={loading}
            >
                <Row gutter={24}>
                    <Col span={8} >
                        <Card
                            style={{ boxShow: 'none', border: 'none' }}
                            cover={
                                <img
                                    alt="example"
                                    src={product.photo ? product.photo : 'https://www.generationsforpeace.org/wp-content/uploads/2018/03/empty.jpg'}
                                />
                            }
                        >
                            <Meta title={product.name} description={<a href={`https://${this.props.merchantId}.piticommerce.com/detail/${itemId}`}>{`https://${this.props.merchantId}.piticommerce.com`}</a>} />
                            <Row gutter={24} style={{ marginTop: 5 }}>
                                <Col span={6}>
                                    NetSales
                                </Col>
                                <Col span={18}>
                                    {product.totalNetSales}
                                </Col>
                            </Row>
                            <Row gutter={12}>
                                <Col span={6}>
                                    Categories
                                </Col>
                                <Col span={18}>
                                    Sun
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                    <Col span={16} >
                        <LineChart config={lineChartConfig(data.orders)} />
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={12}>
                        <Card
                            title="Bought Together "
                            style={{ boxShow: 'none', border: 'none' }}
                        >
                            <Table
                                columns={createBoughtTogetherColumn()}
                                dataSource={createBoughtTogetherRows(data.itemsets)}
                                size="middle"
                                bordered={false}
                                pagination={{
                                    pageSize: 5,
                                }}
                            />
                        </Card>
                    </Col>
                    <Col span={12}>
                        <Card
                            title="Bought Coustomer "
                            style={{ boxShow: 'none', border: 'none' }}
                        >
                            <Table
                                bordered={false}
                                style={{ boxShow: 'none', border: 'none' }}
                                columns={createBoughtCustomerColumns()}
                                dataSource={createBoughtCustomerRows(data.customers)}
                                size="middle"
                                pagination={{
                                    pageSize: 5,
                                }}
                            />
                        </Card>
                    </Col>
                </Row>
            </Card >
        )
    }
}

const mapDispatchToProps = (dispatch: any,) => {
    return {
        getAllVariation: (merchantId: string) => dispatch(GetAllVariation(merchantId)),
        getAllProduct: (merchantId: string) => dispatch(GetAllProduct(merchantId))
    }
}
const mapStateToProps = (state: any) => {
    const { authState, mcommerceState: s } = state as { mcommerceState: McommerceState, authState: any }
    return {
        variations: s.variation.origin,
        products: s.product.origin,
        fetchedVariation: s.fetchedVariation,
        fetchedProduct: s.fetchedProduct,
        merchantId: authState.status === 'loggedIn' && state.authState.userInfo ? authState.userInfo.merchantId : ''
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ProductDetail)