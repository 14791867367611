import * as React from 'react';
import { Card, Table, Popover } from "antd";
import { connect } from 'react-redux';
import { GetAllOrder } from "./redux";
import moment from 'moment';
import { Order } from "./state";
import { ExportCSV } from "../report/excel/exportCSV";
import { OrderModel } from "./model";
import { DateRange } from "./components";
import { Button } from 'antd';
import FilterBox from '../report/Filter/FilterBox';
const { createColumns, createRows, createCsvData, getOrder } = OrderModel
interface Props {
    getAllOrder: (id: string) => any;
    fetchedOrder: boolean;
    merchantId: string;
    orders: Order[];
}
interface Filter {
    from: number;
    to: number;
    orderType: string[];
    orderStatus: string[];
}
const defaultState = {
    data: [],
    origin: [],
    filter: {
        from: moment().startOf('month').unix() * 1000,
        to: moment().endOf('month').unix() * 1000,
        orderStatus: [],
        orderType: []
    },
}
type S =
    | { data: Order[], origin: Order[], filter: Filter }
class OrderList extends React.Component<Props, S> {
    constructor(props: Props) {
        super(props);
        this.state = { ...defaultState }
        this.clearHandler = this.clearHandler.bind(this)
        this.onOk = this.onOk.bind(this)
        this.onChange = this.onChange.bind(this)
        this.footerController = this.footerController.bind(this)
        this.orderStatusFilter = this.orderStatusFilter.bind(this)
        this.orderTypeFilter = this.orderTypeFilter.bind(this)
    }
    componentDidMount() {
        const { merchantId, getAllOrder, fetchedOrder, orders } = this.props
        if (!fetchedOrder) getAllOrder(merchantId)
        if (fetchedOrder) {
            this.setState({ data: getOrder(orders, this.state.filter,), origin: orders })
        }
    }
    componentWillReceiveProps(nextProps: Props) {
        const { orders } = nextProps
        this.setState({ data: getOrder(orders, this.state.filter,), origin: orders })
    }
    clearHandler = () => {
        const { origin } = this.state
        this.setState({
            ...defaultState,
            origin,
            data: origin
        })
    }
    footerController = (m: any, s: any) => {
        const { filter, origin } = this.state
        const from = moment(s[0]).unix() * 1000
        const to = moment(s[1]).unix() * 1000
        this.setState({
            ...this.state,
            filter: {
                ...filter,
                from,
                to
            },
            data: getOrder(origin, { ...filter, from, to })
        })
    }
    onChange = (s: any, t: any) => {
        const from = moment(t[0]).unix() * 1000
        const to = moment(t[1]).unix() * 1000
        const { filter } = this.state
        this.setState({
            ...this.state,
            filter: {
                ...filter,
                from,
                to
            }
        })
    }
    onOk = () => {
        const { origin, filter } = this.state
        this.setState({
            ...this.state,
            data: getOrder(origin, filter)
        })
    }
    orderTypeFilter = (f: any[]) => {
        const { origin, filter } = this.state
        this.setState({
            ...this.state,
            filter: { ...filter, orderType: f },
            data: getOrder(origin, { ...filter, orderType: f })
        })
    }
    orderStatusFilter = (f: any) => {
        const { origin, filter } = this.state
        this.setState({
            ...this.state,
            filter: { ...filter, orderStatus: f },
            data: getOrder(origin, { ...filter, orderStatus: f })
        })
    }
    render() {
        return (
            <Card title="Order List" loading={!this.props.fetchedOrder}
                extra={
                    <div style={{ display: 'flex' }}>
                        <DateRange
                            defaultValue={[moment(this.state.filter.from), moment(this.state.filter.to)]}
                            onChange={(s: any, t: any) => this.onChange(s, t)}
                            onOk={() => this.onOk()}
                            className='order_date_range'
                            footerController={(m: any, t: any) => this.footerController(m, t)}
                        />
                        <div style={{ width: 10 }} />
                        <Popover content={
                            <FilterBox
                                handleSumbit={(f: any) => this.orderTypeFilter(f)}
                                options={['Web', 'POS']} />}>
                            <Button
                                icon='filter'
                            >
                                OrderType
                             </Button>
                        </Popover>
                        <div style={{ width: 10 }} />
                        <Popover content={
                            <FilterBox
                                handleSumbit={(f: any) => this.orderStatusFilter(f)}
                                options={Array.from(new Set(this.state.origin.map(o => o.orderStatus)))} />}>
                            <Button
                                icon='filter'
                            >
                                OrderStatus
                             </Button>
                        </Popover>
                        <div style={{ width: 10 }} />
                        <Button icon='delete' onClick={() => this.clearHandler()} > Clear Filter </Button>
                        <div style={{ width: 10 }} />
                        <ExportCSV csvData={createCsvData(createRows(this.state.data))} fileName={'OrderList'} />
                    </ div>}>
                <Table
                    columns={createColumns()}
                    dataSource={createRows(this.state.data)}
                />
            </Card>
        );
    }

}

const mapDispatchToProps = (dispatch: any) => {
    return {
        getAllOrder: (merchantId: string) => dispatch(GetAllOrder(merchantId)),
    }
}
const mapStateToProps = (state: any) => {
    const { authState, mcommerceState } = state
    const { fetchedOrder, order } = mcommerceState
    const { origin } = order
    return {
        orders: origin,
        fetchedOrder,
        merchantId: authState.status === 'loggedIn' && state.authState.userInfo ? authState.userInfo.merchantId : ''
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(OrderList)