import { Observable, Observer } from 'rxjs';
import * as SparkMD5 from 'spark-md5';

export interface FileInfo {
  fileSize: number;
  fileMD5: string;
  lastUpdated: string;
  fileName: string;
}

const readFileInfo$ = (file: File): Observable<FileInfo> => {
  return Observable.create((observer: Observer<FileInfo>) => {
    // tslint:disable-next-line:one-variable-per-declaration
    const blobSlice = File.prototype.slice,
      chunkSize = 2097152, // Read in chunks of 2MB
      chunks = Math.ceil(file.size / chunkSize),
      spark = new SparkMD5.ArrayBuffer(),
      reader = new FileReader();

    let currentChunk = 0;

    reader.onload = (e: Event) => {
      spark.append((e.target as FileReader).result as ArrayBuffer);
      currentChunk++;

      if (currentChunk < chunks) {
        loadNext();
      } else {
        const fileMD5 = new Buffer(spark.end(), 'hex').toString('base64');
        observer.next({
          fileMD5,
          fileSize: file.size,
          lastUpdated: new Date(file.lastModified).toISOString(),
          fileName: file.name,
        });
        observer.complete();
      }
    };

    reader.onerror = () => {
      observer.error('oops, something went wrong while reading file info.');
    };

    function loadNext() {
      // tslint:disable-next-line:one-variable-per-declaration
      const start = currentChunk * chunkSize,
        end = start + chunkSize >= file.size ? file.size : start + chunkSize;

      reader.readAsArrayBuffer(blobSlice.call(file, start, end));
    }

    loadNext();

    return () => {
      if (!reader.result) {
        // tslint:disable-next-line:no-console
        console.warn('read file aborted');
        reader.abort();
      }
    };
  });
};

export { readFileInfo$ };
