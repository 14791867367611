import React, { Component } from 'react'
import moment from 'moment'
import 'antd/dist/antd.css';
import { connect } from 'react-redux';
import { Button, Card, Table, DatePicker, Icon, Popover } from 'antd'
import '../css.less'
import * as Actions from '../redux/actions'
import { Avatar } from 'antd';
import FilterBox from '../Filter/FilterBox'
import { ExportCSV } from '../excel/exportCSV'

const { RangePicker } = DatePicker
const dateFormat = 'YYYY/MM/DD';

const crateColummns = () => {
    const columns = [
        {
            title: 'Event',
            width: 100,
            dataIndex: 'event',
            key: 'event',
            fixed: 'left',

            render: (text) => {
                let iconName = 'gift'
                switch (text) {
                    case 'award-point':
                        iconName = 'smile'
                        break
                    case 'award-stamp':
                        iconName = 'appstore'
                        break
                    case 'award-reward':
                        iconName = 'gift'
                        break
                    case 'claimed-reward':
                        iconName = 'pay-circle'
                        break
                    case 'member-tier':
                        iconName = 'up-circle'
                        break
                    case 'subscription':
                        iconName = 'like'
                        break
                    default:
                        iconName = 'unknown'
                        break

                }
                return < Avatar size={64} icon={iconName} style={{ color: '#FFD700', backgroundColor: 'white', }}><b style={{ color: 'blue' }}>{text}</b></ Avatar>
            },
        },
        {
            title: 'From',
            //    width: 100,
            dataIndex: 'from',
            key: 'from',
            render: text => <b style={{ color: 'green', fontSize: '16' }}> <span>{text} <Icon type='arrow-right' /></span></b>
        },
        {
            title: 'To',
            //  width: 100,
            dataIndex: 'to',
            key: 'to',
            render: text => <b style={{ color: 'blue', fontSize: '16' }}><span><Icon type='arrow-right' /> {text}</span></b>
        },
        {
            title: 'Date',
            //width: 100,
            dataIndex: 'date',
            key: 'date',
        },

        {
            title: 'Shop',
            //width: 150,
            dataIndex: 'shop',
            key: 'shop',
            // fixed: 'left',
        },
        {
            title: "Reference",
            //width: 100,
            dataIndex: 'ref',
            key: 'ref'
        },
        {
            title: "Changes",
            width: 120,
            dataIndex: 'change',
            key: 'change',
            fixed: 'right',
            render: text => <b style={{ color: '#fc0703', fontSize: '14' }}>{text}</b>
        }
    ]
    return columns
}
const createRows = (rows = []) => {
    let i = 0
    return rows.length > 0 ?
        rows.map(row => {
            i++;
            return {
                key: i,
                event: row.event,
                from: row.from,
                to: row.to,
                date: require('moment').unix(row.Date._seconds | 0).format('dddd, MMMM Do YYYY, h:mm:ss a'),
                shop: row.Shop,
                ref: row.Reference,
                change: row.Other,
            }
        }) :
        []
}
const createCsvData = (rows = []) => {
    return rows.length > 0 ?
        rows.map(row => {
            return { ...row, Date: require('moment').unix(row.Date._seconds | 0).format('dddd, MMMM Do YYYY, h:mm:ss a') }
        })
        : [];
}
class AuditHistory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sdate: moment(),
            tdate: moment(),
        }
        this.onhandleChange = this.onhandleChange.bind(this)
        this.onFilterChange = this.onFilterChange.bind(this)
    }
    onhandleChange(m, st) {
        this.props.fetchAuditByRange(moment(st[0]).unix(), moment(st[1]).unix())
        this.setState({
            sdate: st[0],
            tdate: st[1]
        })
    }
    onFilterChange(filters) {
        this.props.fetchAuditByType(filters.length > 0 ? filters : [])
    }
    componentDidMount() {
        //fetch auditlogs from db if not yet.
        if (!this.props.fetchedAudit) {
            this.props.fetchAuditLog(this.props.merchantId)
        }
    }
    show() {

    }
    render() {
        return (
            <Card
                loading={!this.props.fetchedAudit}
            >
                <div className='history__bar'>
                    <h2 className="history__title"> History</h2>
                    <RangePicker
                        className='history__date'
                        format={dateFormat}
                        defaultValue={[this.state.sdate, this.state.tdate]}
                        onChange={(s, t) => this.onhandleChange(s, t)}
                    />
                    <Popover content={<FilterBox handleSumbit={this.onFilterChange} />}>
                        <Button icon='filter' className='history__filter'>
                            Filter
                    </Button>
                    </Popover>
                    <Button icon='delete' className="history__filter__clear" onClick={() => this.props.clearAuditFiter()}> Clear Filter </Button>
                    <ExportCSV csvData={createCsvData(this.props.autditLogs)} fileName='test' />
                </div>
                <Table
                    columns={crateColummns()}
                    dataSource={createRows(this.props.autditLogs)}
                    scroll={{ x: 1500 }} sticky
                />
            </Card>
        )
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        fetchAuditLog: (merchantId) => dispatch(Actions.GetAllAuditLogs(merchantId)),
        fetchAuditByRange: (from, to) => dispatch(Actions.fetchAuditByRange(from, to)),
        fetchAuditByType: (auditType) => dispatch(Actions.fetchAuditByType(auditType)),
        clearAuditFiter: () => dispatch(Actions.clearAuditFiter())
    }
}
const mapStateToProps = state => {
    const { reportState, authState } = state
    const { temp } = reportState.auditLog
    return {
        autditLogs: temp,
        fetchedAudit: reportState.fetchedAudit | false,
        merchantId: authState.status === 'loggedIn' && state.authState.userInfo ? authState.userInfo.merchantId : ''
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AuditHistory)