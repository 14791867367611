import * as ActionTypes from './ActionTypes';
import { McommerceState } from './state';
import { Item, Order, Variation, Customer, CustomerGroup } from "../state";
const item: Item[] = []
const order: Order[] = []
const variation: Variation[] = []
const customer: Customer[] = []
const group: CustomerGroup[] = []
const initState: McommerceState = {
    order: {
        origin: order,
        temp: order,
    },
    product: {
        origin: item,
        temp: item,
    },
    variation: {
        origin: variation,
        temp: variation,
    },
    customer: {
        origin: customer,
        temp: customer
    },
    customerGroup: {
        origin: group,
        temp: group,
    },
    fetchedVariation: false,
    fetchedOrder: false,
    fetchedCustomer: false,
    fetchedGroup: false,
    fetchedProduct: false,
}

export const mcommerceReducer = (state: McommerceState = initState, action: any): McommerceState => {
    switch (action.type) {
        case ActionTypes.ADD_ORDER:
            const { orders } = action.payload as { orders: Order[] }
            return {
                ...state, order: { origin: orders, temp: orders, }, fetchedOrder: true,
            }
        case ActionTypes.ADD_PRODUCT:
            const { products } = action.payload as { products: Item[] }
            return { ...state, product: { origin: products, temp: products }, fetchedProduct: true }
        case ActionTypes.ADD_VARIATIONS:
            const { variations } = action.payload as { variations: Variation[] }
            return { ...state, variation: { origin: variations, temp: variation }, fetchedVariation: true }

        case ActionTypes.ADD_CUSTOMER:
            const { customers } = action.payload as { customers: Customer[] }
            return { ...state, customer: { origin: customers, temp: customers }, fetchedCustomer: true }

        case ActionTypes.ADD_CUSTOMER_GP:
            const { groups } = action.payload as { groups: CustomerGroup[] }
            return { ...state, customerGroup: { origin: groups, temp: groups }, fetchedGroup: true }
        default:
            return state
    }
}
