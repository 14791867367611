import * as Type from './actionType'
import config from '../../config'
const report_url = process.env.REPORT_URL || config().config.Report.url;
export const GetPointTrend = merchantId => dispatch => {
    let _url = `${report_url}/trend/point/${merchantId}`
    fetch(_url, {
        crossDomain: true
    })
        .then(response => response.json())
        .then(data => {
            var pointTrend = { pointTrend: [] }, _action = {}
            _action = { ..._action, type: Type.ADD_POINT_TREND, payload: { ...pointTrend } }
            if (data.status !== 'Error' || data.data.length < 0) {
                _action = { ..._action, payload: { ...pointTrend, pointTrend: data.data || [] } }
            }
            dispatch(_action)
        });
}
export const GetPointAndMemberMonthly = merchantId => dispatch => {
    let _url = `${report_url}/pointAndMember/monthly/${merchantId}`
    fetch(_url, {
        crossDomain: true
    })
        .then(response => response.json())
        .then(data => {
            let _trend = { pointMonthly: [] }, _action = {}
            _action = { ..._action, type: Type.ADD_MONTLY_POINT_MEMER, payload: { ..._trend } }
            if (data.status !== 'Error' || data.data.length < 0) {
                _action = { ..._action, payload: { ..._trend, pointMonthly: data.data || [] } }
            }
            dispatch(_action)
        });
}
export const GetPointMemberSummary = merchantId => dispatch => {
    let _url = `${report_url}/pointAndMember/summary/${merchantId}`
    fetch(_url, {
        crossDomain: true
    })
        .then(response => response.json())
        .then(data => {
            let pointMeberSummary = { totalMember: 0, earnPoint: 0, spendPoint: 0, earnEngage: 0, spendEngage: 0 }, _action = {}
            _action = { ..._action, type: Type.ADD_SUMMARY_POINT_MEMBER, payload: { ...pointMeberSummary } }
            if (data.status !== 'Error' || data.data.length < 0) {
                const { TotalMembers, earnPoints, spendPoints, earnEngages, spendEngages } = data.data[0]
                _action = {
                    ..._action, payload: { ...pointMeberSummary, totalMember: TotalMembers, earnEngage: earnEngages, spendEngage: spendEngages, earnPoint: earnPoints, spendPoint: spendPoints }
                }
            }
            dispatch(_action)
        });
}
export const GetPointLastMonth = (merchantId, monthNo) => dispatch => {
    let _url = `${report_url}/point/lastMonth/${merchantId}/${monthNo}`
    fetch(_url, { crossDomain: true })
        .then(response => response.json())
        .then(data => {
            let _trend = { pointLastMonth: [] }, _action = {}
            _action = { ..._action, type: Type.ADD_LASTMONTH_POINT, payload: { ..._trend } }
            if (data.status !== 'Error' || data.data.length < 0) _action = { ..._action, payload: { ..._trend, pointLastMonth: data.data || [] } }
            dispatch(_action)
        });
}