import React, { Component } from "react";
import CheckboxGroup from "./CheckboxGroup";
import 'antd/dist/antd.css';
//const options = ["award-point", "award-stamp", "award-reward", "claimed-reward", "member-tier", "subscription"];

export default class Form extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }
  state = {}
  handleChange = ({ target: { label, checked } }) =>
    this.setState({ [label]: checked });

  handleSubmit = e => {
    e.preventDefault();
    this.props.handleSumbit(Object.keys(this.state).filter(key => this.state[key] === true))
  };

  render = () => (
    < form onSubmit={this.handleSubmit} >
      <CheckboxGroup
        {...this.state}
        options={this.props.options ? this.props.options : []}
        handleChange={this.handleChange}
      />
      <div style={{ marginTop: 20 }}>
        <button
          className="ant-btn ant-btn-primary"
          type="submit"
          onClick={() => { }}
          style={{
            marginLeft: '30px'
          }}>
          Save
        </button>
      </div>
    </form >
  );
}