import * as React from 'react'

import { Table } from 'antd'
import { createRows, crateColummns } from './model'

const RewardTable = ({ rewards = [] }) => {
    return <Table
        size='small'
        columns={crateColummns()}
        dataSource={createRows(rewards)}
        scroll={{ x: 1500 }} 
        pagination={{ pageSize: 4}}
        sticky
    />
}
export default RewardTable