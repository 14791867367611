import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import { reducer as rformReducer } from 'redux-form';
import { createEpicMiddleware } from 'redux-observable';
import { reducers as authReducers } from '../auth';
import thunk from 'redux-thunk';
import { chartReducer } from '../Charts/redux/reducer';
import { reportReducer } from '../report/redux/reducer';
import { mcommerceReducer } from '../mcommerce/redux'
import epic from '../epic';

// redux
const reduxCombineReducers: any = combineReducers; //tslint:disable-line
const reducer = reduxCombineReducers({
  authState: authReducers.authReducer,
  chartState: chartReducer,
  reportState: reportReducer,
  mcommerceState: mcommerceReducer,
  form: rformReducer,
});

// class RWindow extends Window {
//   __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: typeof compose;
// }

// const rwindow = window as RWindow;
const composeEnhancers =
  (window['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__'] as typeof compose) || compose;

const epicMiddleware = createEpicMiddleware();
const middlewares = [epicMiddleware, thunk]
const store = createStore(
  reducer,
  composeEnhancers(applyMiddleware(...middlewares))
);
epicMiddleware.run(epic);
export * from './state';
export { store };
