import React from "react";
import { Alert } from "antd";
import './css.less';
export default ({ message }) => (
    <div className="alert__info">
        <Alert
            message={message}
            type="info"
            showIcon
            style={{
                backgroundColor: 'white',
                fontSize: '16px',
                borderColor: 'white'
            }} />
    </div>
);