import { Icon, Layout, Menu } from 'antd';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { AppState, AuthState } from '../store';
import logo from './piti.png';
const { SubMenu } = Menu
const { Sider } = Layout;

const mapStateToProps = ({ authState }: AppState) => {
  return {
    authState,
  };
};
interface P extends RouteComponentProps {
  authState: AuthState;
  onMenuClick?: (item: { key: string }) => void;
  currentMenuItem?: string;
}

class SideNav extends React.Component<P> {
  render() {
    const { onMenuClick, currentMenuItem, authState } = this.props;
    return (
      <Sider
        className="side-nav"
        collapsible={true}
        defaultCollapsed={false}
        style={{ backgroundColor: '#2d373c', color: '#222' }}
      >
        <div className="logo">
          <img alt={'logo'} src={logo} />
          <h2>Merchant</h2>
        </div>
        <Menu
          theme="dark"
          mode="inline"
          defaultSelectedKeys={['dashboard']}
          selectedKeys={[currentMenuItem || 'dashboard']}
          onClick={onMenuClick}
        >
          {/* <Menu.Item key="dashboard">
            <Icon type="home" />
            <span>Dashboard</span>
          </Menu.Item>
          <Menu.Item key="members">
            <Icon type="user" />
            <span>Members</span>
          </Menu.Item> */}
          {/* <Menu.Item key="chart">
            <Icon type="bar-chart" />
            <span>Charts</span>
          </Menu.Item> */}
          <SubMenu
            key="sub-dashboard"
            title={
              <span>
                <Icon type='home'></Icon>
                <span>Dashboard</span>
              </span>
            }
          >
            <Menu.Item key="dashboard/point">Point Dashboard</Menu.Item>
            <Menu.Item key="dashboard/member">Member Dashboard</Menu.Item>
            <Menu.Item key="dashboard/reward">Reward Dashboard</Menu.Item>
          </SubMenu>
          <SubMenu
            key="report"
            title={
              <span>
                <Icon type='bar-chart'></Icon>
                <span>Report</span>
              </span>
            }
          >

            <Menu.Item key="report/history/point">Point History</Menu.Item>
            <Menu.Item key="report/history/expire">Point Expire</Menu.Item>
            <Menu.Item key="report/history/stamp">Stamp</Menu.Item>
            <Menu.Item key="report/history/reward">Reward</Menu.Item>

            {/* <Menu.Item key="report/history">History</Menu.Item> */}

          </SubMenu>
          <SubMenu
            key="member"
            title={
              <span>
                <Icon type='user'></Icon>
                <span>Member</span>
              </span>
            }
          >
            <Menu.Item key="member/all">All</Menu.Item>
            <Menu.Item key="member/active">Active</Menu.Item>
            <Menu.Item key="member/new">New</Menu.Item>
            <Menu.Item key="member/return">Returning</Menu.Item>
            <Menu.Item key="member/top">Top</Menu.Item>
            <Menu.Item key="member/tier">Tier</Menu.Item>
            <Menu.Item key="member/inactive">Inactive</Menu.Item>
            <Menu.Item key="member/loyal">Loyal</Menu.Item>
            <Menu.Item key="member/risk">At Risk</Menu.Item>
            <Menu.Item key="member/sleep">Sleepers</Menu.Item>
            <Menu.Item key="member/red">Red Alert</Menu.Item>
          </SubMenu>
          {
            (authState.status === 'loggedIn' && authState.userInfo.posEnabled) &&
            <SubMenu
              key="mcommerce"
              title={
                <span>
                  <Icon type="appstore-o" />
                  <span>Mcommerce</span>
                </span>
              }
            >
              <Menu.Item key="mcommerce/dashboard/sale">Sale Dashboard</Menu.Item>
              <Menu.Item key="mcommerce/dashboard/order">Order Dashboard</Menu.Item>
              <Menu.Item key="mcommerce/dashboard/product">Product Dashboard</Menu.Item>
              <Menu.Item key="mcommerce/customer">Customer</Menu.Item>
              <Menu.Item key="mcommerce/order">Orders</Menu.Item>
              <Menu.Item key="mcommerce/product">Products</Menu.Item>
            </SubMenu>
          }
        </Menu>
      </Sider>
    );
  }
}
export default withRouter(connect(mapStateToProps, null)(SideNav)) as any;
