import * as React from 'react'
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { Card, Icon, Row, Col, Table } from "antd";
import { GetCustomerDetail, GetCustomerOrder, customerOrderColumns, customerOrderRows, GetCustomerProduct } from "./model";
import { customerProductColumns, customerProductRows } from "./model";
import { GetCustomerGroup, McommerceState, GetAllCustomer, GetAllOrder, GetAllProduct } from "./redux";
import { Customer, CustomerGroup, Order, Item } from "./state";
import nophoto from './img/noprofile.png';
const { Meta } = Card
interface Props extends RouteComponentProps {
    merchantId: string;
    getAllCustomer: (id: string) => any;
    getCustomerGroup: (id: string) => any;
    getAllOrder: (id: string) => any;
    getAllProduct: (id: string) => any;
    customers: Customer[];
    orders: Order[];
    products: Item[];
    groups: CustomerGroup[],
    fetchedProduct: boolean,
    fetchedCustomer: boolean;
    fetchedGroup: boolean;
    fetchedOrder: boolean;

}
const gs: CustomerGroup[] = []
class Detail extends React.Component<Props>{
    constructor(props: Props) {
        super(props);
    }
    componentDidMount() {
        const { fetchedCustomer, fetchedGroup, getCustomerGroup, getAllCustomer, merchantId } = this.props
        const { fetchedOrder, getAllOrder } = this.props
        const { fetchedProduct, getAllProduct } = this.props
        if (!fetchedCustomer) getAllCustomer(merchantId)
        if (!fetchedGroup) getCustomerGroup(merchantId)
        if (!fetchedOrder) getAllOrder(merchantId)
        if (!fetchedProduct) getAllProduct(merchantId)
    }
    render() {
        const { customerId } = this.props.match.params as { customerId: string }
        const state = (this.props.fetchedCustomer && this.props.fetchedGroup) ? GetCustomerDetail(this.props.customers, customerId, this.props.groups) : { customer: { name: '', phone: '', email: '', photo: null, address: '', }, groups: gs }
        return (
            <Card title={`Customer Detail`} loading={!this.props.fetchedGroup && !this.props.fetchedCustomer}>
                <Row gutter={24}>
                    <Col span={8}>
                        <Card
                            hoverable
                            style={{ width: 'auto' }}
                            cover={<img alt="alt"
                                src={state.customer.photo || nophoto} />}
                        >
                            <Meta
                                title={state.customer.name}
                                description={<span><Icon type='mail' /> {state.customer.email}   <Icon type='phone' />  {state.customer.phone}</span>} />
                        </Card>
                    </Col>
                    <Col span={16}>
                        <Card>
                            {/* <Row> */}
                            {/* <Col span={12}>
                                    <Card style={{ border: 'none' }}>
                                        <span>
                                            <Icon type='environment' size={30} />    Shipping Location
                                    </span>
                                    </Card>
                                </Col>
                                <Col span={12}>
                                    <Card style={{ border: 'none' }}>
                                        {state.customer.address}
                                    </Card>    </Col>
                            </Row> */}
                            <Row>
                                <Col span={12}>
                                    <Card style={{ border: 'none' }}>
                                        <span>
                                            <Icon type='environment-o' size={30} />  Address
                                    </span>
                                    </Card>
                                </Col>
                                <Col span={12}>
                                    <Card style={{ border: 'none' }}>
                                        {state.customer.address}
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={12}>
                                    <Card style={{ border: 'none' }}>
                                        <span>
                                            <Icon type='forward' size={50} /> First Ordered
                                    </span>
                                    </Card>
                                </Col>
                                <Col span={12}>
                                    <Card style={{ border: 'none' }}>
                                        No
                                   </Card>    </Col>
                            </Row>
                            <Row>
                                <Col span={12}>
                                    <Card style={{ border: 'none' }}>
                                        <span>
                                            <Icon type='backward' size={50} /> Last Ordered
                                    </span>
                                    </Card>
                                </Col>
                                <Col span={12}>
                                    <Card style={{ border: 'none' }}>
                                        No
                                   </Card>    </Col>
                            </Row>
                            <Row>
                                <Col span={12}>
                                    <Card style={{ border: 'none' }}>
                                        <span>
                                            <Icon type='team' size={60} /> CustomerGroup (Tags)
                                    </span>
                                    </Card>
                                </Col>
                                <Col span={12}>
                                    <Card style={{ border: 'none' }}>
                                        {state.groups.map(g => g.name).join(',')}
                                    </Card>    </Col>
                            </Row>
                            <Row>
                                <Col span={12}>
                                    <Card style={{ border: 'none' }}>
                                        <span>
                                            <Icon type='plus' size={30} /> State
                                    </span>
                                    </Card>
                                </Col>
                                <Col span={12}>
                                    <Card style={{ border: 'none' }}>
                                        Enabled
                                   </Card>    </Col>
                            </Row>
                        </Card>
                    </Col>
                    {/* <Table
                    columns={createColumns()}
                /> */}
                </Row>
                <Row gutter={24} style={{ marginTop: 12 }}>
                    <Card title='Order Of Customer' loading={!this.props.fetchedOrder}>
                        <Table
                            columns={customerOrderColumns()}
                            dataSource={customerOrderRows(GetCustomerOrder(customerId, this.props.orders).orders)}
                        />
                    </Card>
                </Row>
                <Row gutter={24} style={{ marginTop: 12 }}>
                    <Card title='Ordered Products' loading={!this.props.fetchedOrder && !this.props.fetchedProduct}>
                        <Table
                            columns={customerProductColumns()}
                            dataSource={customerProductRows(GetCustomerProduct(GetCustomerOrder(customerId, this.props.orders).itemIds.map(i => i.id), this.props.products))}
                        />
                    </Card>
                </Row>
            </Card >
        )
    }
}


const mapDispatchToProps = (dispatch: any, { history }: Props) => {
    return {
        getAllOrder: (merchantId: string) => dispatch(GetAllOrder(merchantId)),
        getAllCustomer: (merchantId: string) => dispatch(GetAllCustomer(merchantId)),
        getAllProduct: (merchantId: string) => dispatch(GetAllProduct(merchantId)),
        getCustomerGroup: (merchantId: string) => dispatch(GetCustomerGroup(merchantId))
    }
}
const mapStateToProps = (state: any) => {
    const { authState, mcommerceState: s } = state as { mcommerceState: McommerceState, authState: any }
    return {
        products: s.product.origin,
        customers: s.customer.origin,
        groups: s.customerGroup.origin,
        orders: s.order.origin,
        fetchedProduct: s.fetchedProduct,
        fetchedOrder: s.fetchedOrder,
        fetchedCustomer: s.fetchedCustomer,
        fetchedGroup: s.fetchedGroup,
        merchantId: authState.status === 'loggedIn' && state.authState.userInfo ? authState.userInfo.merchantId : ''
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Detail)