import React, { Component } from 'react'
import 'antd/dist/antd.css';
import { Card, Table } from 'antd'
import moment from 'moment'
import '../css.less'
import { connect } from 'react-redux';
import * as Actions from '../redux/actions'
import { ExportCSV } from '../excel/exportCSV'
import { Alert } from '../Member/component'
import DateRange from './component/dateRange'
import { ExpireModel } from './model'
const dateFormat = 'YYYY/MM/DD';
const { createColumns, createCsvData, createRows } = ExpireModel
class ExpirePointList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            tdate: moment().add(1, 'month'),
            sdate: moment(),
            hasInit: false,
            loading: false,
            pending: false,
            fileName: "ExpirePoints_next_one_month"
        }
        this.onOk = this.onOk.bind(this)
        this.onhandleChange = this.onhandleChange.bind(this)
        this.init = this.init.bind(this)
        this.getFileName = this.getFileName.bind(this)
        this.show = this.show.bind(this)
        this.onViewHandler = this.onViewHandler.bind(this)
        this.onpresetController = this.onpresetController.bind(this)
    }
    init(props) {
        const { fetchedMember, getAllMember, merchantId, memberData } = props
        const { fetchedRootPoint, getAllPoint, pointData } = props
        const { fetchedRootPointCount, totalCount, getAllPointCount } = props
        const { fetchedExpirePoint, getExpirePoint } = props
        if (!fetchedMember) getAllMember(merchantId)
        if (!fetchedRootPointCount) getAllPointCount(merchantId)
        if (fetchedRootPointCount && !fetchedRootPoint && !this.state.pending) {
            this.setState({
                pending: true
            }, () => {
                getAllPoint(merchantId, totalCount)
            })
        }
        if (fetchedMember && fetchedRootPoint) {
            const { sdate, tdate, hasInit } = this.state
            if (!hasInit && !fetchedExpirePoint) {
                let newObj = { members: memberData, points: pointData, from: moment(sdate).unix() * 1000, to: moment(tdate).unix() * 1000 }
                this.setState({
                    hasInit: true,
                })
                getExpirePoint({ ...newObj })
            }
            if (fetchedExpirePoint) {
                this.setState({
                    loading: true
                })
            }
        }
    }
    componentDidMount() {
        this.init(this.props)
    }
    componentWillReceiveProps(nextProps) {
        this.init(nextProps)
    }
    onhandleChange(m, st) {
        const f = moment(st[0]).unix() * 1000
        const t = moment(st[1]).unix() * 1000
        this.setState({
            sdate: f,
            tdate: t
        })
    }
    onpresetController(m, st) {
        const { memberData, pointData, getExpirePoint } = this.props
        const f = moment(st[0]).unix() * 1000
        const t = moment(st[1]).unix() * 1000
        this.setState({
            sdate: f,
            tdate: t,
            fileName: this.getFileName(st[0], st[1])
        }, () => {
            const newObj = { members: memberData, points: pointData, from: f, to: t }
            getExpirePoint({ ...newObj })
        })
    }
    onOk() {
        const { memberData, pointData, getExpirePoint } = this.props
        let { sdate, tdate } = this.state
        let newObj = { members: memberData, points: pointData, from: moment(sdate).unix() * 1000, to: moment(tdate).unix() * 1000 }
        this.setState({
            loading: false,
            fileName: this.getFileName(sdate, tdate)
        }, () => {
            getExpirePoint({ ...newObj })
        })
    }
    getFileName(sdate, tdate) {
        let from = moment(sdate).format(dateFormat).toString()
        let to = moment(tdate).format(dateFormat).toString()
        return "ExpirePoints_" + from + "_" + to
    }
    onViewHandler(memberId) {
        const { onView } = this.props
        if (memberId !== undefined) {
            onView(memberId)
        }
    }
    show() {
        const { expirePointData } = this.props
        return (
            <div>
                <div className='active__member__bar'>
                    <h2 className="active__member__title"> Expire Points </h2>
                    <DateRange
                        className='active_member__date'
                        format={dateFormat}
                        defaultValue={[moment(this.state.sdate), moment(this.state.tdate)]}
                        onChange={(s, t) => this.onhandleChange(s, t)}
                        onOk={(e) => this.onOk(e)}
                        footerController={(m, st) => this.onpresetController(m, st)}
                        showTime
                    />
                    <ExportCSV csvData={createCsvData(createRows(expirePointData))} fileName={this.state.fileName} />
                </div>
                <Table
                    size='middle'
                    columns={createColumns({}, (memberId) => this.onViewHandler(memberId))}
                    dataSource={createRows(expirePointData)}
                    scroll={{ x: 1500 }} sticky
                />
            </div>)
    }
    render() {

        return (
            <div className='active__member'>
                <Alert message="Expire Member  points in given period" />
                <Card
                    loading={!this.state.loading}
                >
                    {this.show()}
                </Card>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch, { history }) => {
    return {
        onView: (memberId) => history.push(`/member/detail/${memberId}`),
        getAllMember: (merchantId) => dispatch(Actions.GetAllMember(merchantId)),
        getAllPoint: (merchantId, totalCount) => dispatch(Actions.GetAllPoint(merchantId, totalCount)),
        getAllPointCount: (merchantId) => dispatch(Actions.GetAllTotalPointCount(merchantId)),
        getExpirePoint: ({ members, points, from, to }) => dispatch(Actions.GetExpirePoint({ members, points, from, to }))
    }
}
const mapStateToProps = state => {
    const { authState, reportState } = state
    const { member, fetchedMember, fetchedRootPoint, root } = reportState
    const { fetchedExpirePoint, expire } = reportState
    const { totalCount, fetchedRootPointCount, point } = root
    const { origin } = member
    const [expirePoint] = [expire.point]
    return {
        pointData: point,
        memberData: origin,
        expirePointData: expirePoint,
        totalCount: totalCount,
        fetchedMember: fetchedMember,
        fetchedExpirePoint: fetchedExpirePoint,
        fetchedRootPoint: fetchedRootPoint,
        fetchedRootPointCount: fetchedRootPointCount,
        merchantId: authState.status === 'loggedIn' && state.authState.userInfo ? authState.userInfo.merchantId : ''
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ExpirePointList)