import * as Type from './actionTypes'
import config from '../../config'
import moment from 'moment'
// const dateFormat = 'YYYY/MM/DD';
const report_url = process.env.REPORT_URL || config().config.Report.url
// point actions
export const fetchPointHistory = (merchantId) => dispatch => {
    let _url = `${report_url}/history/point/${merchantId}`
    // console.log(`Report point url :${_url}`)
    fetch(_url, {
        crossDomain: true,
    })
        .then(response => {
            return response.json()
        })
        .then(data => {
            //  console.log("Get point history data", data)
            let _action = {}
            _action = { ..._action, type: Type.ADD_POINT_HISTORY, payload: [] }
            if (data.status !== 'Error' || data.data.length < 0) {
                _action = { ..._action, payload: data.data || [] }
            }
            dispatch(_action)
        }).catch(e => console.log("Respone error", e))
        ;
}

//expected parms milliseconds [from ,to ]
export const addPointFilterRange = (from, to) => dispatch => {
    const _action = {
        type: Type.ADD_POINT_FILTER_RANGE,
        payload: {
            from: from,
            to: to
        }
    }
    dispatch(_action)
}

//expected params Array [filterType]
export const addPointFilterType = (filterType = []) => (dispatch) => {
    const _action = {
        type: Type.ADD_POINT_FILTER_TYPE,
        payload: filterType
    }
    dispatch(_action)
}
export const clearPointFilter = () => (dispatch) => {
    const _action = {
        type: Type.CLEAR_POINT_FILTER
    }
    dispatch(_action)
}
export const GetAllTotalPointCount = (merchantId) => dispatch => {
    let _url = `${report_url}/point/totalCount/${merchantId}`
    fetch(_url, { crossDomain: true, })
        .then(response => response.json())
        .then(data => {
            //console.log("Total Count data", data)
            let _action = {}, count = { totalCount: 0, call: [] }
            _action = { ..._action, type: Type.ADD_ALL_TOTAL_POINT_COUNT, payload: { ...count } }
            if (data.status !== 'Error' || data.data.length < 0) {
                const { totalCount } = data.data[0]
                _action = { ..._action, payload: { ...count, totalCount: totalCount, call: GetLimitOffset(totalCount) } }
            }
            dispatch({ ..._action })
        });
}
export const GetLimitOffset = (totalCount) => {
    const defaultLimit = 20000
    const secondLimit = 40000
    const thirdLimit = 60000
    const fourLimit = 80000
    const fivelimit = 10000
    let result = [{ limit: totalCount, offset: 0 }]

    if (totalCount > defaultLimit && totalCount <= secondLimit)
        result = [{ limit: 20000, offset: 0 }, { limit: 20000, offset: 20000 }]
    if (totalCount > secondLimit && totalCount <= thirdLimit)
        result = [{ limit: 20000, offset: 0 }, { limit: 20000, offset: 20000 }, { limit: 20000, offset: 40000 }]

    if (totalCount > thirdLimit && totalCount <= fourLimit)
        result = [{ limit: 20000, offset: 0 }, { limit: 20000, offset: 20000 }, { limit: 20000, offset: 40000 }, { limit: 20000, offset: 60000 }]

    if (totalCount > fourLimit && totalCount <= fivelimit)
        result = [{ limit: 20000, offset: 0 }, { limit: 20000, offset: 20000 }, { limit: 20000, offset: 40000 }, { limit: 20000, offset: 60000 }, { limit: 20000, offset: 80000 }]
    return result
}

export const GetAllPoint = (merchantId, totalCount) => dispatch => {
    GetLimitOffset(totalCount).forEach(l => {
        let _url = `${report_url}/point/all/${merchantId}/${l.limit}/${l.offset}`
        // console.log(`Report allPoint url :${_url}`)
        fetch(_url, {
            crossDomain: true,
        })
            .then(response => response.json())
            .then(data => {
                // console.log("Get point history data", data)
                let _action = {}
                _action = { ..._action, type: Type.ADD_ALL_POINT_RECORDS, payload: [] }
                if (data.status !== 'Error' || data.data.length < 0) {
                    _action = { ..._action, payload: data.data || [] }
                }
                dispatch(_action)
            });
    })

}
export const GetPointHistory = ({ members = [], points = [] }) => dispatch => {
    let historyPoints = points.map(p => {
        const m = members.filter(m => m.id === p.memberId)[0]
        return { ...p, memberName: m ? m.displayName : 'Unknown' }
    }).filter(p => p.type === 'earn')
    let _action = {}
    _action = { ..._action, type: Type.ADD_POINT_HISTORY, payload: historyPoints }
    dispatch(_action)
}
export const GetExpirePoint = ({ members = [], points = [], from = 0, to = 0 }) => dispatch => {
    // console.log('expirepont and member ', members.length, 'points ', points.length, 'date from ', moment(from).format(dateFormat), '\n to ', moment(to).format(dateFormat))
    let origin = points
    points = points.filter(
        p => moment(p.expiredAt).unix() * 1000 >= from &&
            moment(p.expiredAt || 0).unix() * 1000 <= to
    )
    let expireList = members.map(m => {
        let epoint = 0, spoint = 0
        origin.filter(p => (p.memberId === m.id) && (p.type === 'spend')).map(p => {
            spoint = spoint + p.point
            return ''
        })
        //p.type === 'earn' ? totalPoints += p.point : totalPoints -= p.point
        points.filter(p => p.memberId === m.id && p.type === 'earn').map(p => {
            // console.log('point type in expire', p.type)
            epoint = epoint + p.point
            return ''
        })
       // console.log('spend point', spoint, 'earn point', epoint, '\nmemberId', m.id)
        return { ...m, totalExpirePoint: (epoint + spoint), expiredAt: to }
    }).filter(m => m.totalExpirePoint > 0 && m.memberPoint > 0)
    //console.log('Expire Points', expireList.length)
    let action = { type: Type.ADD_EXPIRE_POINT, payload: { expirePoints: expireList } }
    dispatch({ ...action })
}