import { Avatar, Divider, Dropdown, Icon, Layout, Menu, Breadcrumb, Typography } from 'antd';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { Dispatch } from 'redux';
import { actions } from '../auth';
import { AppState } from '../store';
const { Text } = Typography
const mapStateToProps = ({ authState }: AppState) => {
  if (authState.status === 'loggedIn') {
    return {
      name: authState.userInfo.merchantId || '',
    };
  }
  return {
    name: '',
  };
};
const mapDispatchToProps = (dispatch: Dispatch, { history }: Props) => {
  const { loggedOut } = actions;
  return {
    onMenuClick: (item: { key: string }, id?: string) => {
      switch (item.key) {
        case 'logout': {
          history.push('/');
          dispatch(loggedOut());
          break;
        }
        default: {
          history.push(`/${item.key}`);
          break;
        }
      }
    },
  };
};
interface Props extends RouteComponentProps {
  uid: string;
  name: string;
  onMenuClick: (item: { key: string }, id?: string) => void;
}
class TopBar extends React.Component<Props> {
  handleMenuClick = (item: { key: string }) => {
    const { onMenuClick, uid } = this.props;
    onMenuClick(item, uid);
  };
  render() {
    const { location } = this.props;
    const menu = (
      <Menu
        className="menu"
        selectedKeys={[]}
        onClick={this.handleMenuClick} >
        <Menu.Item key="logout">
          <Icon type="logout" />
          Log out
        </Menu.Item>
      </Menu>
    );
    return (
      <Layout className="top-bar">
        <div className="container">
          <div style={{ float: 'right', paddingLeft: 12, display: 'flex' }}>
            <Breadcrumb style={{ margin: '16px 0' }}>
              <Breadcrumb.Item><a href="https://merchant.piti.app">Home </a></Breadcrumb.Item>
              {
                location.pathname.split('/').length > 1 &&
                location.pathname.split('/').map((name) => name !== '' &&
                  <Breadcrumb.Item key={name}> {name == 'Home' ? <a href="https://merchant.piti.app">{name}</a> : name} </Breadcrumb.Item>)
              }
            </Breadcrumb>
          </div>
          <div className="right-half">
            <div style={{ float: 'right' }}>
              <Text type="success" style={{ fontWeight: 'bold', fontSize: 16 }}> {this.props.name}</Text>
              <Divider type="vertical" />
              <Dropdown overlay={menu}>
                <span className="action account">
                  <Avatar
                    className="avator"
                    size="small"
                    icon="user"
                    src="/profile.png"
                  />
                </span>
              </Dropdown>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TopBar)) as any;
