// root point get all merchant's point records
export const ADD_ALL_POINT_RECORDS = "ADD MERCHANT ALL RECORDS"

// member actoin types
export const ADD_ALL_MEMBER = 'GET ALL MEMBER'
export const ADD_MEMBER_FILTER_RANGE = 'GET MEMBER BY RANGE'
export const CLEAR_MEMBER_FILTER = 'CLEAR MEMBER FILTER'
export const ADD_ACTIVE_MEMBER = "ADD ACTIVE MEMBER"
export const ADD_INACTIVE_MEMBER = "ADD INACTIVE MEMBER"
export const ADD_RED_ALERT_MEMBER = "ADD RED ALERT MEMBER"
export const ADD_SLEEP_MEMBER = "ADD SLEEP MEMBER"
export const ADD_RISK_MEMBER = 'ADD RISK MEMBER'
export const ADD_ROYAL_MEMBER = "ADD ROYAL MEMBER"
export const ADD_RETURN_MEMER = "ADD RETURN MEMBER"
export const ADD_NEW_MEMBER = "ADD NEW MEMBER"
export const ADD_REPORT_TOP_MEMBER = "ADD TOP  MEMBER REPORT"
// firebase autditlog action types
export const GET_ALL_AUDITLOGS = 'GET ALL AUDIT LOGS'
export const ADD_AUDIT_FILTER_RANGE = "GET FILTER BY RANGE"
export const ADD_AUDIT_FILTER_TYPE = "ADD AUDIT FILTER TYPE"
export const CLEAR_AUDIT_FILTER = "CLEAR AUDIT FILER"


// history point action types
export const ADD_POINT_HISTORY = 'ADD POINT HISTORY'
export const ADD_POINT_FILTER_RANGE = 'ADD POINT FITLER RANGE'
export const ADD_POINT_FILTER_TYPE = 'ADD POINT FILTER TYPE'
export const CLEAR_POINT_FILTER = 'CLEAR POINT FILTER'
export const ADD_ALL_TOTAL_POINT_COUNT = 'ADD TOTAL POINT ACCOUNT'
export const ADD_EXPIRE_POINT= "ADD EXPIRE POINT"
// history stamp action types
export const ADD_STAMP_HISTOR = 'ADD STAMP HISTORY'
export const ADD_STAMP_FILTER_RANGE = 'ADD STAMP FILTER RANGE'
export const ADD_STAMP_FILTER_TYPE = 'ADD STAMP FILTER TYPE'
export const CLEAR_STAMP_FILTER = 'CLEAR STAMP FILTER'

// history reward action types
export const ADD_REWARD_HISTORY = 'ADD REWARD HISTORY'
export const ADD_REWARD_FILTER_RANGE = 'ADD REWARD FILTER RANGE'
export const ADD_REWARD_FILTER_TYPE = ' ADD REWARD FITLER TYPE'
export const CLEAR_REWARD_FILTER = "CLEAR REWARD FILTER"