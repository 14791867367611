import * as React from "react";
import 'antd/dist/antd.css';
import { Row, Col, Avatar, Card } from "antd";
import * as Actions from '../redux/actions'
import { HighStockChart } from '../HighCharts'
import PanelBox from "../PanelBox/PanelBox";
import './customer.less'
import * as Colors from '../../color'
import gtm, { tagManagerArgs } from '../../gtm'
import { connect } from 'react-redux';
import { MemberAllShow, MemberTierShow, MemberTopShow } from "./showmore";
import { GetAllMember } from "./showmore"
import { trendConfig, championConfig } from './model'

import { withRouter } from "react-router";
const ChampionMember = (props) => {
    const { champion, championKey } = props
    return (
        <div key={championKey.toString()}>
            <div style={{ display: "flex", height: 50 }}>
                <div style={{ color: 'blue', marginTop: '10px', marginLeft: '12px' }}>
                    {champion.photoUrl ? <Avatar src={champion.photoUrl} size={64} /> : <Avatar icon='user' size={70} style={{ color: 'black' }} />}
                </div>
                <div style={{ marginTop: '10px', paddingLeft: '10px' }}>
                    <li>
                        <h3 style={{ color: Colors.MemberColor, fontWeight: 'bold' }}>{champion.displayName}</h3>
                    </li>
                    <li>
                        <i>{champion.contact}</i>
                    </li>
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    {/* <Avatar icon='star' style={{ color: Colors.BannaColor, backgroundColor: 'white' }} size={50} />
                    <Avatar icon='star' style={{ color: Colors.BannaColor, backgroundColor: 'white' }} size={50} />
                    <Avatar icon='star' style={{ color: Colors.BannaColor, backgroundColor: 'white' }} size={50} /> */}
                    <Avatar src={champion.tierUrl} size='small' shape='square' />
                </div>
            </div>
            <div style={{ display: 'flex', marginTop: '30px' }}>
                <div >
                    <ul>
                        <li style={{ color: 'black', fontWeight: 'bold' }}>
                            Total Points
                        </li>
                        <li style={{ color: Colors.LabelColor }}> {champion.totalPoint}</li>
                    </ul>
                </div>
                <div  >
                    <ul>
                        <li style={{ color: 'black', fontWeight: 'bold' }}>
                            Current Points
                                            </li>
                        <li style={{ color: Colors.LabelColor }}> {champion.currentPoint}</li>
                    </ul>
                </div>
                <div >
                    <ul>
                        <li style={{ color: 'black', fontWeight: 'bold' }}>
                            Frequency
                                            </li>
                        <li style={{ color: Colors.LabelColor }}> {champion.totalEngage}</li>
                    </ul>
                </div>
            </div>
        </div>
    )
}
class CustomerChart extends React.Component {
    constructor(props) {
        super(props)
        gtm.dataLayer({
            ...tagManagerArgs, dataLayer: {
                page: 'Member Dashboard'
            }
        })
    }
    componentDidMount() {
        const { merchantId, fetchedMemberTrend, fetchedTopMember, fetchedSummary } = this.props
        const { trend, summary, top } = this.props
        const { fetchedMember, getAllMember } = this.props
        if (!fetchedSummary) summary(merchantId)
        if (!fetchedTopMember) top(merchantId)
        if (!fetchedMemberTrend) trend(merchantId)
        if (!fetchedMember) getAllMember(merchantId)
    }
    render() {
        const { summaryData, topMemberData, trendData, fetchedMemberTrend, fetchedSummary, fetchedTopMember } = this.props
        const { totalMember = 0, earnPoint = 0, earnEngage = 0 } = summaryData
        const { fetchedMember, memberData } = this.props
        return (
            <div className='customer'>
                <div className='customer__bar'>
                    <Row gutter={10} >
                        <Col span={8}>
                            <PanelBox className="card_item_one" loading={!fetchedSummary}>
                                <div style={{ display: "flex", height: 50 }}>
                                    <div style={{ color: 'blue' }}>
                                        <Avatar icon="team" size={64} style={{ color: 'black', backgroundColor: 'white' }} />
                                    </div>
                                    <div style={{ marginTop: '10px' }}>
                                        <ul>
                                            <li style={{ color: 'black', fontSize: 16 }}>Total Members</li>
                                            <li style={{
                                                color: Colors.LabelColor
                                            }}> {totalMember}</li>
                                        </ul>
                                    </div>

                                </div>
                            </PanelBox>
                        </Col>
                        <Col span={8}>
                            <PanelBox className="card_item_two" loading={!fetchedSummary}>
                                <div style={{ display: "flex", height: 50 }}>
                                    <div style={{ color: 'blue' }}>
                                        <Avatar icon="trophy" size={64} style={{ color: 'black', backgroundColor: 'white' }} />
                                    </div>
                                    <div style={{ marginTop: '10px' }}>
                                        <ul>
                                            <li style={{ color: 'black', fontSize: 16 }}>Total Points</li>
                                            <li style={{
                                                color: Colors.LabelColor
                                            }}> {earnPoint}</li>
                                        </ul>
                                    </div>
                                </div>
                            </PanelBox>
                        </Col>
                        <Col span={8}>
                            <PanelBox className="card_item_three" loading={!fetchedSummary}>
                                <div style={{ display: "flex", height: 50 }}>
                                    <div style={{ color: 'blue' }}>
                                        <Avatar icon="swap" size={64} style={{ color: 'black', backgroundColor: 'white' }} />
                                    </div>
                                    <div style={{ marginTop: '10px' }}>
                                        <ul>
                                            <li style={{ color: 'black', fontSize: 16 }}>Total Engagement</li>
                                            <li style={{
                                                color: Colors.LabelColor
                                            }}> {earnEngage}</li>
                                        </ul>
                                    </div>
                                </div>
                            </PanelBox>
                        </Col>
                    </Row>
                </div>
                <div className='point__card'>
                    <Row gutter={24}>
                        <Col span={24}>
                            <Card
                                loading={!fetchedMemberTrend}
                                bordered={false}
                            >
                                <Row className='hight_stock_chart' gutter={24} justify='center'>
                                    <Col span={14} >
                                        <Card
                                            loading={!fetchedMemberTrend}
                                            bordered={false}
                                            bodyStyle={{
                                                padding: '24px 36px 24px 0',
                                            }}>
                                            <HighStockChart config={trendConfig(trendData)} />
                                        </Card>
                                    </Col>
                                    <Col span={10}>
                                        <Card bordered={false} loading={!fetchedTopMember} >
                                            {
                                                championConfig(topMemberData).map((m, index) => <ChampionMember champion={m} championKey={index} />)
                                            }
                                        </Card>

                                    </Col>
                                    {/* <Col lg={24} md={12} style={{ marginTop: 40 }}>
                            <HighStockChart config={trendConfig(trendData)} />
                        </Col> */}
                                </Row>
                                <Row gutter={24}>
                                    <Col span={24}>
                                        <Card
                                            loading={!fetchedMember}
                                            bordered={false}
                                        >
                                            <Row gutter={10} >
                                                <Col span={8}>
                                                    <Card
                                                        loading={!fetchedMember}
                                                        bordered={false}
                                                    >
                                                        <MemberAllShow list={memberData} {...this.props} />
                                                    </Card>
                                                </Col>
                                                <Col span={8}>
                                                    <Card
                                                        loading={!fetchedTopMember}
                                                        bordered={false}
                                                    >
                                                        <MemberTierShow list={topMemberData} {...this.props} />
                                                    </Card>
                                                </Col>
                                                <Col span={8}>
                                                    <Card
                                                        loading={!fetchedTopMember}
                                                        bordered={false}
                                                    >
                                                        <MemberTopShow list={topMemberData} {...this.props} />
                                                    </Card>
                                                </Col>
                                            </Row>
                                        </Card>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div >

        )
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        // dispatching plain actions
        summary: (merchantId) => dispatch(Actions.GetPointMemberSummary(merchantId)),
        trend: (merchantId) => dispatch(Actions.GetMemberTrend(merchantId)),
        top: (merchantId) => dispatch(Actions.GetTopMemberSummary(merchantId)),
        getAllMember: (merchantId) => dispatch(GetAllMember(merchantId)),
    }
}
const mapStateToProps = state => {
    const { chartState, authState, reportState } = state
    const { fetchedMember } = reportState
    const [memberData] = [reportState.member.origin]
    const { summary, chart, fetchedSummary, fetchedMemberTrend, fetchedTopMember } = chartState
    const { member } = chart
    const { trend, topMember } = member
    return {
        summaryData: summary,
        trendData: trend,
        topMemberData: topMember,
        fetchedSummary: fetchedSummary,
        fetchedMemberTrend: fetchedMemberTrend,
        fetchedTopMember: fetchedTopMember,
        memberData: memberData,
        fetchedMember: fetchedMember,
        merchantId: authState.status === 'loggedIn' && state.authState.userInfo ? authState.userInfo.merchantId : ''
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CustomerChart));