export const REWARD_POINT = 'Total reward Points'
export const REDEEM_POINT = 'Total redeem Points'
export const TOTAL_MEMBER = 'Total member count'
export const TOP_MEMBER = "Top member list"
export const POINT_TREND = "Point Trends"

// summary action types 
export const ADD_SUMMARY_POINT_MEMBER = 'ADD MEMBER AND SUMMARY'

// point summary chart data action types
export const ADD_POINT_TREND = 'ADD POINT TRENDS'
export const ADD_MONTLY_POINT_MEMER = 'ADD POINT AND MEMBER MONTHLY'
export const ADD_LASTMONTH_POINT = 'ADD LAST MONTHS POINTS'

// member summary chart data action types
export const ADD_MEMBER_TREND = 'ADD MEMBER TRENDS'
export const ADD_TOP_MEMBER = 'ADD TOP MEMBERS'