import * as _ from 'lodash';
export const championConfig = (topMember = []) => {
    if (topMember.length >= 3)
        return topMember.slice(0, 3).map(m => {
            return {
                photoUrl: m.memberPhoto,
                displayName: m.displayName,
                tierUrl: m.tierPhoto,
                tierName: m.tierName,
                contact: m.memberEmail || m.memberPhone,
                totalPoint: m.earnPoints,
                totalEngage: m.earnEngages,
                currentPoint: m.currentPoints,
            }
        })
    return topMember
}

export const trendConfig = (trends = []) => {
    var data = Array(trends.length).fill().map(m => Array(2).fill().map(m => m))
    _.orderBy(trends, 'joinedAt', 'asc').map((p, index) => {
        data[index][0] = p.joinedAt * 1000
        data[index][1] = p.memberCount
        return ''
    })
    return {
        rangeSelector: {
            selected: 1,
        },
        title: {
            text: 'Member Trends',
        },
        credits: {
            enabled: false
        },
        series: [
            {
                name: 'Member',
                data,
                tooltip: {
                    valueDecimals: 2,
                },
            },
        ]
    }
}