import React from 'react';
import 'antd/dist/antd.css';
import Highlighter from 'react-highlight-words';
import { Table, Input, Button, Icon } from 'antd';
import { AllModel } from '../model'
const { createColumns, createRows } = AllModel
export default class DataTable extends React.Component {
  state = {
    searchText: '',
    searchedColumn: '',
  };
  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <span>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            //  icon={<span><Icon type="search" /></span>}
            icon="search"
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </span>
      </div>
    ),
    filterIcon: filtered => <Icon style={{ color: filtered ? '#1890ff' : undefined }} type='filter' />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: text =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : <b style={{ color: 'blue' }}>{text}</b>
    ,
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };
  onView = memberId => {
    const { onView } = this.props
    if (memberId !== undefined) {
      onView(memberId)
    }
  }
  render() {

    const column = createColumns({ fn: this.getColumnSearchProps('name'), onView: (memberId) => this.onView(memberId) })
    return (
      <Table columns={column} dataSource={createRows(this.props.rows)} scroll={{ x: 1500 }} sticky rowKey='key' />
    )
  }
}
