import * as React from 'react';
import { Card, Button } from 'antd';
import moment from 'moment'
import { connect } from 'react-redux';
import * as Actions from '../../redux/actions'
import '../css.less'
import DataTable from './Table'
import { ExportCSV } from '../../excel/exportCSV'
import { Alert, DateRange } from '../component'
import { AllModel } from '../model'
const { createRows, createCsvData } = AllModel

const dateFormat = 'YYYY/MM/DD';
class MemberList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sdate: moment(),
            tdate: moment(),
        }
        this.onhandleChange = this.onhandleChange.bind(this)
        this.onOk = this.onOk.bind(this)
        this.init = this.init.bind(this)
        this.onpresetController = this.onpresetController.bind(this)
    }
    onhandleChange(m, st) {
        this.setState({
            sdate: st[0],
            tdate: st[1]
        })
    }
    init(props) {
        const { getAllMember, fetchedMember, merchantId, fetchedRootPoint, getAllPoint } = props
        const { fetchedRootPointCount, getAllPointCount, rootPointTotalCount } = props
        if (!fetchedMember) getAllMember(merchantId)
        if (!fetchedRootPointCount) getAllPointCount(merchantId)
        if (fetchedRootPointCount && !fetchedRootPoint) getAllPoint(merchantId, rootPointTotalCount)
    }
    onpresetController(m, st) {
        const { addFilterRange } = this.props
        this.setState({
            sdate: st[0],
            tdate: st[1],
        }, () => {
            addFilterRange(moment(this.state.sdate).unix() * 1000, moment(this.state.tdate).unix() * 1000)
        })
    }
    onOk() {
        const { addFilterRange } = this.props
        addFilterRange(moment(this.state.sdate).unix() * 1000, moment(this.state.tdate).unix() * 1000)
    }
    componentDidMount() {
        this.init(this.props)
    }
    componentWillReceiveProps(nextProps) {
        this.init(nextProps)
    }
    render() {
        const { fetchedMember, data, onView } = this.props
        return (
            <div className="all_member">
                <Alert message="All your member in your membership program" />
                <Card
                    loading={!fetchedMember}>
                    <div className='member__bar'>
                        <h2 className="member__title"> Member List </h2>
                        <DateRange
                            className='member__date'
                            format={dateFormat}
                            defaultValue={[this.state.sdate, this.state.tdate]}
                            onChange={(s, t) => this.onhandleChange(s, t)}
                            onOk={() => this.onOk()}
                            footerController={(m, st) => this.onpresetController(m, st)}
                            showTime
                        />
                        <Button icon='delete' className="member__filter__clear" onClick={() => this.props.clearFilter()} > Clear Filter </Button>
                        <ExportCSV csvData={createCsvData(createRows(data))} fileName='customer' />
                    </div>
                    <DataTable rows={data} onView={(memberId) => onView(memberId)} />
                </Card>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch, { history }) => {
    return {
        getAllMember: (merchantId) => dispatch(Actions.GetAllMember(merchantId)),
        clearFilter: () => dispatch(Actions.clearMemberFilter()),
        onView: (memberId) => history.push(`/member/detail/${memberId}`),
        addFilterRange: (from, to) => dispatch(Actions.addMemberFilterRange(from, to)),
        getAllPoint: (merchantId, totalCount) => dispatch(Actions.GetAllPoint(merchantId, totalCount)),
        getAllPointCount: (merchantId) => dispatch(Actions.GetAllTotalPointCount(merchantId))
    }
}
const mapStateToProps = state => {
    const { reportState } = state
    const { member, fetchedMember, fetchedRootPoint, root } = reportState
    const { totalCount, fetchedRootPointCount } = root
    const { temp } = member
    return {
        data: temp,
        rootPointTotalCount: totalCount,
        fetchedMember: fetchedMember || false,
        fetchedRootPoint: fetchedRootPoint,
        fetchedRootPointCount: fetchedRootPointCount,
        merchantId: state.authState.status === 'loggedIn' && state.authState.userInfo ?
            state.authState.userInfo.merchantId : ''
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(MemberList)