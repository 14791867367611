import * as React from 'react';
import { Item, Variation, Order, ItemSet, SubItemCustomer } from "../state";
import moment from 'moment';
import { Avatar } from "antd";
import * as _ from 'lodash';
export const GetProductSummary = (orders: Order[], itemsets: ItemSet[]) => {
    let photo: string | null = null, totalNetSales = 0, name = ''
    if (itemsets.length > 1) {
        name = itemsets[0].itemName
        itemsets.map(v => {
            if (photo === null) {
                photo = v.itemPhoto
            }
        })
    }
    if (!photo) {
        photo = ''
    }
    totalNetSales = orders.reduce((a, b) => a + b.totalAmount, 0);
    return { photo, totalNetSales, name }
}
export const GetProductVariants = (itemsets: ItemSet[]) => {
    const variations = itemsets.map(iSet => JSON.parse(iSet.itemVariations))
    return variations
}
export const GetProductDetail = (products: Item[] = [], id: string, vars: Variation[]) => {
    const _p = products.filter(p => p.id === id)[0]
    const term = _p.name.replace(/\\$/, "")
    const search = new RegExp(term, 'i')
    const _vars: Variation[] = vars.filter(v => search.test(v.id)) || []
    return {
        product: _p,
        variations: _vars,
    }
}

export const createRows = (rows: Variation[] = []) => {
    let i = 0
    return rows.length > 0 ?
        rows.map(row => {
            i++;
            return {
                key: i,
                name: row.name,
                id: row.id,
                point: row.points || 0,
                photo: row.unitAbbr,
                date: new Date(row.updatedAt).toDateString(),
                qty: row.stockQty,
                control: row.enableInventoryControl,
            }
        }) :
        []
}
export const createColumns = () => {
    const columns = [
        {
            title: 'Photo',
            width: 100,
            key: 'photo',
            fixed: 'left',
            render: (v: { photo: string }) => < Avatar src={v.photo} size={70} />
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            align: 'left',
            render: (text: any) => <b style={{ color: 'blue' }}>{text}</b>
        },
        {
            title: 'Point',
            dataIndex: 'point',
            key: 'point',
            render: (text: any) => text
        },
        {
            title: 'StockQty',
            dataIndex: 'qty',
            key: 'qty',
            render: (text: any) => text
        },
        {
            title: 'Enable Invenstory Control',
            dataIndex: 'control',
            key: 'control',
            render: (text: any) => text
        },
    ];
    return columns
}

export const createBoughtTogetherRows = (rows: ItemSet[]) => {
    let i = 0
    return rows.length > 0 ?
        rows.map(row => {
            i++;
            return {
                key: i,
                name: row.subItemName,
                photo: row.subItemPhoto,
                count: row.count || 0,
                lastactive: new Date(row.updatedAt).toDateString(),
            }
        }) :
        []
}
export const createBoughtTogetherColumn = () => {
    const columns = [
        {
            title: 'Photo',
            width: 100,
            key: 'photo',
            fixed: 'left',
            render: (v: { photo: string }) => < Avatar src={v.photo} size={70} />
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            align: 'left',
            render: (text: any) => <b style={{ color: 'blue' }}>{text}</b>
        },
        {
            title: 'Count',
            dataIndex: 'count',
            key: 'count',
            render: (text: any) => text
        },
        {
            title: 'LastAcitve',
            dataIndex: 'lastactive',
            key: 'lastactive',
            render: (text: any) => text
        },

    ];
    return columns
}

export const createBoughtCustomerRows = (rows: SubItemCustomer[]) => {
    const distincts = [...Array.from(new Set(rows.map(r => r.phone)))]
    const results: Array<{ name: string, count: number, phone: string }> = []
    distincts.forEach(phone => {
        let total = 0, name = ''
        const filters = rows.filter(p => p.phone === phone)
        if (filters.length > 1) {
            name = filters[0].name
        }
        filters.map(p => total += p.count)
        results.push({ name, count: total, phone })
    })
    let i = 0
    return results.map(row => {
        i++;
        return {
            key: i,
            name: row.name,
            phone: row.phone,
            count: row.count || 0,
        }
    })

}
export const createBoughtCustomerColumns = () => {
    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            align: 'left',
            render: (text: any) => <b style={{ color: 'blue' }}>{text}</b>
        },
        {
            title: 'Count',
            dataIndex: 'count',
            key: 'count',
            render: (text: any) => text
        },
        {
            title: 'Phone',
            dataIndex: 'phone',
            key: 'phone',
            render: (text: any) => text
        },

    ];
    return columns
}

export const lineChartConfig = (orders: Order[]) => {
    orders.map((order, index) => orders[index] = { ...order, date: moment(order.createdOn).format('LL') })
    orders = _.orderBy(orders, 'createdOn', 'asc')
    const distincts = orders.map(p => p.date)
    const distinctPointDate = [...Array.from(new Set(distincts))]
    var data = Array(distinctPointDate.length).fill(2).map(p => Array(2).fill(2).map(p => p))
    distinctPointDate.forEach((pdate, index) => {
        var totalCount = 0
        orders.filter(p => p.date === pdate).map(p => totalCount += 1)
        data[index][0] = pdate
        data[index][1] = totalCount
    })
    // console.log('line chart data:', data)
    const config = {
        chart: {
            type: 'spline'
        },
        title: {
            text: 'Product Order Trend'
        },
        credits: {
            enabled: false
        },
        yAxis: {
            title: {
                text: 'product order trends',
                color: 'blue'
            },
        },
        xAxis: {
            visible: false,
        },
        series: [
            {
                name: 'Orders',
                data: data,
                showInLegend: false,
            }
        ]
    };
    return config
}
