import * as React from 'react'
import { Empty } from 'antd'


export const EmptyBox = (props: any) => {
   // console.log('emptybox', props)
    return (
        <Empty
            description={
                <span>
                    {props.description}
                </span>
            }
        >
        </Empty>
    )
}