import * as React from "react";
import 'antd/dist/antd.css';
import { Row, Col, Card, Avatar, Button } from "antd";
import { connect } from 'react-redux';
import { withRouter } from "react-router";
import * as Actions from '../redux/actions'
import BarChart from './Chart/chart'
import PanelBox from "../PanelBox/PanelBox";
import * as Colors from '../../color'
import moment from 'moment'
import DateRange from '../../report/Member/component/dateRange'
//import { HighTreeMapChart ,treeMapConfig} from './Chart'
import DataTable from './Table/Table'
import { HighPieChart } from '../HighCharts'
import { BarChartConfig, pieConfig } from "./Chart";
import { getRewards, getRewardSummary } from './model'
const dateFormat = 'YYYY/MM/DD';
class RewardChart extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            hasInit: false,
            loading: false,
            rewardData: [],
            sdate: moment().startOf('month'),
            tdate: moment().endOf('month'),
        }
        this.init = this.init.bind(this)
        this.onOk = this.onOk.bind(this)
        this.onClear = this.onClear.bind(this)
        this.onhandleChange = this.onhandleChange.bind(this)
        this.onpresetController = this.onpresetController.bind(this)
    }
    onhandleChange(m, st) {
        this.setState({
            sdate: moment(st[0]),
            tdate: moment(st[1])
        })
    }
    onpresetController(m, st) {
        const { rewardData } = this.props
        this.setState({
            sdate: st[0],
            tdate: st[1],
            rewardData: getRewards({ rewards: rewardData, from: moment(st[0]).unix() * 1000, to: moment(st[1]).unix() * 1000 }),
        })
    }
    onOk() {
        const { sdate, tdate } = this.state
        const { rewardData } = this.props
        this.setState({
            rewardData: getRewards({ rewards: rewardData, from: moment(sdate).unix() * 1000, to: moment(tdate).unix() * 1000 })
        })
    }
    onClear() {
        const { rewardData } = this.props
        this.setState({
            rewardData: rewardData
        })
    }
    init(props) {
        const { fetchedMember, getAllMember, merchantId } = props
        const { fetchedReward, getAllReward } = props
        const { hasInit } = this.state
        if (!fetchedReward) getAllReward(merchantId)
        if (!fetchedMember) getAllMember(merchantId)
        if (fetchedReward && !hasInit) {
            const { sdate, tdate } = this.state
            const { rewardData } = props
            this.setState({
                hasInit: true,
                rewardData: getRewards({ rewards: rewardData, from: moment(sdate).unix() * 1000, to: moment(tdate).unix() * 1000 })
            })
        }
    }
    componentDidMount() {
        this.init(this.props)
    }
    componentWillReceiveProps(nextProps) {
        this.init(nextProps)
    }
    render() {
        const { totalClaimed, totalReward, totalRedeem } = getRewardSummary({ rewards: this.state.rewardData })
        const { fetchedMember, fetchedReward } = this.props
        const loading = fetchedMember && fetchedReward ? true : false
        return (
            <div className='reward'>
                <div >
                    <Row gutter={24}>
                        <Col span={24}>
                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <div >
                                    <DateRange
                                        format={dateFormat}
                                        defaultValue={[this.state.sdate, this.state.tdate]}
                                        onChange={(s, t) => this.onhandleChange(s, t)}
                                        onOk={() => this.onOk()}
                                        size={'small'}
                                        footerController={(m, st) => this.onpresetController(m, st)}
                                        showTime
                                    />
                                </div>
                                <div style={{ marginLeft: '10px', width: 'auto' }}>
                                    <Button icon='delete' onClick={() => this.onClear()} style={{ width: '100%' }} block>Clear Filter</Button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className='reward__bar' style={{ marginTop: '12px' }}>
                    <Row gutter={24} >
                        <Col span={8}>
                            <PanelBox className="card-item" loading={!loading}>
                                <div style={{ display: "flex", height: 50 }}>
                                    <div style={{ color: 'blue' }}>
                                        <Avatar icon="gift" size={64} style={{ color: 'black', backgroundColor: 'white' }} />
                                    </div>
                                    <div style={{ marginTop: '10px' }}>
                                        <ul>
                                            <li style={{ color: 'black', fontSize: 16 }}>Total Rewards</li>
                                            <li style={{
                                                color: Colors.LabelColor
                                            }}> {totalReward}</li>
                                        </ul>
                                    </div>
                                </div>
                            </PanelBox>
                        </Col>
                        <Col span={8}>
                            <PanelBox className="card-item" loading={!loading}>
                                <div style={{ display: "flex", height: 50 }}>
                                    <div style={{ color: 'blue' }}>
                                        <Avatar icon="shopping-cart" size={64} style={{ color: 'black', backgroundColor: 'white' }} />
                                    </div>
                                    <div style={{ marginTop: '10px' }}>
                                        <ul>
                                            <li style={{ color: 'black', fontSize: 16 }}>Total Claimed</li>
                                            <li style={{
                                                color: Colors.LabelColor
                                            }}> {totalClaimed}</li>
                                        </ul>
                                    </div>
                                </div>
                            </PanelBox>
                        </Col>
                        <Col span={8}>
                            <PanelBox className="card-item" loading={!loading}>
                                <div style={{ display: "flex", height: 50 }}>
                                    <div style={{ color: 'blue' }}>
                                        <Avatar icon="arrow-right" size={64} style={{ color: 'black', backgroundColor: 'white' }} />
                                    </div>
                                    <div style={{ marginTop: '10px' }}>
                                        <ul>
                                            <li style={{ color: 'black', fontSize: 16 }}>Total Redeem</li>
                                            <li style={{
                                                color: Colors.LabelColor
                                            }}> {totalRedeem}</li>
                                        </ul>
                                    </div>
                                </div>
                            </PanelBox>
                        </Col>

                    </Row>
                </div>
                <div className='customer___body'>
                    <Row gutter={24}>
                        <Col span={24}>
                            <Card
                                loading={!loading}
                                bordered={false}
                            >
                                <Row gutter={24} >
                                    <Col span={16}>
                                        <Card
                                            loading={!loading}
                                            bordered={false}
                                        >
                                            <BarChart config={BarChartConfig({ rewards: this.state.rewardData })} />
                                        </Card>
                                    </Col>
                                    <Col span={8}>
                                        <Card
                                            loading={!loading}
                                            bordered={false}
                                        >
                                            <HighPieChart config={pieConfig({ rewards: this.state.rewardData })} />
                                        </Card>
                                    </Col>
                                </Row>
                                <Row gutter={24} style={{ marginTop: '12px' }}>
                                    <Col span={24}>
                                        <Card
                                            loading={!loading}
                                            bordered={false}
                                        >
                                            <DataTable rewards={this.state.rewardData} />
                                        </Card>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>


                    {/* <Row gutter={24} style={{ marginTop: '12px' }}>
                        {
                            !loading ? <Card loading={true} /> :
                                rewardBarChartList({ rewards: rewardData }).map((config, index) => {
                                    return (
                                        <Col key={index.toString()} span={8} style={{ marginTop: '10px' }}>
                                            <Card>
                                                <BarChart config={config} />
                                            </Card>
                                        </Col>)
                                })
                        }
                    </Row> */}
                    {/* <Row gutter={24} style={{ marginTop: '12px' }}>
                        <Col span={24}>
                            <Card
                                loading={!loading}
                            >
                                <HighTreeMapChart config={treeMapConfig({ rewards: rewardData, members: memberData })} />
                            </Card>
                        </Col>
                    </Row> */}
                    {/* <Row gutter={24} style={{ marginTop: '12px' }}>
                        <Col span={24}>
                            <Card
                                loading={!loading}
                            >
                                <DataTable rewards={this.state.rewardData} />
                            </Card>
                        </Col>
                    </Row> */}
                </div>
            </div >

        )
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        getAllMember: (merchantId) => dispatch(Actions.GetAllMember(merchantId)),
        getAllReward: (merchantId) => dispatch(Actions.fetchRewardHistory(merchantId)),
    }
}
const mapStateToProps = state => {
    const { authState, reportState } = state
    const { fetchedReward, fetchedMember } = reportState
    const { reward } = reportState.history
    const [memberData] = [reportState.member.origin]
    const { origin } = reward
    return {
        memberData: memberData,
        rewardData: origin,
        fetchedMember: fetchedMember,
        fetchedReward: fetchedReward,
        merchantId: authState.status === 'loggedIn' && state.authState.userInfo ? authState.userInfo.merchantId : ''
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(RewardChart));