import * as React from "react";
import { Item } from "../state";
import { Avatar, Button } from "antd";
import * as  _ from 'lodash'
import ButtonGroup from 'antd/lib/button/button-group';
export const createRows = (rows: Item[] = []) => {
    let i = 0
    return rows.length > 0 ?
        rows.map(row => {
            i++;
            return {
                key: i,
                id: row.id,
                name: row.name,
                category: row.categoryName,
                photo: row.photo,
                date: new Date(row.createdOn).toDateString(),
                varitations: row.itemVariationIds,
            }
        }) :
        []
}


export const createColumns = (view: (id: string) => any) => {
    const columns = [
        {
            title: 'Photo',
            width: 100,
            key: 'photo',
            fixed: 'left',
            render: (v: { photo: string }) => < Avatar src={v.photo} size={70} />

        },
        // {
        //     title: 'Id',
        //     dataIndex: 'id',
        //     key: 'id',
        //     align: 'left',
        //     render: (text: any) => <b style={{ color: 'blue' }}>{text}</b>
        // },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (text: any) => text
        },
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            render: (text: any) => text
        },
        {
            title: 'Category',
            dataIndex: 'category',
            key: 'category',
            render: (text: any) => text
        },
        {
            title: 'Variatations',
            dataIndex: 'varitations',
            key: 'varitations',
            width: 250,
            render: (text: any) => text
        },
        {
            title: 'Action',
            key: 'action',
            align: 'center',
            width: '12%',
            fixed: 'right',
            render: (v: { id: string }) => {
                return (
                    <ButtonGroup>
                        <Button
                            icon="eye"
                            // tslint:disable-next-line:jsx-no-lambda
                            onClick={() => view(v.id)}
                        > detail </Button>
                    </ButtonGroup>
                );

            }
        }

    ];
    return columns
}

export const createCsvData = (rows: any[] = []) => {
    return rows.length > 0 ?
        rows.map(row => {

            return {
                name: row.name,
                // id: row.id,
                category: row.category,
                // photo: row.photo,
                date: row.date,
                varitaions: row.variat,
            }
        })
        : [];
}