import * as React from 'react'
import * as Colors from '../../../color'
import { List, Avatar, Button, Skeleton } from 'antd';
export default class LoadMoreList extends React.Component {
    render() {
        const { history, list } = this.props
        const showList = list.length > 4 ? list.slice(0, 4) : list
        const loadMore =
            (
                <div
                    style={{
                        textAlign: 'center',
                        marginTop: 12,
                        height: 10,
                        lineHeight: '20px',
                    }}
                >
                    <Button onClick={() => history.push('/member/all')}><b style={{ color: 'skyblue' }}>Show all members</b></Button>
                </div>
            )
        return (
            <List
                className="demo-loadmore-list"
                //  loading={initLoading}
                itemLayout="horizontal"
                loadMore={loadMore}
                dataSource={showList}
                renderItem={item => (
                    <List.Item
                    >
                        <Skeleton avatar title={false} loading={item.loading} active>
                            <List.Item.Meta
                                avatar={
                                    item.photo ?
                                        <Avatar src={item.photo} /> : <Avatar icon='user' style={{ color: 'black' }} />
                                }
                                title={<b style={{ color: Colors.MemberColor }}>{item.displayName} </b>}
                                description={item.email || item.phone}
                            />
                            <div><b style={{ color: 'black' }}>member</b></div>
                        </Skeleton>
                    </List.Item>
                )}
            />
        );
    }
}
