import * as React from 'react';
import { statusColors } from '../api';
export default class Title extends React.Component<{
  status: string;
}> {
  render() {
    const { status } = this.props;
    const istatus = status || 'default';
    return (
      <div className="ribbon">
        <span style={{ backgroundColor: statusColors[istatus].color }}>
          {istatus}
        </span>
      </div>
    );
  }
}
