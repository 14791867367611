import * as Type from './actionTypes'
import config from '../../config'
const report_url = process.env.REPORT_URL || config().config.Report.url

export const GetAllAuditLogs = (merchantId) => (dispatch) => {
    let _url = `${report_url}/getAuditLogs/${merchantId}`
    //console.log(`getall member url :${_url}`)
    fetch(_url, {
        crossDomain: true
    })
        .then(response => response.json())
        .then(data => {
          //  console.log("Get AuditLogs data", data)
            let _action = {}
            _action = { ..._action, type: Type.GET_ALL_AUDITLOGS, payload: [] }
            if (data.status !== 'Error' || data.data.length < 0) {
                _action = { ..._action, payload: data.data || [] }
            }
            dispatch(_action)
        });
}

export const fetchAuditByRange = (from, to) => dispatch => {
    const _action = {
        type: Type.ADD_AUDIT_FILTER_RANGE,
        payload: {
            from: from,
            to: to
        }
    }
    dispatch(_action)
}
export const fetchAuditByType = (auditType) => dispatch => {
    const _action = {
        type: Type.ADD_AUDIT_FILTER_TYPE,
        payload: {
            auditType: auditType,
        }
    }
    dispatch(_action)
}

export const clearAuditFiter = () => dispatch => {
    const _action = {
        type: Type.CLEAR_AUDIT_FILTER
    }
    dispatch(_action)
}



