import * as React from 'react'
import { Avatar, Button } from 'antd';
import ButtonGroup from 'antd/lib/button/button-group';
export const createRows = (rows = []) => {
    let i = 0
    return rows.length > 0 ?
        rows.map(row => {
            i++;
            return {
                key: i,
                name: row.displayName,
                phone: row.phone,
                profile: row.photo,
                jdate: new Date(row.joinedAt).toDateString(),
                tpoints: row.totalPoint,
                action: { id: row.id },
                cpoints: row.memberPoint,
                tperiod: row.pointInPeriod,
                rperiod: row.rewardInPeriod,
                pf: row.pF,
                forder: new Date(row.firstActive).toDateString(),
                lorder: new Date(row.lastActive).toDateString(),
                email: row.email,
            }
        }) :
        []
}
export const createColumns = ({ fn, onView }) => {
    const columns = [
        {
            title: 'Profile',
            key: 'profile',
            fixed: 'left',
            width: 100,
            render: ({ profile }) => profile ? <Avatar src={profile} size={70} /> : <Avatar icon='user' size={70} style={{ color: 'black' }} />,
        },
        {
            title: 'Full Name',
            width: 150,
            dataIndex: 'name',
            key: 'name',
            fixed: 'left',
            render: (text) => <b style={{ color: 'blue' }}>{text}</b>,
            ...fn,
        },
        {
            title: 'Phone Number',
            width: 150,
            dataIndex: 'phone',
            key: 'phone',
            // fixed: 'left',
        },

        {
            title: 'Email ',
            dataIndex: 'email',
            key: 'email',
            width: 180,
        },
        {
            title: 'Join Date',
            dataIndex: 'jdate',
            key: 'jdate',
            width: 150,
        },
        {
            title: 'Total Points',
            dataIndex: 'tpoints',
            key: '2',
            width: 150,
            sorter: (a, b) => a.tpoints - b.tpoints,
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: 'Total Points In Period',
            dataIndex: 'tperiod',
            key: 'tperiod',
            width: 150,
            sorter: (a, b) => a.tperiod - b.tperiod,
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: 'Total Rewards Claimed',
            dataIndex: 'rperiod',
            key: 'rperiod',
            width: 150,
            sorter: (a, b) => a.rperiod - b.rperiod,
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: 'Current Points',
            dataIndex: 'cpoints',
            key: '3',
            width: 150,
            sorter: (a, b) => a.cpoints - b.cpoints,
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: 'Engagement',
            dataIndex: 'pf',
            key: '4',
            width: 150,
            sorter: (a, b) => a.pf - b.pf,
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: 'First Date(given points)',
            dataIndex: 'forder',
            key: '5',
            width: 150,
        },
        {
            title: 'Last Date(given points)',
            dataIndex: 'lorder',
            key: '6',
            width: 150,
        },
        {
            title: 'Action',
            key: 'action',
            align: 'center',
            width: '12%',
            fixed: 'right',
            render: (text, { action }) => {
                return (
                    <ButtonGroup>
                        <Button
                            icon="eye"
                            // tslint:disable-next-line:jsx-no-lambda
                            onClick={() => { onView(action.id); }}
                        > view </Button>
                    </ButtonGroup>)

            }
        },
    ];
    return columns
}

export const createCsvData = (rows = []) => {
    return rows.length > 0 ?
        rows.map(row => {
            return {
                name: row.name,
                phoneNumber: row.phone,
                email: row.email,
                joinDate: row.jdate,
                totalPoint: row.tpoints,
                currentPoint: row.cpoints,
                Engagement: row.pf,
                FirstOrderDate: row.forder,
                LastOrderDate: row.lorder,
                totalPointInPeriod: row.tperiod,
                totalRewardClaimed: row.rperiod,
            }
        })
        : [];
}