import { connectedRouterRedirect } from 'redux-auth-wrapper/history4/redirect';
import { AppState } from './store';
import Progress from '../src/progress/progress'
export const userIsAuthenticated = connectedRouterRedirect({
  redirectPath: '/login',
  authenticatedSelector: ({ authState }: AppState) => {
    return authState && authState.status === 'loggedIn'
      ? !!authState.user
      : false;
  },
  authenticatingSelector: (state: AppState) =>
    state.authState && state.authState.status === 'loading',
  AuthenticatingComponent: Progress,
  wrapperDisplayName: 'UserIsAuthenticated',
});