import React, { Component } from 'react'
import 'antd/dist/antd.css';
import { Card, Table, Empty } from 'antd'
import './css.less'
import { connect } from 'react-redux';
import * as Actions from '../redux/actions'
import { ExportCSV } from '../excel/exportCSV'
import { Alert } from './component'
import { RiskModel } from './model'
const { createColumns, createCsvData, createRows } = RiskModel

const NoRedMemberBox = () => {
    return (
        <Empty
            description={
                <span>
                    Risk member not found
            </span>
            }
        >
        </Empty>
    )
}
class RiskMemberList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            fileName: "RiskMembers",
            loading: false,
            pending: false
        }
        this.init = this.init.bind(this)
        this.show = this.show.bind(this)
        this.onViewHandler = this.onViewHandler.bind(this)
    }
    componentDidMount() {
        this.init(this.props)
    }
    onViewHandler(memberId) {
        if (memberId !== undefined) {
            const { onView } = this.props
            onView(memberId)
        }
    }
    init(props) {
        const { fetchedMember, getAllMember, merchantId, memberData } = props
        const { fetchedRootPoint, getAllPoint, pointData } = props
        const { fetchedRiskMember, getRiskMember } = props
        const { fetchedRootPointCount, totalCount, getAllPointCount } = props
        if (!fetchedMember) getAllMember(merchantId)
        if (!fetchedRootPointCount) getAllPointCount(merchantId)
        if (fetchedRootPointCount && !fetchedRootPoint && !this.state.pending) {
            this.setState({
                pending: true
            }, () => {
                getAllPoint(merchantId, totalCount)
            })
        }

        if (fetchedMember && fetchedRootPoint && !fetchedRiskMember) {
            let newObj = { members: memberData, points: pointData }
            this.setState({
                loading: false,
            }, () => {
                getRiskMember({ ...newObj })
            })
        }
        if (fetchedRiskMember) {
            this.setState({
                loading: true
            })
        }
    }
    componentWillReceiveProps(nextProps) {
        this.init(nextProps)
    }
    show() {
        const { riskMemberData } = this.props
        if (riskMemberData.length > 0)
            return (
                <div>
                    <div className='active__member__bar'>
                        <h2 className="active__member__title"> Risk Members </h2>
                        <ExportCSV csvData={createCsvData(createRows(riskMemberData))} fileName={this.state.fileName} />
                    </div>
                    <Table
                        size='middle'
                        columns={createColumns({ onView: (memberId) => this.onViewHandler(memberId) })}
                        dataSource={createRows(riskMemberData)}
                        scroll={{ x: 1500 }} sticky
                    />
                </div>
            )
        else
            return <NoRedMemberBox />
    }
    render() {
        return (
            <div className='active__member'>
                <Alert message="Previous earn points more than 3 times in total ,who have not earn point anything with 90 days" />
                <Card
                    loading={!this.state.loading}
                >
                    {this.show()}
                </Card>
            </div>
        )
    }
}
const mapDispatchToProps = (dispatch, { history }) => {
    return {
        getAllMember: (merchantId) => dispatch(Actions.GetAllMember(merchantId)),
        onView: (memberId) => history.push(`/member/detail/${memberId}`),
        getAllPoint: (merchantId, totalCount) => dispatch(Actions.GetAllPoint(merchantId, totalCount)),
        getAllPointCount: (merchantId) => dispatch(Actions.GetAllTotalPointCount(merchantId)),
        getRiskMember: ({ members, points }) => dispatch(Actions.GetRiskMembers({ members, points }))
    }
}
const mapStateToProps = state => {
    const { authState, reportState } = state
    const { member, fetchedMember, fetchedRootPoint, root, fetchedRiskMember } = reportState
    const { totalCount, fetchedRootPointCount, point } = root
    const { origin, risk } = member
    return {
        pointData: point,
        memberData: origin,
        riskMemberData: risk,
        totalCount: totalCount,
        fetchedRootPointCount: fetchedRootPointCount,
        fetchedMember: fetchedMember,
        fetchedRootPoint: fetchedRootPoint,
        fetchedRiskMember: fetchedRiskMember,
        merchantId: authState.status === 'loggedIn' && state.authState.userInfo ? authState.userInfo.merchantId : ''
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(RiskMemberList)