import * as React from 'react'
import * as Colors from '../../../color'
import { Avatar, Button } from 'antd';
import ButtonGroup from 'antd/lib/button/button-group';
export const createRows = (rows = []) => {
    let i = 0
    return rows.length > 0 ?
        rows.map(row => {
            i++;
            return {
                key: i,
                name: row.displayName,
                phone: row.memberPhone,
                profile: row.memberPhoto,
                tpoints: row.earnPoints,
                cpoints: row.currentPoints,
                pf: row.earnEngages,
                action: { id: row.memberId },
                email: row.memberEmail,
                tname: row.tierName,
                tphoto: row.tierPhoto,
            }
        }) :
        []
}
export const createColumns = ({ fn, onView }) => {
    const columns = [
        {
            title: 'Tier',
            key: 'tphoto',
            fixed: 'left',
            render: ({ tphoto }) => <Avatar src={tphoto} size={75} shape='square' />
        },
        {
            title: 'Full Name',
            width: 150,
            dataIndex: 'name',
            key: 'name',
            fixed: 'left',
            render: (text) => <b style={{ color: Colors.MemberColor }}>{text}</b>,
            ...fn,
        },
        {
            title: 'Profile',
            key: 'profile',
            fixed: 'left',
            width: 100,
            render: ({ profile }) => profile ? <Avatar src={profile} size={70} /> : <Avatar icon='user' size={70} style={{ color: 'black' }} />,
        },
        {
            title: 'Phone Number',
            width: 150,
            dataIndex: 'phone',
            key: 'phone',
            // fixed: 'left',
        },

        {
            title: 'Email ',
            dataIndex: 'email',
            key: 'email',
            width: 180,
        },
        {
            title: 'Total Points',
            dataIndex: 'tpoints',
            key: '2',
            width: 150,
            sorter: (a, b) => a.tpoints - b.tpoints,
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: 'Current Points',
            dataIndex: 'cpoints',
            key: '3',
            width: 150,
            sorter: (a, b) => a.cpoints - b.cpoints,
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: 'Engagement',
            dataIndex: 'pf',
            key: '4',
            width: 150,
            sorter: (a, b) => a.pf - b.pf,
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: 'Tier Name',
            dataIndex: 'tname',
            key: '5',
        },
        {
            title: 'Action',
            key: 'action',
            align: 'center',
            width: '12%',
            fixed: 'right',
            render: (text, { action }) => {
                return (
                    <ButtonGroup>
                        <Button
                            icon="eye"
                            // tslint:disable-next-line:jsx-no-lambda
                            onClick={() => { onView(action.id); }}
                        > view </Button>
                    </ButtonGroup>)

            }
        }

    ];
    return columns
}

export const createCsvData = (rows = []) => {
    return rows.length > 0 ?
        rows.map(row => {
            return {
                name: row.name,
                phoneNumber: row.phone,
                email: row.email,
                totalPoint: row.tpoints,
                currentPoint: row.cpoints,
                Engagement: row.pf,
                TierName: row.tname,
            }
        })
        : [];
}

export const getDistinctTier = ({ tiers = [] }) => {
    const distinctTier = [...new Set(tiers.map(t => t.tierName))]
    return distinctTier
}
export const getTierByFilter = ({ tiers = [], filters = [] }) => {
    return tiers.filter(t => filters.includes(t.tierName))
}