import { createComponent, customMap } from 'redux-form-antd';
import { Input } from 'antd';

// tslint:disable-next-line:no-any
function mapFunction(mapProps: any, { input: { onChange } }: any) {
  return {
    ...mapProps,
    // tslint:disable-next-line:no-any
    onChange: (v: any) => onChange(v),
  };
}

const passwordFieldMap = customMap(mapFunction);

export default createComponent(Input.Password, passwordFieldMap);
