import firebase from 'firebase/app';
import * as React from 'react';
import { SubmissionError } from 'redux-form';
import { Subscription } from 'rxjs';
import UserNamePasswordForm, {
  FormData as UserNamePasswordFormData,
} from '../components/UserNamePasswordForm';

class LoginForm2 extends React.Component {
  subscription: Subscription;

  constructor(props: {}) {
    super(props);
    this.subscription = new Subscription();
  }

  login = async ({ merchantId, username, password }: UserNamePasswordFormData) => {
    const auth = firebase.functions().httpsCallable('authMerchantWeb');
    const result = await auth({ merchantId: merchantId, username: username, password: password });
    const authResult = result.data;
    if (authResult.success) {
      await firebase.auth().signInWithCustomToken(authResult.token);
    } else {
      throw new SubmissionError({ _error: 'invalid credentials' });
    }
  };

  render() {
    return (
      <div>
        <UserNamePasswordForm onSubmit={this.login} />
      </div>
    );
  }
}

export default LoginForm2;
