// product action types
export const ADD_PRODUCT = 'ADD_PRODUCT'



// order action types
export const ADD_ORDER = 'ADD_ORDER'


// varitiona action types
export const ADD_VARIATIONS = 'ADD_VARIATIONS'


// customer action types
export const ADD_CUSTOMER = 'ADD_CUSTOMER'
export const ADD_CUSTOMER_GP = 'ADD_CUSTOMER_GP'