import config from "../../config";
import * as ActionTypes from './ActionTypes'
import { Order } from "../state";
const pos_url = process.env.POS_URL || config().config.POS.url;
export const GetAllOrder = (merchantId: string) => (dispatch: any) => {
    let _url = `${pos_url}/getAllOrder/${merchantId}`
    fetch(_url, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
    })
        .then(response => response.json())
        .then(data => {
            let orders: Order[] = [];
            if (data.status !== 'Error' || data.data.length < 0) {
                orders = data.data as Order[] || []
            }
            dispatch({
                type: ActionTypes.ADD_ORDER,
                payload: { orders }
            });
        });
}