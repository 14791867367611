import * as React from 'react';
import { Button } from 'antd';
import { ButtonProps } from 'antd/lib/button';

export default class RemoveButton extends React.Component<
  { index: number; handler: (i: number) => void } & ButtonProps
> {
  _handleClick = () => {
    const { index, handler } = this.props;
    handler(index);
  };

  render() {
    const { index, handler, onClick, ...rest } = this.props;
    return (
      <Button
        type="danger"
        icon="minus"
        shape="circle"
        size="small"
        className="circle-delete-button"
        onClick={this._handleClick}
        {...rest}
      />
    );
  }
}
