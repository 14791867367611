import { createComponent, customMap } from 'redux-form-antd';
import { DatePicker } from 'antd';
const RangePicker = DatePicker.RangePicker;
// tslint:disable-next-line:no-any
function mapFunction(mapProps: any, { input: { onChange } }: any) {
  return {
    ...mapProps,
    // tslint:disable-next-line:no-any
    onChange: (v: any) => onChange(v),
  };
}

const rangePickerFieldMap = customMap(mapFunction);

export default createComponent(RangePicker, rangePickerFieldMap);
