import * as React from 'react';
import { Card, Table } from "antd";
import { RouteComponentProps, withRouter } from 'react-router';
import { connect } from 'react-redux';
import { GetAllProduct, GetAllVariation } from "./redux";
import { Item } from "./state";
import { ExportCSV } from "../report/excel/exportCSV";
import { ProductModel } from "./model";
import { McommerceState } from "./redux/state";
const { createColumns, createRows, createCsvData } = ProductModel
interface Props extends RouteComponentProps {
    getAllProduct: (id: string) => any;
    getAllVariation: (id: string) => any;
    onDetailView: (id: string) => any;
    fetchedProduct: boolean;
    fetchedVaritaion: boolean;
    merchantId: string;
    products: Item[];
}
class ProductList extends React.Component<Props> {
    constructor(props: Props) {
        super(props);
        this.state = { loading: 'loaded', data: [], };
    }
    componentDidMount() {
        const { merchantId, getAllProduct, fetchedProduct, getAllVariation, fetchedVaritaion } = this.props
        if (!fetchedProduct) getAllProduct(merchantId)
        if (!fetchedVaritaion) getAllVariation(merchantId)
    }
    render() {
        return (
            <Card title="Product List"
                extra={
                    <>
                        <ExportCSV csvData={createCsvData(createRows(this.props.products))} fileName={'Productlist'} />
                    </>
                }
                loading={!this.props.fetchedProduct && !this.props.fetchedVaritaion} >
                <Table
                    columns={createColumns((id) => this.props.onDetailView(id))}
                    dataSource={createRows(this.props.products)}
                />
            </Card>
        );
    }

}

const mapDispatchToProps = (dispatch: any, { history }: Props) => {
    return {
        onDetailView: (id: string) => history.push(`/mcommerce/detail/${id}`),
        getAllProduct: (merchantId: string) => dispatch(GetAllProduct(merchantId)),
        getAllVariation: (merchantId: string) => dispatch(GetAllVariation(merchantId))
    }
}
const mapStateToProps = (state: any) => {
    const { authState, mcommerceState: s } = state as { mcommerceState: McommerceState, authState: any }
    return {
        products: s.product.origin,
        fetchedProduct: s.fetchedProduct,
        fetchedVaritaion: s.fetchedVariation,
        merchantId: authState.status === 'loggedIn' && state.authState.userInfo ? authState.userInfo.merchantId : ''
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ProductList)) as any;
