export const BannaColor = '#ffdb5c'
export const CantelColor = '#f8a055'
export const WatermelonColor = '#fa6e59'
export const ElectricBlueColor = '#4897d8'
export const SkyBlueColor = '#27406e'
export const LabelColor = '#2988bc'
export const ColorOne = '#eb4034'
export const ColorTwo = '#611510'
export const ColorThree = '#fcba03'
export const BlackWhite = '#2f499e'
export const DarkColor ='#2f486e'
export const MemberColor = '#4091f7'
export const BlackColor = '#141413'

const Colors = [BlackWhite,CantelColor, WatermelonColor, ElectricBlueColor, BannaColor, SkyBlueColor, ColorThree, ColorOne, ColorTwo]
const RandomColor = () => Colors[Math.floor(Math.random() * Colors.length)]
export { RandomColor }