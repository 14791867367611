import * as React from 'react';
import { Icon, Tag } from 'antd';
import { statusColors } from '../api';

export default class Status extends React.Component<{
  status?: string;
  style?: React.CSSProperties;
}> {
  render() {
    const { status, style } = this.props;
    const istatus = status || 'default';

    return (
      <div className="status">
        <Tag color={statusColors[istatus].color} style={style}>
          <Icon
            type={statusColors[istatus].iconName}
            style={{ verticalAlign: 'middle', fontSize: '16px' }}
          />
          {status}
        </Tag>
      </div>
    );
  }
}
