import * as React from "react";
import 'antd/dist/antd.css';
import { Row, Col, Card, Button, Avatar, Table } from "antd";
import { connect } from 'react-redux';
import { RouteComponentProps } from "react-router";
import moment from 'moment'
import { StockChart } from "./Charts";
import PanelBox from "../Charts/PanelBox/PanelBox";
//import { PieChart } from "./Charts";
import { DateRange } from './components'
import ViewMore from './viewMoreOrder'
import { Order } from "./state";
import { GetAllOrder } from "./redux";

//import { pieConfig } from "./model";
import { getOrderSummary, orderDashboardColumns, orderDashboardRows, orderTrendConfig } from "./model";
import * as Colors from '../color'
interface Filter {
    from: number;
    to: number;
}
interface Props extends RouteComponentProps {
    getAllOrder: (id: string) => any;
    orders: Order[];
    fetchedOrder: boolean;
    merchantId: string;
}
interface Summary {
    orders: Order[];
    webCompleted: number;
    webCancelled: number;
    webPending: number;
    posCompleted: number;
    posCancelled: number;
    posPending: number;
    completed: number;
    pending: number;
    cancelled: number;
}
const defaultState = {
    data: [],
    pendingOrders: [],
    completedOrders: [],
    cancelledOrders: [],
    origin: [],
    filter: {
        from: moment().startOf('month').unix() * 1000,
        to: moment().endOf('month').unix() * 1000
    },
    summary: {
        webCompleted: 0,
        webCancelled: 0,
        webPending: 0,
        posCompleted: 0,
        posCancelled: 0,
        posPending: 0,
        completed: 0,
        pending: 0,
        cancelled: 0,
        orders: [],
    }
}
type S = |
{
    data: Order[],
    origin: Order[],
    filter: Filter,
    summary: Summary,
    pendingOrders: Order[],
    completedOrders: Order[],
    cancelledOrders: Order[]
}
class OrderDashboard extends React.Component<Props, S> {
    constructor(props: Props) {
        super(props)
        this.state = { ...defaultState }
        this.onOk = this.onOk.bind(this)
        this.onClear = this.onClear.bind(this)
        this.onhandleChange = this.onhandleChange.bind(this)
        this.onpresetController = this.onpresetController.bind(this)
        this.init = this.init.bind(this)
    }
    onhandleChange(m: any, st: any) {
        this.setState({
            ...this.state,
            filter: {
                from: moment(st[0]).unix() * 1000,
                to: moment(st[1]).unix() * 1000
            }
        })
    }
    onpresetController(m: any, st: any) {
        const from = moment(st[0]).unix() * 1000
        const to = moment(st[1]).unix() * 1000
        const { origin } = this.state
        const state = getOrderSummary(origin, { from, to })
        this.setState({
            ...this.state,
            data: state.orders,
            filter: {
                ...this.state.filter,
                from,
                to
            },
            pendingOrders: state.pendingOrders,
            cancelledOrders: state.cancelledOrders,
            completedOrders: state.completedOrders,
            summary: { ...state }
        })
    }
    onOk() {
        const { origin, filter } = this.state
        const state = getOrderSummary(origin, filter)
        this.setState({
            ...this.state,
            pendingOrders: state.pendingOrders,
            cancelledOrders: state.cancelledOrders,
            completedOrders: state.completedOrders,
            data: state.orders,
            summary: { ...state }
        })
    }
    onClear() {
        const { origin } = this.state
        const state = getOrderSummary(origin, { from: 0, to: 0 })
        this.setState({
            ...defaultState,
            origin: origin,
            pendingOrders: state.pendingOrders,
            cancelledOrders: state.cancelledOrders,
            completedOrders: state.completedOrders,
            data: state.orders,
            summary: { ...state },
        })
    }
    init(props: Props) {
        const { fetchedOrder, getAllOrder, merchantId, orders } = props
        if (!fetchedOrder) getAllOrder(merchantId)
        if (fetchedOrder) {
            const state = getOrderSummary(orders, this.state.filter)
            this.setState({
                ...this.state,
                pendingOrders: state.pendingOrders,
                cancelledOrders: state.cancelledOrders,
                completedOrders: state.completedOrders,
                data: state.orders,
                origin: orders,
                summary: { ...state }
            })
        }
    }
    componentDidMount() {
        this.init(this.props)
    }
    componentWillReceiveProps(nextProps: Props) {
        this.init(nextProps)
    }
    render() {
        const { summary, cancelledOrders, completedOrders, pendingOrders } = this.state
        return (
            <div className='sale'>
                <div >
                    <Row gutter={24}>
                        <Col span={24}>
                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <div >
                                    <DateRange
                                        className='sale_date_range'
                                        defaultValue={[moment(this.state.filter.from), moment(this.state.filter.to)]}
                                        onChange={(s, t) => this.onhandleChange(s, t)}
                                        onOk={() => this.onOk()}
                                        footerController={(m, st) => this.onpresetController(m, st)}
                                    />
                                </div>
                                <div style={{ marginLeft: '10px', width: 'auto' }}>
                                    <Button icon='delete' onClick={() => this.onClear()} style={{ width: '100%' }} block>Clear Filter</Button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className='customer___body' style={{ marginTop: 12 }}>
                    <Row gutter={24} >
                        <Col span={24}>
                            <Card
                                loading={!this.props.fetchedOrder}
                                style={{ border: 'none' }}
                            >
                                <Row gutter={24}>
                                    <Col span={8}>
                                        {/* <Card
                                            loading={!this.props.fetchedOrder}
                                        > */}
                                        <Row>
                                            <Col span={12}>
                                                <PanelBox className="card-item" loading={!this.props.fetchedOrder}>
                                                    <div style={{ display: "block", height: 50 }}>
                                                        <div style={{ textAlign: 'center' }}>
                                                            <Avatar icon="calendar"
                                                                size={60}
                                                                style={{
                                                                    color: 'black',
                                                                    backgroundColor: 'white'
                                                                }} />
                                                        </div>
                                                        <div style={{ textAlign: 'center' }}>
                                                            <b style={{
                                                                color: Colors.LabelColor,
                                                                marginLeft: 5
                                                            }}>{moment(this.state.filter.from).format('DD/MM/YYYY')}</b>
                                                        </div>
                                                        <div style={{ textAlign: 'center' }}>
                                                            <span>
                                                                <b style={{ color: 'black', fontWeight: 'bold' }}>
                                                                    From
                                                        </b>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </PanelBox>
                                            </Col>
                                            <Col span={12}>
                                                <PanelBox className="card-item" loading={!this.props.fetchedOrder}>
                                                    <div style={{ display: "block", height: 50 }}>
                                                        <div style={{ color: 'blue', textAlign: 'center' }}>
                                                            <Avatar icon="calendar"
                                                                size={60}
                                                                style={{
                                                                    color: 'black',
                                                                    backgroundColor: 'white'
                                                                }} />
                                                        </div>
                                                        <div style={{ textAlign: 'center' }}>
                                                            <b style={{
                                                                color: Colors.LabelColor,
                                                                marginLeft: 5
                                                            }}> {moment(this.state.filter.to).format('DD/MM/YYYY')} </b>
                                                        </div>
                                                        <div style={{ textAlign: 'center' }}>
                                                            <span>
                                                                <b style={{ color: 'black', fontWeight: 'bold' }}>
                                                                    To
                                                        </b>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </PanelBox>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col span={12}>
                                                <PanelBox className="card-item" loading={!this.props.fetchedOrder}>
                                                    <div style={{ display: "block", height: 50 }}>
                                                        <div style={{ color: 'blue', textAlign: 'center' }}>
                                                            <Avatar icon="check-circle"
                                                                size={60}
                                                                style={{
                                                                    color: 'black',
                                                                    backgroundColor: 'white'
                                                                }} />
                                                        </div>
                                                        <div style={{ textAlign: 'center' }}>
                                                            <span>

                                                                <b style={{
                                                                    color: Colors.LabelColor,
                                                                    marginLeft: 5
                                                                }}>{summary.completed}</b>
                                                            </span>
                                                        </div>
                                                        <div style={{ textAlign: 'center' }}>
                                                            <b style={{ color: 'black', fontWeight: 'bold' }}>
                                                                Completed
                                                        </b>
                                                        </div>
                                                    </div>
                                                </PanelBox>
                                            </Col>
                                            <Col span={12}>
                                                <PanelBox className="card-item" loading={!this.props.fetchedOrder}>
                                                    <div style={{ display: "block", height: 50 }}>
                                                        <div style={{ color: 'blue', textAlign: 'center' }}>
                                                            <Avatar icon="close-circle"
                                                                size={60}
                                                                style={{
                                                                    color: 'black',
                                                                    backgroundColor: 'white'
                                                                }} />
                                                        </div>
                                                        <div style={{ textAlign: 'center' }}>
                                                            <b style={{
                                                                color: Colors.LabelColor,
                                                                marginLeft: 5
                                                            }}>{summary.cancelled}  </b>
                                                        </div>
                                                        <div style={{ textAlign: 'center' }}>
                                                            <span>
                                                                <b style={{ color: 'black', fontWeight: 'bold' }}>
                                                                    Cancelled
                                                        </b>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </PanelBox>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col span={12}>
                                                <PanelBox className="card-item" loading={!this.props.fetchedOrder}>
                                                    <div style={{ display: "block", height: 50 }}>
                                                        <div style={{ color: 'blue', textAlign: 'center' }}>
                                                            <Avatar icon="clock-circle-o"
                                                                size={60}
                                                                style={{
                                                                    color: 'black',
                                                                    backgroundColor: 'white'
                                                                }} />
                                                        </div>
                                                        <div style={{ textAlign: 'center' }}>
                                                            <b style={{
                                                                color: Colors.LabelColor,
                                                                marginLeft: 5
                                                            }}> {summary.pending} </b>
                                                        </div>
                                                        <div style={{ textAlign: 'center' }}>
                                                            <span>
                                                                <b style={{ color: 'black', fontWeight: 'bold' }}>
                                                                    Pending
                                                        </b>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </PanelBox>
                                            </Col>
                                            <Col span={12}>
                                                <PanelBox className="card-item" loading={!this.props.fetchedOrder}>
                                                    <div style={{ display: "block", height: 50 }}>
                                                        <div style={{ color: 'blue', textAlign: 'center' }}>
                                                            <Avatar icon="file"
                                                                size={60}
                                                                style={{
                                                                    color: 'black',
                                                                    backgroundColor: 'white'
                                                                }} />
                                                        </div>
                                                        <div style={{ textAlign: 'center' }}>
                                                            <b style={{
                                                                color: Colors.LabelColor,
                                                                marginLeft: 5
                                                            }}> {summary.orders.length} </b>
                                                        </div>
                                                        <div style={{ textAlign: 'center' }}>
                                                            <span>
                                                                <b style={{ color: 'black', fontWeight: 'bold' }}>
                                                                    Orders
                                                        </b>

                                                            </span>
                                                        </div>
                                                    </div>
                                                </PanelBox>
                                            </Col>
                                        </Row>
                                        {/* </Card> */}
                                    </Col>
                                    <Col span={16}>
                                        {/* <PieChart config={pieConfig(summary)} /> */}
                                        <StockChart config={orderTrendConfig(pendingOrders, completedOrders, cancelledOrders)} />

                                    </Col>
                                </Row>
                            </Card>
                        </Col>


                    </Row>
                    <Row gutter={24} style={{ marginTop: '12px' }}>
                        <Col span={24}>
                            <Card
                                loading={!this.props.fetchedOrder}
                            >
                                <Row gutter={24}>
                                    <Col span={24}>
                                        <Table
                                            bordered={false}
                                            size='middle'
                                            dataSource={orderDashboardRows(summary)}
                                            columns={orderDashboardColumns()}
                                            pagination={false}
                                        />
                                    </Col>
                                    {/* <Col span={18}>
                                        <ViewMore {...this.props} />
                                    </Col> */}
                                </Row>
                                <Row gutter={24} style={{ marginTop: '12px' }}>
                                    <Col span={24}>
                                        <ViewMore {...this.props} />
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>

                </div>
            </div >
        )
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        getAllOrder: (id: string) => dispatch(GetAllOrder(id)),
    }
}
const mapStateToProps = (state: any) => {
    const { authState, mcommerceState } = state
    const { fetchedOrder, order } = mcommerceState
    const { origin } = order
    return {
        orders: origin,
        fetchedOrder,
        merchantId: authState.status === 'loggedIn' && state.authState.userInfo ? authState.userInfo.merchantId : ''
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(OrderDashboard);