import moment from 'moment';
import { defer, from, Observable, of } from 'rxjs';
import { flatMap, map } from 'rxjs/operators';

export const baseURL = process.env.REACT_APP_API_URL;

// tslint:disable-next-line:no-any
function toQueryString(params: any) {
  return Object.keys(params)
    .filter((k) => params[k])
    .map(
      (key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`
    )
    .join('&');
}

const iso8601Regex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d{3})?Z$/;
// tslint:disable-next-line
const reviver = (key: any, value: any) => {
  if (typeof value === 'string' && iso8601Regex.test(value)) {
    return moment(value);
  } else {
    return value;
  }
};

export const fetchJSON$ = <T>(
  url: string,
  payload: T,
  method: string = 'POST'
) => {
  const headers = {};
  let rURL = baseURL + url;
  if (method === 'GET') {
    rURL += `?${toQueryString(payload)}`;
  }
  return from(
    fetch(rURL, {
      method,
      headers,
      body:
        method === 'POST' || method === 'PUT'
          ? JSON.stringify(payload)
          : undefined,
    })
  ).pipe(
    flatMap((resp) => {
      if (resp.status >= 200 && resp.status < 300) {
        return resp.text().then((text) => {
          return text ? JSON.parse(text, reviver) : undefined;
        });
      } else if (resp.status === 404) {
        return defer(() =>
          of({
            status: '404',
          })
        );
      } else {
        return from(resp.text()).pipe(
          map((err) => ({
            status: 'error',
            error: err,
          }))
        );
      }
    })
  );
};

export const imgPath$ = (key: string): Observable<string> =>
  fetchJSON$('/downloadURL', { key }, 'POST').pipe(map((r) => r.location));

export const statusColors = {
  Draft: {
    color: 'blue',
    iconName: 'save',
  },
  Submitted: {
    color: '#108ee9',
    iconName: 'solution',
  },
  Resubmit: {
    color: '#f50',
    iconName: 'exception',
  },
  Processing: {
    color: '#ff9900',
    iconName: 'file-sync',
  },
  Recommended: {
    color: '#33aa33',
    iconName: 'file-done',
  },
  default: {
    color: '#2db7f5',
    iconName: 'solution',
  },
};
