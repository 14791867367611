import React, { Component } from 'react'
import 'antd/dist/antd.css';
import { Card, Table } from 'antd'
import moment from 'moment'
import './css.less'
import { connect } from 'react-redux';
import * as Actions from '../redux/actions'
import { ExportCSV } from '../excel/exportCSV'
import { Alert, DateRange } from './component'
import { InactiveModel } from './model'
const dateFormat = 'YYYY/MM/DD';
const { createColumns, createCsvData, createRows } = InactiveModel
class InactiveMemberList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            sdate: moment().subtract(1, 'month'),
            tdate: moment(),
            hasInit: false,
            loading: false,
            pending: false,
            fileName: "InactiveMembers_last_one_month"

        }
        this.onOk = this.onOk.bind(this)
        this.onhandleChange = this.onhandleChange.bind(this)
        this.init = this.init.bind(this)
        this.getFileName = this.getFileName.bind(this)
        this.onViewHandler = this.onViewHandler.bind(this)
        this.onpresetController = this.onpresetController.bind(this)
    }
    init(props) {
        console.log('inactive init calling', props)
        const { fetchedMember, getAllMember, merchantId, memberData } = props
        const { fetchedRootPoint, getAllPoint, pointData } = props
        const { fetchedInactiveMember, getInactiveMember, inactiveMemberData } = props
        const { fetchedReward, rewardData, getAllReward } = props
        const { fetchedRootPointCount, totalCount, getAllPointCount } = props
        if (!fetchedReward) getAllReward(merchantId)
        if (!fetchedMember) getAllMember(merchantId)
        if (!fetchedRootPointCount) getAllPointCount(merchantId)
        if (fetchedRootPointCount && !fetchedRootPoint && !this.state.pending) {
            this.setState({
                pending: true
            }, () => {
                getAllPoint(merchantId, totalCount)
            })
        }
        if (fetchedReward && fetchedMember && fetchedRootPoint) {
            const { sdate, tdate, hasInit } = this.state
            if (!hasInit && inactiveMemberData.length <= 0 && !fetchedInactiveMember) {
                let newObj = { members: memberData, points: pointData, rewards: rewardData, from: moment(sdate).unix() * 1000, to: moment(tdate).unix() * 1000 }
                this.setState({
                    hasInit: true,
                })
                getInactiveMember({ ...newObj })
            }
            if (fetchedInactiveMember) {
                this.setState({
                    loading: true
                })
            }
        }
    }
    onpresetController(m, st) {
        const { memberData, pointData, rewardData, getInactiveMember } = this.props
        this.setState({
            sdate: st[0],
            tdate: st[1],
            fileName: this.getFileName(st[0], st[1])
        }, () => {
            let newObj = { members: memberData, points: pointData, rewards: rewardData, from: moment(this.state.sdate).unix() * 1000, to: moment(this.state.tdate).unix() * 1000 }
            getInactiveMember({ ...newObj })
        })
    }
    componentDidMount() {
        this.init(this.props)
    }
    componentWillReceiveProps(nextProps) {
        this.init(nextProps)
    }
    onViewHandler(memberId) {
        if (memberId !== undefined) {
            const { onView } = this.props
            onView(memberId)
        }
    }
    onhandleChange(m, st) {
        this.setState({
            sdate: moment(st[0]),
            tdate: moment(st[1])
        })
    }
    onOk() {
        const { memberData, pointData, rewardData, getInactiveMember } = this.props
        let { sdate, tdate } = this.state
        let newObj = { members: memberData, points: pointData, rewards: rewardData, from: moment(sdate).unix() * 1000, to: moment(tdate).unix() * 1000 }
        this.setState({
            loading: false,
            fileName: this.getFileName(sdate, tdate)
        }, () => {
            getInactiveMember({ ...newObj })
        })
    }
    getFileName(sdate, tdate) {
        let from = moment(sdate).format(dateFormat).toString()
        let to = moment(tdate).format(dateFormat).toString()
        return "InactiveMembers_" + from + "_" + to
    }
    render() {
        const { inactiveMemberData } = this.props
        return (
            <div className='active__member'>
                <Alert message="Member earn no points in given period" />
                <Card
                    loading={!this.state.loading}
                >
                    <div className='active__member__bar'>
                        <h2 className="active__member__title"> Inactive Members </h2>
                        <DateRange
                            className='active_member__date'
                            format={dateFormat}
                            defaultValue={[this.state.sdate, this.state.tdate]}
                            onChange={(s, t) => this.onhandleChange(s, t)}
                            onOk={(e) => this.onOk(e)}
                            footerController={(m, st) => this.onpresetController(m, st)}
                            showTime
                        />
                        <ExportCSV csvData={createCsvData(createRows(inactiveMemberData))} fileName={this.state.fileName} />
                    </div>
                    <Table
                        size='middle'
                        columns={createColumns({ onView: (memberId) => this.onViewHandler(memberId) })}
                        dataSource={createRows(inactiveMemberData)}
                        scroll={{ x: 1500 }} sticky
                    />
                </Card>
            </div>
        )
    }
}
const mapDispatchToProps = (dispatch, { history }) => {
    return {
        getAllMember: (merchantId) => dispatch(Actions.GetAllMember(merchantId)),
        onView: (memberId) => history.push(`/member/detail/${memberId}`),
        getAllPoint: (merchantId, totalCount) => dispatch(Actions.GetAllPoint(merchantId, totalCount)),
        getAllReward: (merchantId) => dispatch(Actions.fetchRewardHistory(merchantId)),
        getAllPointCount: (merchantId) => dispatch(Actions.GetAllTotalPointCount(merchantId)),
        getInactiveMember: ({ members, points, rewards, from, to }) => dispatch(Actions.GetInactiveMembers({ members, points, rewards, from, to }))
    }
}
const mapStateToProps = state => {
    const { authState, reportState } = state
    const { member, fetchedMember, fetchedRootPoint, fetchedInactiveMember, root } = reportState
    const { totalCount, fetchedRootPointCount, point } = root
    const { fetchedReward, history } = reportState
    const { reward } = history
    const [rewardData] = [reward.origin]
    const { origin, inactive } = member
    return {
        pointData: point,
        memberData: origin,
        inactiveMemberData: inactive,
        totalCount: totalCount,
        rewardData: rewardData,
        fetchedMember: fetchedMember,
        fetchedReward: fetchedReward,
        fetchedRootPoint: fetchedRootPoint,
        fetchedRootPointCount: fetchedRootPointCount,
        fetchedInactiveMember: fetchedInactiveMember,
        merchantId: authState.status === 'loggedIn' && state.authState.userInfo ? authState.userInfo.merchantId : ''
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(InactiveMemberList)