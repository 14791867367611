import React, { Component } from 'react'
import moment from 'moment'
import 'antd/dist/antd.css';
import { Button, Card, Table } from 'antd'
import '../css.less'
import { connect } from 'react-redux';
import * as _ from 'lodash'
import * as Actions from '../redux/actions'
import { DateRange } from '../Member/component'
import { ExportCSV } from '../excel/exportCSV'
import { PointModel } from './model'
const { createRows, crateColummns, createCsvData } = PointModel
const dateFormat = 'YYYY/MM/DD';
class PointHistory extends Component {
    constructor(props) {
       // console.log('render init')
        super(props);
        this.state = {
            sdate: moment(),
            tdate: moment(),
            pending: false,
            loading: false,
        }
        this.onhandleChange = this.onhandleChange.bind(this)
        this.onOk = this.onOk.bind(this)
        this.onFilterChange = this.onFilterChange.bind(this)
        this.init = this.init.bind(this)
        this.renderDate = this.renderDate.bind(this)
        this.onpresetController = this.onpresetController.bind(this)
    }
    init(props) {
        const { fetchedMember, getAllMember, merchantId, memberData } = props
        const { fetchedRootPointCount, totalCount, getAllPointCount } = props
        const { fetchedRootPoint, getAllPoint, pointData } = props
        const { fetchedPoint, getHistoryPoint } = props
        if (!fetchedMember) getAllMember(merchantId)
        if (!fetchedRootPointCount) getAllPointCount(merchantId)
        if (fetchedRootPointCount && !fetchedRootPoint && !this.state.pending) {
            this.setState({
                pending: true
            }, () => {
                getAllPoint(merchantId, totalCount)
            })
        }
        if (fetchedRootPoint && fetchedMember && !fetchedPoint) {
            let newObj = { members: memberData, points: pointData }
            this.setState({
                loading: false
            }, () => {
                getHistoryPoint({ ...newObj })
            })
        }
        if (fetchedRootPoint) {
            this.setState({
                loading: true
            })
        }
    }
    onhandleChange(m, st) {
        //  this.props.pointFilterRange(moment(st[0]).unix() * 1000, moment(st[1]).unix() * 1000)
        this.setState({
            sdate: st[0],
            tdate: st[1]
        })
    }
    onpresetController(m, st) {
        const { pointFilterRange } = this.props
        this.setState({
            sdate: st[0],
            tdate: st[1],
        }, () => {
            pointFilterRange(moment(this.state.sdate).unix() * 1000, moment(this.state.tdate).unix() * 1000)
        })
    }
    onOk() {
        this.props.pointFilterRange(moment(this.state.sdate).unix() * 1000, moment(this.state.tdate).unix() * 1000)
    }
    onFilterChange(filters) {
        if (filters.length > 0) {
            this.props.pointFilterType(filters)
        }
    }
    componentDidMount() {
        // if (!this.props.fetchedPoint) {
        //     this.props.fetchPoint(this.props.merchantId)
        // }
        this.init(this.props)
    }
    componentWillReceiveProps(nextProps) {
        this.init(nextProps)
    }
    renderDate() {
        return (
            <DateRange
                style={{ color: 'black' }}
                className='history__date'
                format={dateFormat}
                defaultValue={[this.state.sdate, this.state.tdate]}
                onChange={(s, t) => this.onhandleChange(s, t)}
                onOk={() => this.onOk()}
                footerController={(m, st) => this.onpresetController(m, st)}
                showTime
            />
        )
    }
    show() {
       // console.log('this.state', this.state)
        return (<div>
            <div className='history__bar'>
                <h2 className="history__title"> Point </h2>
                {this.renderDate()}
                {/* <Popover content={<FilterBox handleSumbit={this.onFilterChange} options={['point-one', 'point-two', 'point-three']} />}>
                        <Button icon='filter' className='history__filter'>
                            Filter
                    </Button>
                    </Popover> */}
                <Button icon='delete' className="history__filter__clear" onClick={() => { this.props.pointFilterClear() }}> Clear Filter </Button>
                <ExportCSV csvData={createCsvData(createRows(this.props.data))} fileName='point' />
            </div>
            <Table
                size='middle'
                columns={crateColummns()}
                dataSource={createRows(this.props.data)}
                scroll={{ x: 1500 }} sticky
            />
        </div>)
    }
    render() {
        return (
            <Card
                loading={!this.state.loading}
            >
                {this.show()}
            </Card>
        )
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        getAllMember: (merchantId) => dispatch(Actions.GetAllMember(merchantId)),
        getAllPointCount: (merchantId) => dispatch(Actions.GetAllTotalPointCount(merchantId)),
        getAllPoint: (merchantId, totalCount) => dispatch(Actions.GetAllPoint(merchantId, totalCount)),
        //   fetchPoint: (merchantId) => dispatch(Actions.fetchPointHistory(merchantId)),
        getHistoryPoint: ({ members, points }) => dispatch(Actions.GetPointHistory({ members, points })),
        pointFilterRange: (from, to) => dispatch(Actions.addPointFilterRange(from, to)),
        pointFilterClear: () => dispatch(Actions.clearPointFilter()),
        pointFilterType: (filterType) => dispatch(Actions.addPointFilterType(filterType ? filterType : []))
    }
}
const mapStateToProps = state => {
    const { reportState, authState } = state
    const { member, fetchedMember, fetchedRootPoint, root } = reportState
    const { totalCount, fetchedRootPointCount } = root
    const { origin } = member
    const [rootPoint] = [root.point]
    const { point } = reportState.history
    const { temp } = point
    return {
        pointData: rootPoint,
        data: _.orderBy(temp, 'createdAt', 'desc'),
        memberData: origin,
        totalCount: totalCount,
        fetchedRootPointCount: fetchedRootPointCount,
        fetchedMember: fetchedMember,
        fetchedRootPoint: fetchedRootPoint,
        fetchedPoint: reportState.fetchedPoint | false,
        merchantId: authState.status === 'loggedIn' && state.authState.userInfo ? authState.userInfo.merchantId : ''
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(PointHistory)