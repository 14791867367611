import * as React from 'react';
import moment from "moment";
import * as Colors from '../../color';
import { Order } from "../state";
import * as _ from 'lodash';
interface Filter {
    from: number;
    to: number;
}
interface Summary {
    orders: Order[];
    webCompleted: number;
    webCancelled: number;
    webPending: number;
    posCompleted: number;
    posCancelled: number;
    posPending: number;
    completed: number;
    pending: number;
    cancelled: number;
}
const Completed = 'Completed';
const Cancelled = 'Cancelled';
const Pending = 'Pending';
export const pieConfig = (s: Summary) => {
    const pieSerie = [{
        name: 'Total',
        colorByPoint: true,
        data: [{
            name: 'Orders',
            y: s.orders.length,
            color: Colors.BannaColor,
            sliced: true,
            selected: true
        }, {
            name: ' Pending',
            color: Colors.WatermelonColor,
            y: s.pending
        }, {
            name: 'Cancelled',
            color: Colors.ColorTwo,
            y: s.cancelled
        }, {
            name: 'Completed',
            color: Colors.CantelColor,
            y: s.completed
        }]
    }]
    const config = {
        chart: {
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            type: 'pie',
            defaultSeriesType: 'areaspline'
        },
        title: {
            text: 'Order Summary'
        },
        tooltip: {
            pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
        },
        accessibility: {
            point: {
                valueSuffix: '%'
            }
        },
        credits: {
            enabled: false
        },
        plotOptions: {
            pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                size: '100%',
                dataLabels: {
                    enabled: false,
                    format: '<b>{point.name}</b>: {point.percentage:.1f} %'
                },
                showInLegend: true,
            }
        },
        series: pieSerie
    }
    return config
}
export const getOrderSummary = (orders: Order[], f: Filter) => {
    let pendingOrders: Order[] = [], completedOrders: Order[] = [], cancelledOrders: Order[] = []
    let wcompleted = 0, wcancelled = 0, wpending = 0;
    let pcompleted = 0, ppending = 0, pcancelled = 0
    const hasFilter = (f.from > 0 && f.to > 0) ? true : false
    const conditions = (o: Order) => {
        if (hasFilter ? (o.createdOn > f.from && o.createdOn < f.to) : true) {
            switch (o.orderStatus) {
                case Completed:
                    completedOrders.push(o)
                    if (o.orderType === 'Web') {
                        wcompleted = wcompleted + 1
                    } else {
                        pcompleted = pcompleted + 1
                    }
                    break
                case Cancelled:
                    cancelledOrders.push(o)
                    if (o.orderType === 'Web') {
                        wcancelled = wcancelled + 1
                    } else {
                        pcancelled = pcancelled + 1
                    }
                    break
                case Pending:
                    pendingOrders.push(o)
                    if (o.orderType === 'Web') {
                        wpending = wpending + 1
                    } else {
                        ppending = ppending + 1
                    }
                default:
                    break
            }
            return true
        }
        return false
    }
    const result = orders.filter(conditions)
    return {
        orders: result,
        webCompleted: wcompleted,
        webCancelled: wcancelled,
        webPending: wpending,
        posCompleted: pcompleted,
        posCancelled: pcancelled,
        posPending: ppending,
        completed: wcompleted + pcompleted,
        pending: wpending + ppending,
        cancelled: wcancelled + pcancelled,
        pendingOrders,
        completedOrders,
        cancelledOrders,
    }
}

export const orderTrendConfig = (p: Order[] = [], c: Order[] = [], ca: Order[] = []) => {
    const orders = p.concat(c, ca)
    const ymd: Array<{
        year: number,
        md: {
            m: number,
            d: Array<{ time: number, count: number, day: number }>
        }
    }> = []
    orders.forEach(o => {
        const date = moment(o.createdOn).toDate()
        const y = date.getFullYear()
        const m = date.getMonth()
        const d = date.getDay()
        const index = ymd.findIndex(v => v.year === y && v.md.m === m)
        if (index > -1) {
            const state = ymd[index]
            const dIndex = state.md.d.findIndex(dd => dd.day === d)
            if (dIndex > -1) {
                state.md.d[dIndex] = { time: o.createdOn, day: d, count: state.md.d[dIndex].count + 1 }
            } else {
                state.md.d.push({ day: d, time: o.createdOn, count: 1 })
            }
            ymd[index] = state
        } else {
            ymd.push({
                year: y,
                md: {
                    m: m,
                    d: [{ time: o.createdOn, count: 1, day: d }]
                }
            })
        }
    })
    let countArr: Array<{ createdOn: number, count: number }> = []
    ymd.forEach(v => { v.md.d.map(v => countArr.push({ createdOn: v.time, count: v.count })) })
    var data = Array(countArr.length).fill(0).map(m => Array(2).fill(0).map(m => m))
    _.orderBy(countArr, 'createdOn', 'asc').map((o, index) => {
        data[index][0] = o.createdOn
        data[index][1] = o.count
        return ''
    })
    return {
        rangeSelector: {
            selected: 1,
        },
        title: {
            text: 'Order Trends',
        },
        credits: {
            enabled: false
        },
        series: [
            {
                name: 'Count',
                data,
                tooltip: {
                    valueDecimals: 0,
                },
            },
        ]
    }
}


export const orderDashboardRows = (data: Summary) => {
    let i = 0
    return [
        { channel: 'Web', pending: data.webPending, completed: data.webCompleted, cancelled: data.webCancelled },
        { channel: 'POS', pending: data.posPending, completed: data.posCompleted, cancelled: data.posCancelled },
    ].map(v => {
        i++
        return {
            key: i,
            channel: v.channel,
            completed: v.completed,
            cancelled: v.cancelled,
            pending: v.pending,
        }
    })
}
export const orderDashboardColumns = () => {
    const columns = [
        {
            title: 'Sale Channel',
            dataIndex: 'channel',
            key: 'channel',
            render: (text: any) => <b style={{ color: 'blue', fontSize: 20 }} >{text}</b>
        },
        {
            title: 'Completed',
            dataIndex: 'completed',
            key: 'completed',
            render: (text: any) => <b style={{ color: 'green', fontSize: 20 }} >{text}</b>
        },
        {
            title: 'Pending',
            dataIndex: 'pending',
            key: 'pending',
            render: (text: any) => <b style={{ color: Colors.WatermelonColor, fontSize: 20 }} >{text}</b>
        },
        {
            title: 'Cancelled',
            dataIndex: 'cancelled',
            key: 'cancelled',
            render: (text: any) => <b style={{ color: 'red', fontSize: 20 }} >{text}</b>
        },
    ]
    return columns
}