import * as React from 'react'
import { Avatar, Button } from 'antd';
import ButtonGroup from 'antd/lib/button/button-group';
export const createRows = (rows = []) => {
    let i = 0
    return rows.length > 0 ?
        rows.map(row => {
            i++;
            return {
                key: i,
                name: row.displayName,
                phone: row.phone,
                profile: row.photo,
                edate: new Date(row.expiredAt).toDateString(),
                tpoints: row.totalPoint,
                cpoints: row.memberPoint,
                action: { id: row.id },
                texpire: row.totalExpirePoint,
                email: row.email,
            }
        }) :
        []
}
export const createColumns = (fn, onView) => {
    const columns = [
        {
            title: 'Profile',
            key: 'profile',
            fixed: 'left',
            width: 100,
            render: ({ profile }) => profile ? <Avatar src={profile} size={70} /> : <Avatar icon='user' size={70} style={{ color: 'black' }} />,
        },
        {
            title: 'Full Name',
            width: 150,
            dataIndex: 'name',
            key: 'name',
            fixed: 'left',
            render: (text) => <b style={{ color: 'blue' }}>{text}</b>,
            ...fn,
        },
        {
            title: 'Phone Number',
            width: 150,
            dataIndex: 'phone',
            key: 'phone',
            // fixed: 'left',
        },

        {
            title: 'Email ',
            dataIndex: 'email',
            key: 'email',
            width: 180,
        },
        {
            title: 'Total Points To Expire',
            dataIndex: 'texpire',
            key: 'texpire',
            width: 150,
            sorter: (a, b) => a.texpire - b.texpire,
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: 'Expire Date',
            dataIndex: 'edate',
            key: 'edate',
            width: 150,
        },
        {
            title: 'Total Points',
            dataIndex: 'tpoints',
            key: '2',
            width: 150,
            sorter: (a, b) => a.tpoints - b.tpoints,
            sortDirections: ['descend', 'ascend'],
        },

        {
            title: 'Current Points',
            dataIndex: 'cpoints',
            key: '3',
            width: 150,
            sorter: (a, b) => a.cpoints - b.cpoints,
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: 'Action',
            key: 'action',
            align: 'center',
            width: '12%',
            fixed: 'right',
            render: (text, { action }) => {
                return (
                    <ButtonGroup>
                        <Button
                            icon="eye"
                            // tslint:disable-next-line:jsx-no-lambda
                            onClick={() => { onView(action.id); }}
                        > view </Button>
                    </ButtonGroup>)

            }
        },
    ];
    return columns
}

export const createCsvData = (rows = []) => {
    return rows.length > 0 ?
        rows.map(row => {
            return {
                name: row.name,
                phoneNumber: row.phone,
                email: row.email,
                totalPoint: row.tpoints,
                currentPoint: row.cpoints,
                totalPointExpire: row.texpire,
                expireDate: row.edate,
            }
        })
        : [];
}