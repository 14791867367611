import * as React from 'react'
import { Order } from "../state";
import { formatMoney } from "../../util";
import * as _ from 'lodash';
interface Filter {
    from: number;
    to: number;
}
interface Summary {
    totalSales: number;
    totalTaxes: number;
    totalOrders: number,
    webSales: number;
    webTaxes: number;
    webOrders: number;
    PosSales: number;
    PosTaxes: number;
    PosOrders: number,
    totalDiscountAmount: number,
    paymentBank: number,
    paymentCash: number,
    CashTaxes: number,
    CashOrders: number,
    BankTaxes: number,
    BankOrders: number,
    orders: Order[],
}

export const getSaleSummary = (orders: Order[], f: Filter) => {
    let wsales = 0, wtaxes = 0, worders = 0;
    let psales = 0, ptaxes = 0, porders = 0;
    let totalDiscountAmount = 0;
    let ctaxes = 0, paymentCash = 0, corders = 0;
    let btaxes = 0, borders = 0, paymentBank = 0;
    const conditions = (o: Order) => {
        if (o.orderStatus !== 'Completed') return false
        if (f.from > 0 && f.to > 0) {
            if (o.createdOn > f.from && o.createdOn < f.to) {
                if (o.totalDiscountAmount) {
                    totalDiscountAmount = totalDiscountAmount + o.totalDiscountAmount
                }
                if (o.paymentType.toLocaleLowerCase() === 'cash') {
                    ctaxes = ctaxes + o.totalAddedTaxAmount
                    corders = corders + 1
                    paymentCash = paymentCash + o.totalAmount

                } else {
                    btaxes = btaxes + o.totalAddedTaxAmount
                    borders = borders + 1
                    paymentBank = paymentBank + o.totalAmount
                }
                if (o.orderType === 'Web') {
                    wsales = wsales + o.totalAmount
                    wtaxes = wtaxes + o.totalAddedTaxAmount
                    worders = worders + 1
                    return true
                }
                if (o.orderType === 'POS') {
                    psales = psales + o.totalAmount
                    ptaxes = ptaxes + o.totalAddedTaxAmount
                    porders = porders + 1
                    return true
                }
            }
            return false
        } else {
            if (o.totalDiscountAmount) {
                totalDiscountAmount = totalDiscountAmount + o.totalDiscountAmount
            }
            if (o.paymentType.toLocaleLowerCase() === 'cash') {
                ctaxes = ctaxes + o.totalAddedTaxAmount
                corders = corders + 1
                paymentCash = paymentCash + o.totalAmount

            } else {
                btaxes = btaxes + o.totalAddedTaxAmount
                borders = borders + 1
                paymentBank = paymentBank + o.totalAmount
            }
            if (o.orderType === 'Web') {
                wsales = wsales + o.totalAmount
                wtaxes = wtaxes + o.totalAddedTaxAmount
                worders = worders + 1
            }
            if (o.orderType === 'POS') {
                psales = psales + o.totalAmount
                ptaxes = ptaxes + o.totalAddedTaxAmount
                porders = porders + 1
            }
        }
        return true
    }
    const result = orders.filter(conditions)
    return {
        totalSales: wsales + psales,
        totalTaxes: wtaxes + ptaxes,
        totalOrders: worders + porders,
        webSales: wsales,
        webTaxes: wtaxes,
        webOrders: worders,
        PosSales: psales,
        PosTaxes: ptaxes,
        PosOrders: porders,
        CashTaxes: ctaxes,
        CashOrders: corders,
        BankTaxes: btaxes,
        BankOrders: borders,
        totalDiscountAmount,
        paymentBank,
        paymentCash,
        orders: result,
    }
}

export const saleTrendConfig = (orders: Order[] = []) => {
    
    var data = Array(orders.length).fill(0).map(m => Array(2).fill(0).map(m => m))
    _.orderBy(orders, 'createdOn', 'asc').map((o, index) => {
        data[index][0] = o.createdOn
        data[index][1] = o.totalAmount
        return ''
    })
    return {
        rangeSelector: {
            selected: 1,
        },
        title: {
            text: 'Sale Trends',
        },
        credits: {
            enabled: false
        },
        series: [
            {
                name: 'Sale',
                data,
                tooltip: {
                    valueDecimals: 2,
                },
            },
        ]
    }
}

export const createRows = (data: Summary) => {
    let i = 0
    return [
        { channel: 'Web', sales: data.webSales, taxes: data.webTaxes, orders: data.webOrders },
        { channel: 'POS', sales: data.PosSales, taxes: data.PosTaxes, orders: data.PosOrders }
    ].map(v => {
        i++
        return {
            key: i,
            channel: v.channel,
            tsale: v.sales,
            tax: v.taxes,
            torder: v.orders
        }
    })
}

export const createColumns = () => {
    const columns = [
        {
            title: 'Sale Channel',
            dataIndex: 'channel',
            key: 'channel',
            render: (text: any) => <b style={{ color: 'blue', fontSize: 12 }} >{text}</b>
        },
        {
            title: 'Total Sales',
            dataIndex: 'tsale',
            key: 'tsale',
            render: (text: any) => <b style={{ fontSize: 13 }} >{formatMoney(text)} MMK</b>
        },
        {
            title: 'Total Taxes',
            dataIndex: 'tax',
            key: 'tax',
            render: (text: any) => <b style={{ color: 'black', fontSize: 14 }} >{formatMoney(text)} MMK</b>
        },
        {
            title: 'Total Orders',
            dataIndex: 'torder',
            key: 'torder',
            render: (text: any) => <b style={{ color: 'black', fontSize: 13 }} >{text}</b>
        },
    ]
    return columns
}
export const createPaymentRows = (data: Summary) => {
    let i = 0
    return [
        { channel: 'Cash', sales: data.paymentCash, taxes: data.CashTaxes, orders: data.CashOrders },
        { channel: 'Bank', sales: data.paymentBank, taxes: data.BankTaxes, orders: data.BankOrders }
    ].map(v => {
        i++
        return {
            key: i,
            channel: v.channel,
            tsale: v.sales,
            tax: v.taxes,
            torder: v.orders
        }
    })
}

export const createPaymentColumns = () => {
    const columns = [
        {
            title: 'Payment Method',
            dataIndex: 'channel',
            key: 'channel',
            render: (text: any) => <b style={{ color: 'blue', fontSize: 12 }} >{text}</b>
        },
        {
            title: 'Total Sales',
            dataIndex: 'tsale',
            key: 'tsale',
            render: (text: any) => <b style={{ fontSize: 13 }} >{formatMoney(text)} MMK</b>
        },
        {
            title: 'Total Taxes',
            dataIndex: 'tax',
            key: 'tax',
            render: (text: any) => <b style={{ color: 'black', fontSize: 14 }} >{formatMoney(text)} MMK</b>
        },
        {
            title: 'Total Orders',
            dataIndex: 'torder',
            key: 'torder',
            render: (text: any) => <b style={{ color: 'black', fontSize: 13 }} >{text}</b>
        },
    ]
    return columns
}