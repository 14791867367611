import * as React from "react";
import * as Colors from '../../../color'
export const crateColummns = () => {
    const columns = [
        // {
        //     title: 'Photo', width: 100, key: 'photo', fixed: 'left', render: ({ photo }) => < Avatar src={photo} size={70} />
        // },
        {
            title: "Name", width: 150, dataIndex: 'name', key: 'name', fixed: 'left',
            render: text => <b style={{ color: Colors.BlackColor, fontSize: '14' }}><span>{text}</span></b>
        },
        {
            title: 'From', dataIndex: 'from', key: 'from',
            render: text => <b style={{ color: Colors.BlackColor, fontSize: '16' }}>{text}</b>
        },
        {
            title: 'To', dataIndex: 'to', key: 'to',
            render: text => <b style={{ color: Colors.MemberColor, fontSize: '16' }}> {text}</b>
        },
        { title: 'Date', dataIndex: 'date', key: 'date', },
        { title: 'Claimed Date', dataIndex: 'claimed', key: 'claimed', },
        { title: "Store", dataIndex: 'store', key: 'store' },
        {
            title: "Status", dataIndex: 'status', key: 'status', fixed: 'right',
            render: text => text === 'claimed' ?
                <b style={{ color: Colors.BlackColor, fontSize: '16' }}><span >{text}</span></b> :
                <b style={{ color: Colors.LabelColor, fontSize: '16' }} > <span >{text === null && 'redeem'}</span></ b>
        },
    ]
    return columns
}
export const createRows = (rows = []) => {
    let i = 0
    return rows.length > 0 ?
        rows.map(row => {
            i++;
            return {
                key: i,
                photo: row.rewardPhoto,
                from: row.uId ? row.uid.split('@')[0] : 'system',
                to: row.memberName,
                date: require('moment').unix(row.createdAt / 1000 | 0).format('LLL'),
                claimed: row.status === 'claimed' ? require('moment').unix(row.claimedAt / 1000 | 0).format('LLL') : '',
                store: '',
                status: row.status,
                type: row.type,
                name: `${row.rewardName}`,
            }
        }) :
        []
}
export const createCsvData = (rows = []) => {
    return rows.length > 0 ?
        rows.map(row => {
            return {
                event: row.type,
                rewardName: row.name,
                from: row.from,
                to: row.to,
                store: row.store,
                date: row.date,
                cliamedDate: row.claimed,
                status: row.status === 'claimed' ? row.status : 'redeem',
            }
        })
        : [];
}