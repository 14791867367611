import * as React from 'react'
import moment from 'moment'
import { DatePicker, Button } from 'antd'
const { RangePicker } = DatePicker
const dateFormat = 'YYYY/MM/DD';
export interface DateProps {
    defaultValue: any;
    onChange: (s: any, t: any) => void;
    onOk: () => void;
    className: string;
    footerController: (m: any, v: any) => void;
}
const DateRange = (props: DateProps) => {
    return (
        <RangePicker
            className={props.className}
            format={dateFormat}
            placeholder={['start date', 'end date']}
            value={props.defaultValue}
            defaultValue={props.defaultValue}
            onChange={(s: any, t: any) => props.onChange(s, t)}
            onOk={() => props.onOk()}
            allowClear={true}
            autoFocus={true}
            renderExtraFooter={() => {
                return (
                    <>
                        <Button style={{ marginLeft: 10 }} type='primary' size='small' onClick={() => props.footerController({}, [moment().startOf('day'), moment().endOf('day')])}>Today</Button>
                        <Button style={{ marginLeft: 10 }} type='primary' size='small' onClick={() => props.footerController({}, [moment().startOf('month'), moment().endOf('month')])}>This Month</Button>
                        <Button style={{ marginLeft: 10 }} type='primary' size='small' onClick={() => props.footerController({}, [moment().subtract(7, 'day'), moment()])}>Last 1Week</Button>
                        <Button style={{ marginLeft: 10 }} type='primary' size='small' onClick={() => props.footerController({}, [moment().subtract(14, 'day'), moment()])}>Last 2Week</Button>
                        <Button style={{ marginLeft: 10 }} type='primary' size='small' onClick={() => props.footerController({}, [moment().subtract(21, 'day'), moment()])}>Last 3Week</Button>
                        <Button style={{ marginLeft: 10 }} type='primary' size='small' onClick={() => props.footerController({}, [moment().subtract(1, 'month'), moment()])}>Last 1Month</Button>
                        <Button style={{ marginLeft: 10 }} type='primary' size='small' onClick={() => props.footerController({}, [moment().subtract(3, 'month'), moment()])}>Last 3Month</Button>
                        <Button style={{ marginLeft: 10 }} type='primary' size='small' onClick={() => props.footerController({}, [moment().subtract(6, 'month'), moment()])}>Last 6Month</Button>
                        <Button style={{ marginLeft: 10 }} type='primary' size='small' onClick={() => props.footerController({}, [moment().subtract(9, 'month'), moment()])}>Last 9Month</Button>
                        <Button style={{ marginLeft: 10 }} type='primary' size='small' onClick={() => props.footerController({}, [moment().subtract(12, 'month'), moment()])}>Last Year</Button>
                    </>
                )
            }
            }
            showTime
        />
    )

}
export default DateRange