import * as Type from './actionTypes'
import config from '../../config'
import moment from 'moment'
const report_url = process.env.REPORT_URL || config().config.Report.url
export { GetTopMemberSummary } from '../../Charts/redux/actions'
export const GetAllMember = (merchantId) => (dispatch) => {
    let _url = `${report_url}/getAllMember/${merchantId}`
   // console.log(`Report member url :${_url}`)
    fetch(_url, {
        crossDomain: true
    })
        .then(response => response.json())
        .then(data => {
          //  console.log("GellAllmember data", data)
            let members = { memberList: [] }, _action = {}
            _action = { ..._action, type: Type.ADD_ALL_MEMBER, payload: { ...members } }
            if (data.status !== 'Error' || data.data.length < 0) {
                _action = { ..._action, payload: { ...members, memberList: data.data || [] } }
            }
            dispatch(_action)
        });
}
export const addMemberFilterRange = (from, to) => dispatch => {
    const _action = {
        type: Type.ADD_MEMBER_FILTER_RANGE,
        payload: {
            from: from,
            to: to
        }
    }
    dispatch(_action)
}

export const clearMemberFilter = () => dispatch => {
    const _action = {
        type: Type.CLEAR_MEMBER_FILTER
    }
    dispatch(_action)
}
export const GetNewMembers = ({ members = [], points = [], rewards = [], from = 0, to = 0 }) => dispatch => {
    //console.log('Get members:', members, '\n points', points, '\nrewards', rewards)
    let newMembers = members.filter(m => moment(m.firstActiveAt).unix() * 1000 >= from && moment(m.firstActiveAt || 0).unix() * 1000 <= to).map(m => {
        let pointInPeriod = 0, rewardInPeriod = 0
        points.filter(p => m.id === p.memberId && p.createdAt >= from && p.createdAt <= to).map(p => pointInPeriod += p.point)
        rewards.filter(r => r.memberId === m.id && r.status === 'claimed' && r.createdAt >= from && r.createdAt <= to).map(r => rewardInPeriod++)
        return { ...m, pointInPeriod: pointInPeriod, rewardInPeriod: rewardInPeriod }
    })
    //console.log('new members', newMembers)
    let action = { type: Type.ADD_NEW_MEMBER, payload: { newMembers: newMembers } }
    dispatch({ ...action })
}
export const GetTopMembers = ({ members = [], topMembers = [] }) => dispatch => {
    let top = topMembers.map(tm => {
        return members.filter(m => m.id === tm.memberId)[0]
    })
    //('Top members', top)
    let action = { type: Type.ADD_REPORT_TOP_MEMBER, payload: { topMembers: top } }
    dispatch({ ...action })
}
export const GetActiveMembers = ({ members = [], points = [], rewards = [], from = 0, to = 0 }) => dispatch => {
    let activeMembers = members.map(m => {
        let pointInPeriod = 0, rewardInPeriod = 0
        points.filter(p => m.id === p.memberId && p.createdAt >= from && p.createdAt <= to).map(p => pointInPeriod += p.point)
        rewards.filter(r => r.memberId === m.id && r.status === 'claimed' && r.createdAt >= from && r.createdAt <= to).map(r => rewardInPeriod++)
        return { ...m, pointInPeriod: pointInPeriod, rewardInPeriod: rewardInPeriod }
    }).filter(m => m.pointInPeriod > 0)
    let action = { type: Type.ADD_ACTIVE_MEMBER, payload: { activeMembers: activeMembers } }
    dispatch({ ...action })
}
export const GetInactiveMembers = ({ members = [], points = [], rewards = [], from = 0, to = 0 }) => dispatch => {
    console.log('Inactive from and to ', from, 'To ', to)
    let activeMembers = members.map(m => {
        let pointInPeriod = 0, rewardInPeriod = 0
        points.filter(p => m.id === p.memberId && p.createdAt >= from && p.createdAt <= to).map(p => pointInPeriod += p.point)
        rewards.filter(r => r.memberId === m.id && r.status === 'claimed' && r.createdAt >= from && r.createdAt <= to).map(r => rewardInPeriod++)
        return { ...m, pointInPeriod: pointInPeriod, rewardInPeriod: rewardInPeriod }
    }).filter(m => m.pointInPeriod === 0)
    console.log('Inactive members', activeMembers)
    let action = { type: Type.ADD_INACTIVE_MEMBER, payload: { inactiveMembers: activeMembers } }
    dispatch({ ...action })
}
export const GetReturnMembers = ({ members = [], points = [], rewards = [], from = 0, to = 0 }) => dispatch => {
    let returnMembers = members.map(m => {
        let pointInPeriod = 0, rewardInPeriod = 0
        points.filter(p => m.id === p.memberId && p.type === 'earn' && p.createdAt >= from && p.createdAt <= to).map(p => pointInPeriod += p.point)
        rewards.filter(r => r.memberId === m.id && r.status === 'claimed' && r.createdAt >= from && r.createdAt <= to).map(r => rewardInPeriod++)
        return { ...m, pointInPeriod: pointInPeriod, rewardInPeriod: rewardInPeriod }
    }).filter(m => m.totalPoint > 1 && m.pointInPeriod > 0)
    console.log('Return members', returnMembers)
    let action = { type: Type.ADD_RETURN_MEMER, payload: { returnMembers: returnMembers } }
    dispatch({ ...action })
}
export const GetRedAlertMembers = ({ members = [], points = [] }) => dispatch => {
    let redMembers = members.map(m => {
        let count = 0
        points.filter(p => m.id === p.memberId && p.point > 0 && p.type === "earn").map(p => count++)
        return { ...m, count: count }
    }).filter(m => m.count >= 3 && moment().diff(moment(m.lastActive), 'days') > 365)
    // .map(m => {
    //     let lst = m.lastActive, now = moment()
    //     let diffDays = moment().diff(moment(m.lastActive), 'days')
    //         console.log('last active date', lst, '\n current date', now, '\ndiff days', diffDays)
    //     // && moment(m.lastActive).diff(moment(), 'days') >= 0
    //     return m
    // })

   // console.log('Red members', redMembers)
    let action = { type: Type.ADD_RED_ALERT_MEMBER, payload: { redMembers: redMembers } }
    dispatch({ ...action })
}

export const GetRedSleepMembers = ({ members = [], points = [] }) => dispatch => {
    let sleepMembers = members.map(m => {
        let count = 0
        points.filter(p => m.id === p.memberId && p.point > 0 && p.type === "earn").map(p => count++)
        return { ...m, count: count }
    }).filter(m => m.count >= 4 && moment().diff(moment(m.lastActive), 'days') > 90)
    //console.log('Sleep members', sleepMembers)
    let action = { type: Type.ADD_SLEEP_MEMBER, payload: { sleepMembers: sleepMembers } }
    dispatch({ ...action })
}
export const GetRiskMembers = ({ members = [], points = [] }) => dispatch => {
    let riskMembers = members.map(m => {
        let count = 0
        points.filter(p => m.id === p.memberId && p.point > 0 && p.type === "earn").map(p => count++)
        return { ...m, count: count }
    }).filter(m => m.count >= 3 && moment().diff(moment(m.lastActive), 'days') > 90 && moment().diff(moment(m.lastActive), 'days') < 356)
   // console.log('Risk members', riskMembers)
    let action = { type: Type.ADD_RISK_MEMBER, payload: { riskMembers: riskMembers } }
    dispatch({ ...action })
}

export const GetRoyalMembers = ({ members = [], points = [] }) => dispatch => {
    let royalMembers = members.map(m => {
        let fiterPoints = 0
        points.filter(p => m.id === p.memberId && p.point > 0 && p.type === "earn").map(p => fiterPoints += p.point)
        return { ...m, fiterPoints: fiterPoints }
    }).filter(m => m.fiterPoints >= 3 && moment().diff(moment(m.lastActive), 'days') < 90)
   // console.log('Royal members', royalMembers)
    let action = { type: Type.ADD_ROYAL_MEMBER, payload: { royalMembers: royalMembers } }
    dispatch({ ...action })
}

