import * as React from "react";
import * as Colors from '../../../color'
export const crateColummns = () => {
    const columns = [
        // {
        //     title: 'Photo', width: 100, key: 'photo', fixed: 'left',
        //     render: ({ photo }) => { return < Avatar size={70} src={photo} /> },
        // },
        {
            title: "Stamp Card Name", dataIndex: 'name', key: 'name', width: 100,
            render: text => <b style={{ color: Colors.BlackColor, fontSize: '16' }}> <span>{text}</span></b>
        },
        {
            title: 'From', dataIndex: 'from', key: 'from', render: text => <b style={{ color: Colors.BlackColor, fontSize: '16' }}> {text}</b>
        },
        {
            title: 'To', dataIndex: 'to', key: 'to',
            render: text => <b style={{ color: Colors.MemberColor, fontSize: '16' }}>{text}</b>
        },
        {
            title: 'Date', dataIndex: 'date', key: 'date',
        },
        {
            title: "Changes", width: 100, dataIndex: 'change', key: 'change', fixed: 'right',
            render: text => <b style={{ color: Colors.BlackColor, fontSize: '14' }}>{text}</b>
        }
    ]
    return columns
}
export const createRows = (rows = []) => {
    let i = 0
    return rows.length > 0 ?
        rows.map(row => {
            i++;
            return {
                key: i,
                photo: row.campaignPhoto,
                event: 'award-stamp',
                from: row.uId ? row.uid.split('@')[0] : 'system',
                to: row.memberName,
                date: require('moment').unix(row.createdAt / 1000 | 0).format('LLL'),
                shop: 'Cafe Shop',
                name: row.campaignName,
                change: `${row.delta} + Stamp`,
            }
        }) :
        []
}
export const createCsvData = (rows = []) => {
    return rows.length > 0 ?
        rows.map(row => {
            return {
                event: row.event,
                from: row.from,
                to: row.to,
                date: row.date,
                name: row.name,
                change: row.change,
            }
        })
        : [];
}