import * as  React from 'react';
import HighchartsReact from 'highcharts-react-official';
import addTreemapModule from 'highcharts/modules/treemap';
import Highcharts from 'highcharts';

addTreemapModule(Highcharts);
const HighTreeMapChart = (props) => {
    return <HighchartsReact
        highcharts={Highcharts}
        options={props.config}
    />
}

export default HighTreeMapChart